import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import '../../containers/Container.scss';
import { closeExistingSDK } from '../utils';
import sampleImages from '../../constants/sampleImages';
import { replaceSuperModuleIdsWithActualIds } from '../../containers/uiConfigOperations';
import generateUniqueID from '../../utils/generateUniqueId';
import { updateSdkLaunchId } from '../../reducers/builderSDK';

function DisplaySDKScreen(props) {
  const {
    versionedModules,
    selectedWorkflow,
    screenToTeleport,
    uiConfig,
    textConfig,
    moduleToTeleport,
    jwtToken,
    customStyleSheets,
    reloadSdk,
    closeSdk,
    selectedLanguage,
    defaultTextConfig,
    selectedElement,
    workflowId,
  } = props;

  const dispatch = useDispatch();

  const callback = () => {
    // console.log(HVResponse);
  };

  useEffect(() => {
    let uniqueLaunchId = '';
    // eslint-disable-next-line no-undef
    const hyperKycConfig = new HyperKycConfig(
      jwtToken,
      workflowId,
      'transactionId',
      false,
      null,
      true,
      true,
    );

    if (!closeSdk && Object.keys(selectedWorkflow || {}).length > 0) {
      hyperKycConfig.setInputs({
        builderSampleFace: sampleImages.face,
        builderSampledoc: sampleImages.doc,
      });
      const styles = {
        left: '250px',
        top: '48px',
        width: '32.4%',
        height: '100%',
        backgroundColor: 'rgba(85, 78, 241, 0.03)',
      };
      hyperKycConfig.setBuilderBrandingStyles(styles);
      // This is the compiled workflow
      hyperKycConfig.setUserWorkflow(selectedWorkflow);

      if (uiConfig && Object.keys(uiConfig).length > 0) {
        const compiledModuleUiConfig =
      replaceSuperModuleIdsWithActualIds(selectedWorkflow?.modules || [], uiConfig);
        hyperKycConfig.setUiConfig(compiledModuleUiConfig);
        window.loadCustomUICss({ uiConfig: compiledModuleUiConfig });
      }

      if (textConfig
        && Object.keys(textConfig).length > 0) hyperKycConfig.setTextConfig(textConfig);
      else hyperKycConfig.setTextConfig(defaultTextConfig);

      hyperKycConfig.enableEditBranding(true);
      hyperKycConfig.setLanguageUsed(selectedLanguage);
      if (screenToTeleport === 'retake' && selectedElement?.type === 'errorDropdown') hyperKycConfig.SetTeleportScreen(moduleToTeleport, screenToTeleport, selectedElement?.textConfig);
      else hyperKycConfig.SetTeleportScreen(moduleToTeleport, screenToTeleport);

      hyperKycConfig.setCustomFontStylesheet(' https://kycdev-hyperverge-co.s3.ap-south-1.amazonaws.com/audit-portal/dev/workflow-builder/fonts/index.css');
      if (customStyleSheets?.length) {
        customStyleSheets.forEach((customStyleSheet) => {
          hyperKycConfig.setCustomFontStylesheet(customStyleSheet);
        });
      }

      // eslint-disable-next-line no-undef
      HyperKYCModule.launch(hyperKycConfig, callback);
      uniqueLaunchId = generateUniqueID();
      dispatch(updateSdkLaunchId({
        id: uniqueLaunchId,
      }));
    }

    return () => {
      closeExistingSDK(uniqueLaunchId);
    };
  }, [
    screenToTeleport,
    moduleToTeleport,
    textConfig,
    reloadSdk,
    closeSdk,
    selectedWorkflow,
    versionedModules,
    uiConfig,
    customStyleSheets,
    selectedLanguage,
    defaultTextConfig,
    selectedElement,
    jwtToken,
    workflowId,
  ]);
  return null;
}

DisplaySDKScreen.propTypes = {
  versionedModules: PropTypes.object.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  screenToTeleport: PropTypes.string.isRequired,
  uiConfig: PropTypes.object.isRequired,
  textConfig: PropTypes.object.isRequired,
  moduleToTeleport: PropTypes.string.isRequired,
  jwtToken: PropTypes.string.isRequired,
  customStyleSheets: PropTypes.arrayOf(PropTypes.string).isRequired,
  reloadSdk: PropTypes.bool.isRequired,
  closeSdk: PropTypes.bool.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  defaultTextConfig: PropTypes.object.isRequired,
  selectedElement: PropTypes.object.isRequired,
  workflowId: PropTypes.string.isRequired,
};

export default DisplaySDKScreen;
