import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import closeIcon from '../../assests/icons/CloseIcon.svg';
import withDeletionDependencyCheck from '../../utils/withDeletionDependencyCheck';
import MultiSelectionDrawer from './MultiSelectionDrawer';
import CustomInputDrawer from './CustomInputDrawer';
import calculateTotalNumberOfVariablesAdded from './utils';

function WorkflowOutputSelectionDrawer(props) {
  const {
    onClose, onAddVariables, menuOptions, page, sdkResponse,
  } = props;

  const [currentPage, setCurrentPage] = useState(page);
  const [customInputVariables, setCustomInputVariables] = useState([]);
  const [selectedVariablesOutput, setSelectedVariablesOutput] = useState([]);
  const [errorInCustomInput, setErrorInCustomInput] = useState(false);
  const [valueErrors, setValueErrors] = useState({});
  const [variableErrors, setVariableErrors] = useState({});
  const [isVariableOutputChanged, setIsVariableOutputChanged] = useState(false);

  const checkForError = () => Object.values(valueErrors).some((error) => error !== '') || Object.values(variableErrors).some((error) => error !== '');

  const menuValues = menuOptions.items.flatMap((item) => {
    if (item.label === 'Module Outputs' && item.items) {
      return item.items.flatMap((subItem) => subItem.items.map((option) => option.value));
    } if (item.label === 'Conditional Variables' && item.items) {
      return item.items.map((option) => option.value);
    }
    return [];
  });
  const preSelectedCustomInput = sdkResponse.filter((item) => !menuValues.includes(item.value));

  const handleAddVariablesOutput = () => {
    if (checkForError()) {
      setErrorInCustomInput(true);
      setCurrentPage('CustomInput');
    } else {
      onClose();
      onAddVariables(selectedVariablesOutput);
    }
  };

  const totalVariables = () => calculateTotalNumberOfVariablesAdded(
    sdkResponse,
    preSelectedCustomInput,
    selectedVariablesOutput,
    menuValues,
    customInputVariables,
  );
  useEffect(() => {
    setSelectedVariablesOutput(sdkResponse);
  }, [sdkResponse]);

  useEffect(() => {
    setCustomInputVariables(preSelectedCustomInput);
  }, []);

  useEffect(() => {
    if (sdkResponse === selectedVariablesOutput) {
      setIsVariableOutputChanged(false);
    } else {
      setIsVariableOutputChanged(true);
    }
  }, [selectedVariablesOutput]);

  return (
    <div className="workflow_configuration_modal__overlay">
      <div className="workflow_configuration_modal__body">
        <div className="workflow_configuration_modal__headerContainer">
          <div className="workflow_configuration_modal__headerContent">
            <div className="workflow_configuration_modal__headerContent__label">
              Add Output Variables
            </div>
            <div className="workflow_configuration_modal__headerContent__subtitle">
              Add module outputs, conditional variables
              and custom inputs in bulk to your workflow outputs
            </div>
          </div>
          <button type="button" className="closeWorkflowOutputModalButton" onClick={onClose}>
            <img
              src={closeIcon}
              alt="close"
            />
          </button>
        </div>

        <div className="workflow_configuration_modal__add_variables_content">
          <div className="workflow_configuration_modal__add_variables_navbar">
            <button
              type="button"
              className={currentPage === 'ModuleOutputs' ? 'workflow_configuration_modal__add_variables_navbar_button_active' : 'workflow_configuration_modal__add_variables_navbar_button'}
              onClick={() => setCurrentPage('ModuleOutputs')}
            >
              Module Outputs
            </button>
            <button
              type="button"
              className={currentPage === 'ConditionalVariables' ? 'workflow_configuration_modal__add_variables_navbar_button_active' : 'workflow_configuration_modal__add_variables_navbar_button'}
              onClick={() => setCurrentPage('ConditionalVariables')}
            >
              Conditional Variables
            </button>
            <button
              type="button"
              className={currentPage === 'CustomInput' ? 'workflow_configuration_modal__add_variables_navbar_button_active' : 'workflow_configuration_modal__add_variables_navbar_button'}
              onClick={() => setCurrentPage('CustomInput')}
            >
              Custom Inputs
            </button>

          </div>
          <div className="workflow_configuration_modal__add_variables_body">
            {
              currentPage === 'ConditionalVariables'
                ? (
                  <MultiSelectionDrawer
                    data={menuOptions.items[1]}
                    selectedItems={selectedVariablesOutput}
                    onSelectItems={setSelectedVariablesOutput}
                    drawerTitle="Conditional Variables"
                    nestedCheckboxSelection={false}
                    text="No Conditional Variables Defined"

                  />
                ) : ''
            }
            {
              currentPage === 'ModuleOutputs'
                ? (
                  <MultiSelectionDrawer
                    data={menuOptions.items[0]}
                    selectedItems={selectedVariablesOutput}
                    onSelectItems={setSelectedVariablesOutput}
                    drawerTitle="Module Outputs"
                    nestedCheckboxSelection
                  />
                ) : ''
            }
            {
              (currentPage === 'CustomInput') ? (
                <CustomInputDrawer
                  selectedVariablesOutput={selectedVariablesOutput}
                  setSelectedVariablesOutput={setSelectedVariablesOutput}
                  customInputVariables={customInputVariables}
                  setCustomInputVariables={setCustomInputVariables}
                  errorInCustomInput={errorInCustomInput}
                  setErrorInCustomInput={setErrorInCustomInput}
                  valueErrors={valueErrors}
                  setValueErrors={setValueErrors}
                  variableErrors={variableErrors}
                  setVariableErrors={setVariableErrors}
                />
              )
                : ''
            }
            <div className="bottom-content">
              <div className="nav_button">
                <button type="button" className="back-button" onClick={onClose}>
                  Cancel
                  {' '}
                </button>
                {isVariableOutputChanged ? (
                  <button type="button" className="submit-button" onClick={handleAddVariablesOutput}>
                    {totalVariables() > 0 ? `Add ${totalVariables()} Variables` : 'Add Variables'}
                  </button>
                ) : (
                  <button type="button" className="submit-button_disabled">
                    Add Variables
                  </button>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

WorkflowOutputSelectionDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAddVariables: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  menuOptions: PropTypes.object.isRequired,
  sdkResponse: PropTypes.array.isRequired,
};

export default withDeletionDependencyCheck(WorkflowOutputSelectionDrawer);
