import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import StyleEditor from 'react-style-editor';

import useDebounce from '../../../utils/useDebounce';

function SimpleCssInput(props) {
  const {
    label,
    onChange,
    defaultValue,
    domId,
  } = props;
  const [value, setValue] = useState(defaultValue);
  const prevValue = useRef(defaultValue || '');
  const debouncedValue = useDebounce(value, 500);
  const onStyleChange = (v) => {
    setValue(v);
  };

  useEffect(() => {
    if (prevValue.current !== debouncedValue) {
      onChange(debouncedValue);
    }
    prevValue.current = debouncedValue;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <Grid container alignItems="center" className="edit-properties-div__text-input-container">
      <Grid item xs={4}>
        <label htmlFor={label} className="edit-properties-div__label">{label}</label>
      </Grid>
      <Grid item xs={8}>
        <StyleEditor
          defaultValue={`
                    #${domId} {color:red;}
                `}
          onChange={onStyleChange}
          value={value}
          outputFormats="pretty"
        />
      </Grid>
    </Grid>
  );
}

SimpleCssInput.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  domId: PropTypes.string.isRequired,
};

export default SimpleCssInput;
