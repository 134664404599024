import { getAllFormComponents } from '../containers/FormModule/helper';
import { eventHandlers } from '../components/constants';

const getAllComponentDependencies = (id, component, nextNodeType, regex) => {
  let childDeps = [];
  const { subComponents, ...restOfTheComp } = component;
  if (subComponents?.length) {
    subComponents.forEach((subComponent) => {
      childDeps = [
        ...childDeps,
        ...getAllComponentDependencies(id, subComponent, nextNodeType, regex),
      ];
    });
  }

  const nextStep = component.onClick?.nextStep
          || component.onValidated?.nextStep
          || component.onComplete?.nextStep
          || component.onChange?.nextStep;

  const nextStepEvent = eventHandlers.find((event) => component[event]?.nextStep === nextStep);
  const eventName = nextStepEvent || '';

  const moduleId = `${component.id}${eventName ? `.${eventName}.nextStep` : ''}`;

  if (regex.test(JSON.stringify(restOfTheComp))) {
    if (nextStep !== id || (nextStep === id && nextNodeType[moduleId] === 'goto')) {
      return [...childDeps, component.id];
    }
  }
  return [...childDeps];
};

const getFormModuleDependencies =
  (id, components, nextNodeType, regex) => components.reduce((arrSoFar, component) => [
    ...arrSoFar,
    ...getAllComponentDependencies(id, component, nextNodeType, regex),
  ], []);

// TODO: Check if it works for footer component as well ?
export const fetchDependencyList = (id, workflow) => {
  const regex = new RegExp(`\\b${id}\\b`, 'i');
  const moduleDependencies = workflow.modules
    .filter((module) => {
      // include the parent of the goto version of the node
      if (module.nextStep === id && (module.next_node_type === 'goto' || module.next_node_type?.default === 'goto')) return true;
      // exclude the module itself
      if (module.id === id) return false;
      // exclude the parent of the module
      if (module.nextStep === id) return false;
      // exclude the dependency from the next step if parent in form module

      // Dynamic form v1
      if (module.properties?.sections?.length) {
        const components = getAllFormComponents(module);
        const formDeps = getFormModuleDependencies(id, components, module.next_node_type, regex);
        return !!formDeps.length;
      }

      // Dynamic form v2
      if (module?.type === 'dynamicFormV2') {
        // const allNextSteps = getNextStepForModule(module);
        // const dependentNextSteps = allNextSteps.filter(({ nextStep }) => nextStep === id);
        // return !!dependentNextSteps.length;
        // TODO: fix this
        return false;
      }

      return regex.test(JSON.stringify(module));
    }).map(({ id: moduleId, name: moduleName }) => moduleName || moduleId);

  const conditionDependencies = [];
  Object.entries(workflow.conditions || {}).forEach(([conditionId, value]) => {
    // include the parent of the goto version of the node
    if (value.next_node_type?.if_false === 'goto' && value.if_false === id) {
      conditionDependencies.push(conditionId);
    }
    if (value.next_node_type?.if_true === 'goto' && value.if_true === id) {
      conditionDependencies.push(conditionId);
    }
    if (regex.test(JSON.stringify(value))
    && value.if_true !== id && value.if_false !== id // exclude the parent of the id
    && conditionId !== id // exclude itself
    ) conditionDependencies.push(conditionId);
  });

  const workflowOutputDependencies = Object.entries(workflow.sdkResponse || {})
    .filter(([, resValue]) => regex.test(JSON.stringify(resValue)))
    .map(([resName]) => resName);

  const conditionalVariableDependencies = Object.entries(workflow.conditionalVariables || {})
    .filter(([, value]) => regex.test(JSON.stringify(value)))
    .map(([varName]) => varName);
  return {
    moduleDependencies,
    conditionDependencies,
    workflowOutputDependencies,
    conditionalVariableDependencies,
  };
};

export const fetchDependencyOfField = (fieldName, source, workflow) => {
  const regex = new RegExp(`\\b${source}\\.${fieldName}\\b`);

  // Loop through workflow.modules, stringify each module object and test with regex
  const moduleDependencies = workflow.modules
    .filter((module) => regex.test(JSON.stringify(module)))
    .map(({ id: moduleId, name: moduleName }) => moduleName || moduleId);

  // Loop through workflow.conditions, stringify each condition object and test with regex
  const conditionDependencies = Object.entries(workflow.conditions || {})
    .filter(([, conditionValue]) => regex.test(JSON.stringify(conditionValue)))
    .map(([conditionId, { name }]) => name || conditionId);

  // Loop through workflow.sdkResponse, stringify each response object and test with regex
  const workflowOutputDependencies = Object.entries(workflow.sdkResponse || {})
    .filter(([, resValue]) => regex.test(JSON.stringify(resValue)))
    .map(([resName]) => resName);

  // Loop through workflow.conditionalVariables, stringify each conditionalVariable object
  // and test with regex
  const conditionalVariableDependencies = Object.entries(workflow.conditionalVariables || {})
    .filter(([, value]) => regex.test(JSON.stringify(value)))
    .map(([varId, { name: varName, parent }]) => {
      if (!parent || parent === varId) return varName || varId;
      return workflow.conditionalVariables[parent].name || parent;
    });

  return {
    moduleDependencies,
    conditionDependencies,
    workflowOutputDependencies,
    conditionalVariableDependencies,
  };
};
