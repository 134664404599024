/* eslint-disable no-param-reassign */
import { cloneDeep, get, unset } from 'lodash';
import removeUnvisitedNodesAndConditions from '../workflowOperations/utils';
import { fetchCurrentValueFromWorkflow as getModuleProperty } from '../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';
import { replaceAll } from '../utils/helper';
import replaceNextStepId from './utils/replaceNextStepId';
import { getAllFormComponentsObj, getComponentFromPath } from '../containers/FormModule/helper';

const findExitStatePositionInModules = (library) => {
  let moduleId = null;
  library.modules.forEach((module) => {
    // TODO: What if next step is in the form module of library, Case not handled
    if (module.nextStep === 'EXIT_POINT') {
      moduleId = module.id;
    }
  });
  return moduleId;
};

const findExitStatePositionInConditions = (library) => {
  let conditionId = null;
  const conditionsIdArr = Object.keys(library.conditions);
  conditionsIdArr.forEach((id) => {
    const condition = library.conditions[id];
    if (condition.if_false === 'EXIT_POINT') {
      conditionId = `${id}[+]if_false`;
    } else if (condition.if_true === 'EXIT_POINT') {
      conditionId = `${id}[+]if_true`;
    }
  });
  return conditionId;
};

// TODO: Write tests for the below function
// TOOD: use event handlers
const findPositionOfExitPointComponentInArray = (components, currentPathArray = []) => {
  let result = null;
  (components || []).every((component, index) => {
    if (component?.onClick?.nextStep === 'EXIT_POINT') {
      result = {
        componentPathArray: [...currentPathArray, index],
        position: 'onClick.nextStep',
      };
      return false;
    }
    if (component?.onValidated?.nextStep === 'EXIT_POINT') {
      result = {
        componentPathArray: [...currentPathArray, index],
        position: 'onValidated.nextStep',
      };
      return false;
    }
    if (component?.onComplete?.nextStep === 'EXIT_POINT') {
      result = {
        componentPathArray: [...currentPathArray, index],
        position: 'onComplete.nextStep',
      };
      return false;
    }
    if (component?.onChange?.nextStep === 'EXIT_POINT') {
      result = {
        componentPathArray: [...currentPathArray, index],
        position: 'onChange.nextStep',
      };
      return false;
    }
    if (component?.dynamicHandlers?.handlers?.length) {
      // Dynamic form handler
      let position = null;
      (component?.dynamicHandlers?.handlers || []).forEach((handler, handlerIndex) => {
        if (handler?.nextStep === 'EXIT_POINT') position = `dynamicHandlers.handlers[${handlerIndex}].nextStep`;
      });
      if (position) {
        result = {
          componentPathArray: [...currentPathArray, index],
          position,
        };
        return false;
      }
    }
    if (component?.subComponents?.length) {
      // Can't return from forEach
      const exitPointData =
    findPositionOfExitPointComponentInArray(component?.subComponents, [...currentPathArray, index]);
      if (exitPointData) {
        result = exitPointData;
        return false;
      }
    }
    return true;
  });
  return result;
};

const findPositionOfExitPointInDynamicForm = (library) => {
  let path = null;
  library.modules.forEach((module) => {
    if (path === null) {
      if (module?.type === 'dynamicForm') {
        const formComponentsObj = getAllFormComponentsObj(module);
        const basePathKeys = Object.keys(formComponentsObj);

        basePathKeys.forEach((basePathKey) => {
          const components = formComponentsObj[basePathKey];
          const exitPointData = findPositionOfExitPointComponentInArray(components, []);
          if (exitPointData) path = { id: module?.id, ...exitPointData, basePathKey };
        });
      }
    }
  });
  return path;
};

const findExitNodeId = (library, superModuleId, moduleMap, lowLevelWorkflow) => {
  // Try fetching EXIT_POINT from module next step
  const moduleIdForNextStep = findExitStatePositionInModules(library);
  if (moduleIdForNextStep) return moduleMap[moduleIdForNextStep]?.nextStep;
  const conditionsIdForNextStep = findExitStatePositionInConditions(library);

  // Try fetching EXIT_POINT from condition
  if (conditionsIdForNextStep) {
    const [conditionId, trueFalseCase] = conditionsIdForNextStep.split('[+]');
    return lowLevelWorkflow.conditions[`condition_${superModuleId}_${conditionId}`][
      trueFalseCase
    ];
  }

  const exitPointInForm = findPositionOfExitPointInDynamicForm(library);
  if (exitPointInForm) {
    const {
      id, componentPathArray, position, basePathKey,
    } = exitPointInForm;
    const actualLowLevelFormModule = moduleMap[id];
    const formComponentsObj = getAllFormComponentsObj(actualLowLevelFormModule);
    const formComponents = formComponentsObj[basePathKey];
    const componentWithNextStep =
      getComponentFromPath(formComponents, componentPathArray);
    // Try fetching EXIT_POINT from dynamic form
    const final = get(componentWithNextStep, position, null);
    return final;
  }
  return null;
};

const createSuperModule = (
  superModuleArray,
  superModuleConditions,
  versionedModuleConfigs,
  lowLevelWorkflow,
  nextNodeType = {},
  moduleName = null,
  superModuleVersion = 'v1',
) => {
  // Extract important information
  const {
    superModuleId,
    superModuleType,
  } = superModuleArray[0];
  const moduleConfig = versionedModuleConfigs[superModuleType][superModuleVersion]?.config;
  const { library, initialStep } = moduleConfig;

  // STEP 1: Create an empty json object with module config structure
  const { id, properties, ...rest } = moduleConfig;
  const module = { ...rest, ...(moduleName ? { name: moduleName } : {}) };

  // Setting Id
  module.id = superModuleId;
  module.next_node_type = nextNodeType;
  module.version = superModuleVersion;
  // Create module Obj with mappingId as the key and module as value
  const moduleMap = {};
  superModuleArray.forEach((superModule) => {
    moduleMap[superModule.mappingId] = superModule;
  });
  Object.keys(superModuleConditions || {}).forEach((conditionId) => {
    moduleMap[conditionId] = superModuleConditions[conditionId];
  });

  // Generating properties
  const propertiesArr = Object.keys(properties);
  const propertiesObj = {};
  propertiesArr.forEach((property) => {
    const [mappingId, propertyKey] = property.split('[+]');
    const extractedValue = getModuleProperty(moduleMap[mappingId], propertyKey);
    propertiesObj[property] = extractedValue;
  });

  module.properties = propertiesObj;

  // Generating builderProperties
  const builderProperties = lowLevelWorkflow.properties?.builderProperties?.[superModuleId];
  if (builderProperties) {
    module.builderProperties = builderProperties;
  }

  const exitNodeActualId = findExitNodeId(library, superModuleId, moduleMap, lowLevelWorkflow);
  if (!exitNodeActualId) {
    throw new Error('Next for super module not found');
  }
  module.nextStep = exitNodeActualId;

  const variableReplacements = [];

  moduleConfig.variables.forEach((variable) => {
    const { name, path } = variable;
    const [mappingId, ...keyNames] = path.split('.');
    const key = keyNames.join('.');
    if (mappingId === 'conditionalVariables') {
      variableReplacements.push({
        key: `conditionalVariables.${superModuleId}_${key}`,
        value: `${superModuleId}.${name}`,
      });
    } else {
      variableReplacements.push({
        key: `${moduleMap[mappingId].id}.${key}`,
        value: `${superModuleId}.${name}`,
      });
    }
  });

  // Finding the id of the first node for referencing from the parent of the superModule

  const mappingIdOfFirstStep = initialStep || library.modules[0].id;
  const idToBeReplaced = moduleMap[mappingIdOfFirstStep].id;

  const replaceNextStepIds = {
    key: idToBeReplaced,
    value: superModuleId,
  };

  return {
    module,
    variableReplacements,
    replaceNextStepIds,
  };
};

const replaceModuleVariables = (workflow, replacements) => {
  const workflowString = JSON.stringify(workflow);
  let finalWorkflowString = workflowString;
  replacements.forEach((replacement) => {
    finalWorkflowString = replaceAll(
      finalWorkflowString,
      replacement.key,
      replacement.value,
    );
  });
  const finalWorkflowObj = JSON.parse(finalWorkflowString);
  return finalWorkflowObj;
};

const removeLibrariesFromWorkflow = (workflow) => {
  const editedWorkflow = cloneDeep(workflow);
  editedWorkflow.modules.forEach((module) => {
    unset(module, 'library');
  });
  return editedWorkflow;
};

const decompile = (lowLevelWorkflow, versionedModuleConfigs) => {
  let highLevelWorkflow = {};

  // STEP 1: COPY ALL THE PROPERTIES EXCEPT MODULES
  const {
    modules: originalModules,
    conditions: originalConditions,
    conditionalVariables: originalConditionalVariables,
    properties: originalProperties,
    ...rest
  } = lowLevelWorkflow;

  // Exclude builderProperties from properties of the workflow
  const { builderProperties, ...otherProperties } = originalProperties;
  highLevelWorkflow = { properties: otherProperties, ...rest };

  // Exclude conditions of the super module
  const conditions = {};
  Object.keys(originalConditions).forEach((condition) => {
    if (!condition.includes('[+]')) conditions[condition] = originalConditions[condition];
  });
  highLevelWorkflow.conditions = conditions;

  // STEP 2: CREATE A MODULES ARRAY BY COPYING ALL THE SIMPLE MODULES AND KEEP SUPER MODUELS ASIDE
  const modules = [];
  const superModulesMap = {};
  const superModuleConditionsMap = {};
  originalModules.forEach((module) => {
    if (module?.superModuleId && typeof module?.superModuleId === 'string') {
      // complex module
      const superModuleId = module?.superModuleId;
      if (superModulesMap[superModuleId]) superModulesMap[superModuleId].push(module);
      else superModulesMap[superModuleId] = [module];
    } else {
      // simple module
      modules.push(module);
    }
  });
  highLevelWorkflow.modules = modules;

  const superModuleIdArr = Object.keys(superModulesMap);

  // Include the conditions from supermodules in the superModulesMap
  // Assumption: superModule will contain at least one module
  Object.entries(originalConditions).forEach(([conditionId, condition]) => {
    const superModuleId = superModuleIdArr.find((moduleId) => conditionId.startsWith(`condition_${moduleId}`));
    if (superModuleId) {
      const [, originalConditionName] = conditionId.split(`condition_${superModuleId}_`);
      superModuleConditionsMap[superModuleId] = {
        ...superModuleConditionsMap[superModuleId],
        [originalConditionName]: { id: conditionId, ...condition },
      };
    }
  });

  // Exclude conditionalVariables from the super module
  const conditionalVariables = {};
  Object.keys(originalConditionalVariables || {}).forEach((condVariable) => {
    const isSuperModuleVar = superModuleIdArr.reduce(
      (acc, superModuleId) => acc || condVariable.startsWith(`${superModuleId}_`),
      false,
    );
    if (!isSuperModuleVar) {
      conditionalVariables[condVariable] = originalConditionalVariables[condVariable];
    }
  });
  highLevelWorkflow.conditionalVariables = conditionalVariables;

  // moduleId.variables that should be replaced
  let replacements = [];

  const nextStepReplacements = [];
  // STEP 3: GROUPING PHASE
  const superModules = superModuleIdArr.map((superModuleId) => {
    const superModuleMetaData = lowLevelWorkflow?.properties?.builder?.superModuleMetaData || {};
    const superModuleName = superModuleMetaData?.[superModuleId]?.moduleName || null;
    const superModuleVersion = superModuleMetaData?.[superModuleId]?.version || 'v1';
    const nextNodeType = superModuleMetaData?.[superModuleId]?.nextNodeType || {};
    const { module, variableReplacements, replaceNextStepIds } = createSuperModule(
      superModulesMap[superModuleId],
      superModuleConditionsMap[superModuleId],
      versionedModuleConfigs,
      lowLevelWorkflow,
      nextNodeType,
      superModuleName,
      superModuleVersion,
    );
    replacements = replacements.concat(variableReplacements);
    nextStepReplacements.push(replaceNextStepIds);
    return module;
  });

  highLevelWorkflow.modules = modules.concat(superModules);
  highLevelWorkflow = replaceModuleVariables(highLevelWorkflow, replacements);

  // TODO: Next step for form module is not yet handled
  highLevelWorkflow = replaceNextStepId(
    highLevelWorkflow,
    nextStepReplacements,
  );
  highLevelWorkflow = removeLibrariesFromWorkflow(highLevelWorkflow);
  highLevelWorkflow = removeUnvisitedNodesAndConditions(highLevelWorkflow);

  return highLevelWorkflow;
};

export default decompile;
