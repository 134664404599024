import { useEffect, useState, useRef } from 'react';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import useDebounce from '../../../utils/useDebounce';
import editIcon2 from '../../../assests/icons/editIcon2.svg';
import closeIcon from '../../../assests/icons/close.svg';
import { options1, regexValues } from '../../../constants/validations';
import ConditionsDrawer from '../../Conditions/ConditionsDrawer';
import DropDown from './DropDown';

function ConfigureValidationDrawer(props) {
  const {
    defaultValue,
    onChange,
    hideRegexTab = false,
    dropDownOptions,
    onClose,
    preDefinedValues,
    texts,
  } = props;
  const { title } = texts;
  const [validationType, setValidationType] = useState(defaultValue.type);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState('');
  const [ruleValue, setRuleValue] = useState(defaultValue.type === 'rule' ? defaultValue
    : { type: 'rule', value: '', errorMsg: '' });
  const [regexValue, setRegexValue] = useState(defaultValue.type === 'regex' ? defaultValue
    : {
      type: 'regex', validationType: '', value: '', errorMsg: '',
    });
  const [showConditionDrawer, setShowConditionDrawer] = useState(false);
  const [openPopper, setOpenPopper] = useState(false);
  const anchorRef = useRef(null);

  const debouncedRuleValue = useDebounce(ruleValue);
  const debouncedRegexValue = useDebounce(regexValue);

  useEffect(() => {
    setOpenPopper(showConditionDrawer); // Open the Popper when showConditionDrawer is true
  }, [showConditionDrawer]);
  useEffect(() => {
    if (validationType === 'rule') {
      onChange(debouncedRuleValue);
    } else {
      onChange(debouncedRegexValue);
    }
  }, [validationType, debouncedRegexValue, debouncedRuleValue]);

  useEffect(() => {
    setSelectedDropdownValue('custom');
  }, []);

  const handleBtnClick = (type) => {
    setValidationType(type);
  };

  const handleTextChange = (type, currentText, isErrorMsg = false) => {
    if (type === 'regex') {
      setRegexValue((currentRegexValue) => {
        const localObject = { ...currentRegexValue };
        if (isErrorMsg) localObject.errorMsg = currentText;
        else localObject.value = currentText;
        return localObject;
      });
    } else {
      setRuleValue((currentRuleValue) => {
        const localObject = { ...currentRuleValue };
        if (isErrorMsg) localObject.errorMsg = currentText;
        else localObject.value = currentText;
        return localObject;
      });
    }
  };

  const defaultOptionIndex = options1.findIndex(
    (option) => option.name === defaultValue.validationType,
  );
  const defaultKey = defaultOptionIndex !== -1 ? options1[defaultOptionIndex].key : '0';

  const handleChange = (selectedValue) => {
    setSelectedDropdownValue(
      (regexValues.filter((val) => val.key === selectedValue)[0].validationType),
    );
    setRegexValue(regexValues.filter((val) => val.key === selectedValue)[0]);
  };
  useEffect(() => {
    if (regexValue.value === 'No value Entered') {
      setRegexValue((prevValue) => ({ ...prevValue, value: '' }));
    }
    if (ruleValue.value === 'No value Entered') {
      setRuleValue((prevValue) => ({ ...prevValue, value: '' }));
    }
    if (regexValue.errorMsg === 'No Message Entered') {
      setRegexValue((prevValue) => ({ ...prevValue, errorMsg: '' }));
    }
    if (ruleValue.errorMsg === 'No Message Entered') {
      setRuleValue((prevValue) => ({ ...prevValue, errorMsg: '' }));
    }
  }, []);

  const getInputContents = (type, rule, regex) => {
    const displayValue = type === 'rule' ? rule : regex;
    return (
      <>
        {
          type === 'rule'
            ? (
              <div className="validation-value__container">
                {
                showConditionDrawer && (
                  <Popper open={openPopper} anchorEl={anchorRef.current} placement="right-start">
                    {' '}
                    {/* Step 4 & 5 */}
                    <ConditionsDrawer
                      onClose={() => setShowConditionDrawer(false)}
                      rule={displayValue.value}
                      dropDownOptions={dropDownOptions}
                      onConditionSave={(value) => {
                        handleTextChange('rule', value);
                        setShowConditionDrawer(false);
                      }}
                      allowDefaultRules={false}
                      preDefinedValues={preDefinedValues}
                    />
                  </Popper>
                )
              }

                {rule.value ? (
                  <div className="validation-value__container">
                    <div className="validation-value__container__heading">
                      Rule
                    </div>
                    <div className="validation-value__rule">
                      <input
                        type="text"
                        value={displayValue.value}
                        className="validation-value"
                        placeholder="Add a rule here"
                        readOnly
                      />
                      <button
                        type="button"
                        onClick={() => setShowConditionDrawer(true)}
                        className="validation-value__edit"
                      >
                        <img src={editIcon2} alt="edit" />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={() => setShowConditionDrawer(true)}
                      className="validation-value__create"
                    >
                      Create Rule
                    </button>
                  </div>
                )}
              </div>
            )
            : (
              <>
                <div className="validation-value__container">
                  <div className="validation-value__container__heading">
                    Validation Type
                  </div>
                  <div className="validation-value__dropdown">
                    <DropDown
                      options={options1}
                      onChange={(selectedValue) => { handleChange(selectedValue); }}
                      defaultValue={defaultKey}
                    />
                  </div>
                  <div className="validation-value__regex">
                    <div className="validation-value__container__heading">
                      Regex
                    </div>
                    <div>
                      <input
                        type="text"
                        value={regexValue.value}
                        className="edit-properties-div__text-input"
                        onChange={(e) => handleTextChange(type, e.target.value)}
                        placeholder={`Write your ${type} here`}
                        readOnly={selectedDropdownValue !== 'custom'}
                      />
                    </div>
                  </div>
                </div>
              </>

            )
        }
        {(type !== 'rule' || (type === 'rule' && rule.value)) && (
        <div className="validation-value__container">
          <div className="validation-value__container__heading">
            Error Message
          </div>
          <div className="validation-value__container__error">
            <textarea
              type="text"
              id={`${type}_error`}
              value={displayValue.errorMsg}
              className="edit-properties-div__text-input2"
              onChange={(e) => handleTextChange(type, e.target.value, true)}
              placeholder={`Write error message for your ${type} here`}
            />
          </div>
        </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="validation-drawer-div">
        <div className="validation-drawer-div__heading">
          {title}
          <button type="button" className="close-icon" onClick={() => { onClose(regexValue || ruleValue); }}><img src={closeIcon} alt="close" /></button>
          {' '}
          {/* Close icon */}
        </div>
        <div className="validation-drawer-div__content">
          <div className="validation-drawer-div__tab">
            <button type="button" onClick={() => { handleBtnClick('rule'); }} className={`validation-type-btn${validationType === 'rule' ? '__active' : ''}`} id="rule">Rule</button>
            {!hideRegexTab ?
              <button type="button" onClick={() => { handleBtnClick('regex'); }} className={`validation-type-btn${validationType === 'regex' ? '__active' : ''}`} id="regex">Regex</button> : null}
          </div>
          <div>

            {getInputContents(validationType, ruleValue, regexValue)}
          </div>

        </div>
      </div>

    </>
  );
}

ConfigureValidationDrawer.propTypes = {
  defaultValue: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  hideRegexTab: PropTypes.bool,
  dropDownOptions: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  preDefinedValues: PropTypes.array.isRequired,
  texts: PropTypes.object,
};

ConfigureValidationDrawer.defaultProps = {
  hideRegexTab: false,
  texts: {
    title: 'Configure Validations',
  },
};

export default ConfigureValidationDrawer;
