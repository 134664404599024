/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import useDebounce from '../../../utils/useDebounce';

function DebouncedJsonInput(props) {
  const {
    label,
    onChange,
    formatAsJson,
    defaultValue,
    readOnly,
    placeholder,
  } = props;
  const getParsedValue = (value, asJson) => (asJson ? JSON.stringify(value || {}) : value);
  const [value, setValue] = useState(getParsedValue(defaultValue, formatAsJson));
  const prevValue = useRef(getParsedValue(defaultValue, formatAsJson));
  const debouncedValue = useDebounce(value, 500);
  const onValueChange = (e) => {
    const updatedValue = e.target.value;
    setValue(updatedValue);
  };

  useEffect(() => {
    const prevStringifiedValue = JSON.stringify(prevValue.current);
    const newStringifiedValue = JSON.stringify(debouncedValue);
    if (prevStringifiedValue !== newStringifiedValue) {
      let parsedData = debouncedValue;
      if (formatAsJson) {
        try {
          parsedData = JSON.parse(debouncedValue);
        } catch (err) {
          console.log('>>> ERR :', err);
        }
      }
      onChange(parsedData);
      prevValue.current = debouncedValue;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <Grid container alignItems="center" className="edit-properties-div__text-input-container">
      <Grid item xs={4}>
        <label htmlFor={label} className="edit-properties-div__label">{label}</label>
      </Grid>
      <Grid item xs={8}>
        <textarea
          className="edit-properties-div__code-editor"
          placeholder={placeholder}
          value={value}
          readOnly={readOnly}
          onChange={onValueChange}
        />
      </Grid>
    </Grid>
  );
}

DebouncedJsonInput.propTypes = {
  label: PropTypes.string.isRequired,
  formatAsJson: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
};

DebouncedJsonInput.defaultProps = {
  placeholder: 'Please enter a value',
};

export default DebouncedJsonInput;
