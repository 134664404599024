import PropTypes from 'prop-types';
import deleteImg from '../../assests/icons/deleteIcon3.svg';
import copyImg from '../../assests/icons/copyIcon.svg';
import addImg from '../../assests/icons/addIcon.svg';
import threeDots from '../../assests/icons/kebabMenuIcon.svg';

function ListFormModuleComponentsV2(props) {
  const {
    formComponents,
    handleOnClick,
    handleOnDelete,
    handleOnAdd,
    handleOnDrag,
    handleOnCopy,
    selectedComponentPath,
    enableCopyButton,
    enableDeleteButton,
    enableAddButton,
    draggedIndexRef,
    handleOnDragOver,
    draggingComponentId,
    handleCopyToClipBoard,
  } = props;
  const clickHandler = (pathArr) => {
    handleOnClick(pathArr);
  };

  const copyClickHandler = (pathArr) => {
    handleOnCopy(pathArr);
  };

  const deleteClickHandler = (pathArr) => {
    handleOnDelete(pathArr);
  };

  const handleDragStart = (id) => {
    draggedIndexRef.current = id;
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (dropId) => {
    handleOnDrag(draggedIndexRef.current, dropId);
  };

  const isCurrentComponentSelected = (currentSelectedIndex, currentIndex) => {
    if (currentSelectedIndex === currentIndex) return true;
    return false;
  };

  return (
    <div className="component-list-div-children">
      {
   (formComponents || []).map((component) => {
     const { tagName, id } = component;
     const key = `${tagName} : ${id}`;
     if (component.subComponents?.length || tagName !== 'span') {
       return (
         <div
           key={`${component.id}`}
           role="menuitem"
           tabIndex={0}
           onKeyUp={() => {}} // currently not handling keyboard events
           draggable
           onDragStart={(event) => { event.stopPropagation(); handleDragStart(component.id); }}
           onDragOver={(event) => { handleDragOver(event); handleOnDragOver(component.id); }}
           onDrop={(event) => { event.stopPropagation(); handleDrop(component.id); }}
           onClick={(event) => { event.stopPropagation(); clickHandler(component.id); }}
         >
           {
          (draggingComponentId === component.id) ?
            <div className="sub-component-list-div__draggingOver" />
            : null
           }
           <div className={`sub-component-list-div${isCurrentComponentSelected(selectedComponentPath, component.id) ? '__active' : ''}`}>
             <div className="sub-component-heading">
               <div className="sub-component-list-div__heading">
                 {component?.text || component?.id}
               </div>
               <button
                 className="sub-component-add-btn"
                 disabled={!enableCopyButton}
                 // eslint-disable-next-line max-len
                 onClick={(event) => { event.stopPropagation(); handleCopyToClipBoard(component.id); }}
                 type="button"
               >
                 <img src={threeDots} alt="copyToClipBoard" />
               </button>
               <button
                 className="sub-component-add-btn"
                 disabled={!enableCopyButton}
                 onClick={(event) => { event.stopPropagation(); copyClickHandler(component.id); }}
                 type="button"
               >
                 <img src={copyImg} alt="copy" />
               </button>
               <button
                 className="sub-component-add-btn"
                 disabled={!enableDeleteButton}
                 onClick={(event) => { event.stopPropagation(); deleteClickHandler(component.id); }}
                 type="button"
               >
                 <img src={deleteImg} alt="delete" />
               </button>
               <button
                 className="sub-component-add-btn"
                 disabled={!enableAddButton}
                 onClick={(event) => { event.stopPropagation(); handleOnAdd(component.id); }}
                 type="button"
               >
                 <img src={addImg} alt="add" />
               </button>
             </div>
             <ListFormModuleComponentsV2
               formComponents={component?.subComponents || []}
               handleOnClick={clickHandler}
               handleOnDelete={deleteClickHandler}
               handleOnAdd={handleOnAdd}
               handleOnCopy={copyClickHandler}
               selectedComponentPath={selectedComponentPath}
               handleOnDrag={(fromComponentId, toComponentId) => {
                 handleOnDrag(fromComponentId, toComponentId);
               }}
               enableCopyButton={enableCopyButton}
               enableDeleteButton={enableDeleteButton}
               enableAddButton={enableAddButton}
               draggedIndexRef={draggedIndexRef}
               handleOnDragOver={handleOnDragOver}
               draggingComponentId={draggingComponentId}
               handleCopyToClipBoard={handleCopyToClipBoard}
             />
           </div>

         </div>
       );
     }

     return (
       <>
         {
          (draggingComponentId === component.id) ?
            <div className="sub-component-list-div__draggingOver" />
            : null
           }
         <div
           key={`${component.id}`}
           role="menuitem"
           tabIndex={0}
           onKeyUp={() => {}} // currently not handling keyboard events
           className={`component-name${isCurrentComponentSelected(selectedComponentPath, component.id) ? '__active' : ''}`}
           onClick={(event) => { event.stopPropagation(); clickHandler(component.id); }}
           draggable
           onDragStart={(event) => { event.stopPropagation(); handleDragStart(component.id); }}
           onDragOver={(event) => { handleDragOver(event); handleOnDragOver(component.id); }}
           onDrop={(event) => { event.stopPropagation(); handleDrop(component.id); }}
         >
           <div className="component-name-label">
             {component[key] || component.text || component.title || component.id}
           </div>
           <div className="component-btn-grp">
             <button
               disabled={!enableCopyButton}
               type="button"
               onClick={(event) => {
                 event.stopPropagation();
                 handleCopyToClipBoard(component.id);
               }}
               className="component-name-dlt-btn"
             >
               <img src={threeDots} alt="copy" />
             </button>
             <button
               disabled={!enableCopyButton}
               type="button"
               onClick={(event) => {
                 event.stopPropagation();
                 copyClickHandler(component.id);
               }}
               className="component-name-dlt-btn"
             >
               <img src={copyImg} alt="copy" />
             </button>
             <button
               type="button"
               disabled={!enableDeleteButton}
               onClick={(event) => {
                 event.stopPropagation();
                 deleteClickHandler(component.id);
               }}
               className="component-name-dlt-btn"
             >
               <img src={deleteImg} alt="delete" />
             </button>
           </div>
         </div>
       </>
     );
   })
}
    </div>
  );
}

export default ListFormModuleComponentsV2;

ListFormModuleComponentsV2.propTypes = {
  formComponents: PropTypes.array.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  handleOnDelete: PropTypes.func.isRequired,
  handleOnAdd: PropTypes.func.isRequired,
  handleOnDrag: PropTypes.func.isRequired,
  handleOnCopy: PropTypes.func.isRequired,
  selectedComponentPath: PropTypes.string.isRequired,
  enableCopyButton: PropTypes.func.isRequired,
  enableDeleteButton: PropTypes.func.isRequired,
  enableAddButton: PropTypes.func.isRequired,
  draggedIndexRef: PropTypes.object.isRequired,
  handleOnDragOver: PropTypes.func.isRequired,
  draggingComponentId: PropTypes.string.isRequired,
  handleCopyToClipBoard: PropTypes.func.isRequired,
};
