import PropTypes, { object } from 'prop-types';
import { TextField } from '@mui/material';
import { getStringInputDisplayValue } from './v2/InputsToModule/utils/findPreviousModules';
import plus from '../../assests/icons/Plus.svg';

function DisplaySDKOutputVariables({
  sdkResponse, setShowModalType, workflowConfig, orderOfModules,
  selectedNodeId,
}) {
  const displayOutputs = () =>
    // eslint-disable-next-line no-unused-vars, implicit-arrow-linebreak
    sdkResponse.map(({ variable, value, id }) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={id} className="workflow_input_field">
        <TextField
          label="variable name"
          variant="outlined"
          value={variable}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          className="workflow_input_field_textfield"
          variant="outlined"
          value={getStringInputDisplayValue(
            value,
            orderOfModules,
            selectedNodeId,
            workflowConfig,
          )}
          label="Value"
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
    ));
  return (
    <div className="default_drawer_content_sub_container">
      <div className="drawer_content__heading_default">
        OUTPUTS OF WORKFLOW
      </div>
      <div className="drawer_content__subheading_default">
        Variables that are outputs of workflow
      </div>
      {
      Object.keys(sdkResponse).length !== 0 ? (
        <div className="workflow_input_form_drawer">
          {displayOutputs()}
        </div>
      ) : null
      }
      <div className="workflowOutput_buttonContainer">
        <button type="button" className="workflowOutput_addBtn" onClick={() => setShowModalType('output')}>
          <img alt="plus" src={plus} />
          <div>Add Output Variables</div>
        </button>
      </div>
    </div>
  );
}

DisplaySDKOutputVariables.propTypes = {
  sdkResponse: PropTypes.array.isRequired,
  setShowModalType: PropTypes.func.isRequired,
  workflowConfig: PropTypes.objectOf(object).isRequired,
  selectedNodeId: PropTypes.string.isRequired,
  orderOfModules: PropTypes.array.isRequired,
};

export default DisplaySDKOutputVariables;
