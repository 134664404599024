/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const moduleBuilderSlice = createSlice({
  name: 'moduleBuilder',
  initialState: {
    inputs: [],
    outputs: [],
    builderProperties: [],
    enabled: false,
  },
  reducers: {
    updateModuleBuilderInputs: (state, action) => {
      const { inputs } = action.payload;
      state.inputs = inputs;
    },
    toggleModuleBuilderMode: (state, action) => {
      const { mode } = action.payload;
      state.enabled = mode;
    },
    updateModuleBuilderOutputs: (state, action) => {
      const { outputs } = action.payload;
      state.outputs = outputs;
    },
    updateModuleBuilderProperties: (state, action) => {
      const { properties } = action.payload;
      state.builderProperties = properties;
    },
    updateModuleBuilderSubtype: (state, action) => {
      const { subType } = action.payload;
      state.subType = subType;
    },

  },
});

export const selectModuleBuilderMode = (state) => state.moduleBuilder.enabled;
export const selectModuleInputs = (state) => state.moduleBuilder.inputs;
export const selectModuleOutputs = (state) => state.moduleBuilder.outputs;
export const selectModuleProperties = (state) => state.moduleBuilder.builderProperties;
export const selectModuleBuilderSubtype = (state) => state.moduleBuilder.subType;

// Action creators are generated for each case reducer function
export const {
  toggleModuleBuilderMode,
  updateModuleBuilderInputs,
  updateModuleBuilderOutputs,
  updateModuleBuilderProperties,
  updateModuleBuilderSubtype,
} = moduleBuilderSlice.actions;
export default moduleBuilderSlice.reducer;
