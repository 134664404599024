/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Toggle.scss';
import { useSelector } from 'react-redux';
import {
  selectSelectedNode, selectSelectedWorkflow, selectVersionedModules,
} from '../../../../reducers/workflow';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';
import { fetchCurrentValueFromWorkflow, getSelectedModule } from '../InputsToModule/utils/updateWorkflow';
import { workflowOperationsObj } from '../../../../workflowOperations';

function Toggle({ description, isDisabled }) {
  const {
    workflowKey, off: offValue, on: onValue, text, default: defaultValue,
  } = description;

  const selectedNode = useSelector(selectSelectedNode);
  const {
    id: selectedNodeId,
    nodeType: selectedNodeType,
    version: selectedNodeVersion = 'v1',
  } = selectedNode || {};
  const versionedModules = useSelector(selectVersionedModules);
  const selectedModuleConfig = versionedModules?.[selectedNodeType]?.[selectedNodeVersion]?.config;
  const selectedWorkflow = useSelector(selectSelectedWorkflow);

  const getDefaultToggleValue = (workflow, moduleId, key) => {
    const module = getSelectedModule(workflow, moduleId);
    const currentValue = fetchCurrentValueFromWorkflow(
      module,
      key,
    );

    const isValueDefinedInWorkflow = currentValue === onValue || currentValue === offValue;
    const finalValue = isValueDefinedInWorkflow ?
      (currentValue === onValue) : (defaultValue === onValue);
    return {
      value: finalValue,
      isValueDefinedInWorkflow,
    };
  };

  const getValueForToggle = (state) => (state === true ? onValue : offValue);

  const [toggle, setToggle] = useState(false);

  const updateValueInWorkflow = (toggleState) => {
    const valueToUpdate = getValueForToggle(toggleState);
    updateWorkflowInState({}, true, {
      operation: workflowOperationsObj.SET_MODULE_PROPERTY,
      actionData: {
        targetNodeId: selectedNodeId,
        workflowKey,
        value: valueToUpdate,
        moduleConfig: selectedModuleConfig,
      },
    });
  };

  useEffect(() => {
    const { value: currentToggleValue } = getDefaultToggleValue(
      selectedWorkflow,
      selectedNodeId,
      workflowKey,
    );
    setToggle(currentToggleValue);
  }, [selectedNodeId, JSON.stringify(selectedWorkflow)]);

  const handleToggle = () => {
    updateValueInWorkflow(!toggle);
  };

  return (
    <div className="description-toggle">
      <div className="description-toggle__label">
        <p>{text}</p>
      </div>
      <div className="toggle">
        <label>
          <input type="checkbox" checked={toggle} onChange={handleToggle} disabled={isDisabled} />
          <span className="slider round" />
        </label>
      </div>
    </div>
  );
}

Toggle.propTypes = {
  description: PropTypes.objectOf(PropTypes.any).isRequired,
  isDisabled: PropTypes.bool,
};

Toggle.defaultProps = {
  isDisabled: false,
};

export default Toggle;
