import { cloneDeep, get, set } from 'lodash';
import { eventHandlers } from '../../components/constants';
import { getSelectedModule } from '../../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';
import { formComponentsBasePath } from '../../constants/dynamicFormComponents';
import generateUniqueID from '../../utils/generateUniqueId';
import { extractComponentIdsForModule } from '../../utils/helper';

export const getFormComponents = (module, rootPath = 'components') => get(module?.properties?.sections?.[0], rootPath, []);

// TODO: Remove this fallback html string post we are able to set HTML
export const getFormHtmlStringForV2 = (module) => module?.properties?.content || '';

export const getComponentFromPath = (components, pathArray) => {
  if (!components?.length || !pathArray?.length) return null;
  if (pathArray.length === 1) return components[pathArray[0]] || null;
  const [currentIndex, ...newPathArray] = pathArray;
  const childComponents = components[currentIndex]?.subComponents;
  return getComponentFromPath(childComponents, newPathArray);
};

export const getSelectedComponent = (module, pathArray, rootPath = 'components') => {
  const components = getFormComponents(module, rootPath);
  if (!components?.length) return null;
  const finalComponent = getComponentFromPath(components, pathArray);
  return finalComponent;
};

const allowedOperations = ['add', 'delete', 'insert', 'update'];

export const operateOnFormComponents = (operation, components, pathArray, newComponent = null) => {
  if (
    !allowedOperations.includes(operation) ||
    typeof components?.length !== 'number' ||
    typeof pathArray?.length !== 'number'
  ) {
    return components;
  }

  const clonnedComponents = cloneDeep(components || []);
  if (pathArray.length === 0 && operation === 'add') {
    clonnedComponents.push(newComponent);
    return clonnedComponents;
  }
  if (pathArray.length === 1 && ['delete', 'insert', 'update'].includes(operation)) {
    if (operation === 'insert') clonnedComponents.splice([pathArray[0]], 0, newComponent);
    if (clonnedComponents?.length > pathArray[0]) {
      if (operation === 'delete') clonnedComponents.splice(pathArray[0], 1);
      if (operation === 'update') clonnedComponents[pathArray[0]] = newComponent;
    }
    return clonnedComponents;
  }
  const [currentIndex, ...newPath] = pathArray;
  const currentSubComponents = clonnedComponents[currentIndex]?.subComponents;
  if (!currentSubComponents) return clonnedComponents;
  const newChildSubComponents = operateOnFormComponents(
    operation,
    currentSubComponents,
    newPath,
    newComponent,
  );
  clonnedComponents[currentIndex].subComponents = newChildSubComponents;
  return clonnedComponents;
};

export const setComponentsArrayAtRootPath = (components, rootPath, module) => {
  const clonnedModule = cloneDeep(module);
  set(clonnedModule.properties.sections[0], rootPath, components);
  return clonnedModule;
};

export const performOpOnWorkflow = (
  operation,
  workflow,
  moduleId,
  pathArray,
  rootPath = 'components',
  newComponent = null,
) => {
  if (!allowedOperations.includes(operation)) return workflow;
  const editedWorkflow = cloneDeep(workflow);
  const selectedModule = getSelectedModule(editedWorkflow, moduleId);
  if (!selectedModule) return editedWorkflow;
  const components = get(selectedModule?.properties?.sections[0], rootPath, []);
  const updatedComponents = operateOnFormComponents(operation, components, pathArray, newComponent);
  set(selectedModule.properties.sections[0], rootPath, updatedComponents);
  return editedWorkflow;
};

// TODO: Deprecate ?
export const getTotalBranches = (components) => {
  let totalBranches = 0;
  (components || []).forEach((component) => {
    eventHandlers.forEach((nextStepEvent) => {
      if (component?.[nextStepEvent]?.nextStep) totalBranches += 1;
    });
    if (component?.subComponents?.length) {
      const totalSubBranches = getTotalBranches(component.subComponents || []);
      totalBranches += totalSubBranches;
    }
  });
  return totalBranches;
};

export const getAllFormComponentsObj = (module) => {
  const basePaths = Object.keys(formComponentsBasePath);
  const componentsObj = {};
  basePaths.forEach((basePath) => {
    componentsObj[basePath] = getFormComponents(module, formComponentsBasePath[basePath]);
  });
  return componentsObj;
};

export const getAllFormComponents = (module) => {
  const allComponentsObj = getAllFormComponentsObj(module);
  const allComponents = Object.values(allComponentsObj).flat();
  return allComponents;
};

export const getAllNextSteps = (
  components,
  nextStepEvents = [],
  includeDynamicHandlers = false,
) => {
  const nextSteps = [];
  (components || []).forEach((component) => {
    if (includeDynamicHandlers && Array.isArray(component?.dynamicHandlers?.handlers)) {
      component?.dynamicHandlers?.handlers.forEach((handler) => {
        if (handler?.nextStep) {
          nextSteps.push({
            nextStepId: handler.nextStep,
            componentId: component?.id,
          });
        }
      });
    }

    nextStepEvents.forEach((nextStepEvent) => {
      if (component?.[nextStepEvent]?.nextStep) {
        nextSteps.push({
          nextStepId: component?.[nextStepEvent]?.nextStep,
          componentId: component?.id,
          nextStepEvent,
        });
      }
    });
    if (component?.subComponents?.length) {
      const subNextSteps = getAllNextSteps(
        component.subComponents || [],
        nextStepEvents,
        includeDynamicHandlers,
      );
      nextSteps.push(...subNextSteps);
    }
  });
  return nextSteps;
};

export const canDeleteComponent = (module, pathArray, rootPath) => {
  const components = getFormComponents(module, rootPath);
  const componentToBeDeleted = getComponentFromPath(components, pathArray);
  if (componentToBeDeleted?.type === 'button' && componentToBeDeleted?.onClick?.nextStep) {
    const allComponents = getAllFormComponents(module);
    const totalBranches = getTotalBranches(allComponents);
    return totalBranches > 1;
  }
  return true;
};

const generateRuleString = (component) => {
  let ruleString = ' ';
  ruleString += ` ${component?.required || ' '}`;
  ruleString += ` ${component?.visible || ' '}`;
  ruleString += ` ${component?.enabled || ' '}`;
  const rulesValidation = (component?.validation || []).filter(
    (validation) => validation?.type === 'rule',
  );
  ruleString += rulesValidation.reduce(
    (accumulator, currentValue) => accumulator + currentValue.value,
    ' ',
  );
  return ruleString;
};

const extractFieldsForComponent = (component, moduleId) => {
  const ruleString = generateRuleString(component);
  let fields = extractComponentIdsForModule(ruleString, moduleId);
  fields = fields.filter((field) => field !== component.id);
  return fields;
};

const getReloadInverseDependency = (components, moduleId) => {
  let dependency = {};
  if (!components?.length) return dependency;
  (components || []).forEach((component) => {
    if (component?.type === 'horizontal' || component?.type === 'vertical') {
      const subComponents = component?.subComponents || [];
      if (subComponents?.length) {
        const subComponentDependency = getReloadInverseDependency(subComponents, moduleId);
        dependency = { ...dependency, ...subComponentDependency };
      }
    }
    const fields = extractFieldsForComponent(component, moduleId);
    dependency[component.id] = fields;
  });
  return dependency;
};

const updateReloadDependency = (components, reloadDependency) => {
  if (!components?.length) return [];
  const clonnedComponents = cloneDeep(components);
  (clonnedComponents || []).forEach((component, index) => {
    if (component?.type === 'horizontal' || component?.type === 'vertical') {
      const subComponents = component?.subComponents || [];
      if (subComponents?.length) {
        const updatedSubComponents = updateReloadDependency(subComponents, reloadDependency);
        clonnedComponents[index].subComponents = updatedSubComponents;
      }
    }
    clonnedComponents[index].onChange = {
      reloadComponents: Object.keys(reloadDependency[component.id] || {}),
    };
  });
  return clonnedComponents;
};

const invertDependency = (onReloadInverseDependency) => {
  const onReloadDependency = {};
  Object.keys(onReloadInverseDependency).forEach((dependency) => {
    const fields = onReloadInverseDependency[dependency];
    fields.forEach((field) => {
      const existingObj = onReloadDependency[field];
      onReloadDependency[field] = { [dependency]: 'present', ...(existingObj || {}) };
    });
  });
  return onReloadDependency;
};

const getReloadDependency = (components, moduleId) => {
  const onReloadInverseDependency = getReloadInverseDependency(components, moduleId);
  const onReloadDependency = invertDependency(onReloadInverseDependency);
  return onReloadDependency;
};

export const updateDependencyForOnReload = (components, moduleId) => {
  const onReloadDependency = getReloadDependency(components, moduleId);
  const updatedComponents = updateReloadDependency(components, onReloadDependency);
  return updatedComponents;
};

// TODO: Write tests for this
export const updateOnReloadDependencyForModule = (module) => {
  if (module.type !== 'dynamicForm') return module;
  const moduleId = module.id;
  const updatedModule = cloneDeep(module);
  const allComponentsObj = getAllFormComponentsObj(updatedModule);
  const basePaths = Object.keys(allComponentsObj);
  const allComponents = basePaths
    .map((basePath) => allComponentsObj[basePath])
    .reduce((acc, curr) => [...acc, ...curr]);

  const onReloadDependency = getReloadDependency(allComponents, moduleId);

  basePaths.forEach((basePathKey) => {
    const actualPathFromSection = formComponentsBasePath[basePathKey];
    const components = allComponentsObj[basePathKey];
    const updatedComponents = updateReloadDependency(components, onReloadDependency);
    set(updatedModule, `properties.sections[0].${actualPathFromSection}`, updatedComponents);
  });
  return updatedModule;
};

export const getdefaultUIValue = (currUiConfig, defaultUiArray, subType = '') => {
  if (!defaultUiArray?.length) return null;
  const defaultUiObjForSubType = defaultUiArray.find((obj) => obj?.subType === subType);
  if (!defaultUiObjForSubType) return null;
  const { key } = defaultUiObjForSubType;
  let uiValue;
  Object.entries(currUiConfig).forEach(([, sectionValue]) => {
    Object.entries(sectionValue).forEach(([innerKey, innerValue]) => {
      if (innerKey === key) uiValue = innerValue;
    });
  });
  return uiValue;
};

const getKeysAllowedToUpdate = (
  selectedComponentType,
  updateConfig,
) => {
  // All the keys can be updated unless a list is specified
  const allowedAllComponentUpdates = updateConfig?.allowedComponentKeysForSubComponents || null;
  const updateEnabled = updateConfig?.enable === true;
  return {
    keysAllowedForUpdate: allowedAllComponentUpdates?.[selectedComponentType] || [],
    canUpdateAllKeys: (allowedAllComponentUpdates === null && updateEnabled),
  };
};

export const getRootConfig = (editConfigs, basePath) => editConfigs
  .find((config) => config.basePath === basePath)
  ?.allowedOperations?.root || {};

export const getAllowedOperations = (rootConfig) => {
  const { update: updateConfig, add: addConfig, delete: deleteConfig } = rootConfig;
  const { allowTypeEdit = true, allowSubTypeEdit = true } = updateConfig || {};
  return {
    canAddComponent: addConfig?.enable === true,
    canDeleteComponent: deleteConfig?.enable === true,
    canUpdateType: updateConfig?.enable === true && allowTypeEdit,
    canUpdateSubType: updateConfig?.enable === true && allowSubTypeEdit,
  };
};

export const getFilteredComponentConfig = (
  allComponentConfigs,
  updateConfig,
  selectedComponentType,
) => {
  const selectedComponentConfig = allComponentConfigs.find(
    (field) => field.type === selectedComponentType,
  );
  const { keysAllowedForUpdate, canUpdateAllKeys } = getKeysAllowedToUpdate(
    selectedComponentType,
    updateConfig,
  );
  const updatedComponentConfig = cloneDeep(selectedComponentConfig);
  if (!canUpdateAllKeys) {
    const updatableKeysHash = keysAllowedForUpdate.map(({ key, value }) => `${key}=${value}`);
    const filteredBrandingKeys = selectedComponentConfig.brandingKeys
      .filter(({ workflowKey, uiKey }) => {
        const hash = `${workflowKey ? 'workflowKey' : 'uiKey'}=${workflowKey || uiKey}`;
        return updatableKeysHash.includes(hash);
      });
    updatedComponentConfig.brandingKeys = filteredBrandingKeys;
  }
  return updatedComponentConfig;
};
export const findFirstGreaterIndex = (fromPathArray, toPathArray) => {
  const minLength = Math.min(fromPathArray.length, toPathArray.length);
  for (let index = 0; index < minLength; index += 1) {
    if (fromPathArray[index] > toPathArray[index]) {
      return index;
    }
    if (fromPathArray[index] < toPathArray[index]) {
      return null;
    }
  }
  return null;
};
export const checkInValidOperation = (fromPathArray, toPathArray) => {
  const minLength = Math.min(fromPathArray.length, toPathArray.length);
  for (let index = 0; index < minLength; index += 1) {
    if (fromPathArray[index] !== toPathArray[index]) {
      return false;
    }
  }
  return true;
};

export const computeFinalDragPath = (components, fromPathArray, toPathArray) => {
  // we need to update the toPathArray in case if we are moving an element from a place
  // on changing which it changes the final position of toPathArray as well. So we used
  // the differing index to find that index
  let updatedPathArray = [...toPathArray];
  const component = getComponentFromPath(components, toPathArray);
  const hasEqualLength = fromPathArray.length === updatedPathArray.length;
  const isLastElementSmaller =
  fromPathArray[fromPathArray.length - 1] < updatedPathArray[updatedPathArray.length - 1];
  const isHorizontalOrVerticalContainer = component.type === 'horizontal' || component.type === 'vertical';
  const draggingIntoContainer = fromPathArray.length < updatedPathArray.length;
  if (draggingIntoContainer) {
    const differingIndex = findFirstGreaterIndex(updatedPathArray, fromPathArray);
    if (differingIndex !== -1 && differingIndex === fromPathArray.length - 1) {
      updatedPathArray[differingIndex] -= 1;
    }
  }
  const isInValidOperation = draggingIntoContainer &&
  checkInValidOperation(fromPathArray, toPathArray);
  if (isInValidOperation) {
    return null;
  }
  if ((hasEqualLength && isLastElementSmaller) && isHorizontalOrVerticalContainer) {
    updatedPathArray[updatedPathArray.length - 1] -= 1;
  }
  if (isHorizontalOrVerticalContainer && component) {
    if (component?.subComponents?.length > 0) {
      updatedPathArray = updatedPathArray.concat(component?.subComponents?.length);
    } else {
      updatedPathArray = updatedPathArray.concat(0);
    }
  }

  return updatedPathArray;
};
export const getModuleFromId = (workflow, moduleId) => workflow.modules.find(
  (workflowModule) => workflowModule.id === moduleId,
);

export const createNewDomId = () => `hv_form_v2_${generateUniqueID()}`;

export const setNodeIdsInHtmlString = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  function nodeToArray(node) {
    // Initialize the array with the tag name
    // eslint-disable-next-line no-param-reassign
    if (!node?.id) node.id = createNewDomId();
    // eslint-disable-next-line no-restricted-syntax
    for (const child of node.children) {
      nodeToArray(child);
    }
  }

  const rootElement = doc.body;
  // eslint-disable-next-line no-restricted-syntax
  for (const child of rootElement.children) {
    nodeToArray(child);
  }
  return rootElement.innerHTML;
};

const getAllChildNodeIds = (element) => {
  const childIds = [element?.id];
  const childNodes = element.getElementsByTagName('*');
  for (let i = 0; i < childNodes.length; i += 1) {
    if (childNodes[i].id) {
      childIds.push(childNodes[i].id);
    }
  }
  return childIds.filter((id) => id?.length);
};

export const deleteNodeFromHtmlString = (domId, htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const element = doc.getElementById(domId);
  let deletedNodeIds = [];
  if (element) {
    deletedNodeIds = getAllChildNodeIds(element);
    element.remove();
  }
  const rootElement = doc.body;
  return { html: rootElement.innerHTML, deletedNodeIds };
};

const updateNodeIds = (domNode, map) => {
  if (domNode) {
    const newId = createNewDomId();
    // eslint-disable-next-line no-param-reassign
    map[domNode.id] = newId;
    // eslint-disable-next-line no-param-reassign
    domNode.id = newId;
    // eslint-disable-next-line no-restricted-syntax
    for (const child of domNode.children) {
      updateNodeIds(child, map);
    }
  }
};

export const copyNodeFromHtmlString = (domId, htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const element = doc.getElementById(domId);
  const map = {};
  if (element) {
    const clonedNode = element.cloneNode(true);
    updateNodeIds(clonedNode, map);
    element.insertAdjacentElement('afterend', clonedNode);
  }
  const rootElement = doc.body;
  return { html: rootElement.innerHTML, map };
};

const getNewNode = (tagName, id, textContent = '') => {
  const element = document.createElement(tagName);
  if (textContent) element.textContent = textContent;
  element.id = id;
  return element;
};

const createNodeFromHtmlString = (htmlString, map) => {
  // Create a new HTML node
  const template = document.createElement('template');
  template.innerHTML = htmlString.trim();
  const newNode = template.content.firstChild;
  const clonedNode = newNode.cloneNode(true);
  updateNodeIds(clonedNode, map);
  return clonedNode;
};

export const addNodeFromHtmlString = (domId, htmlString, htmlFragmentString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const element = doc.getElementById(domId);
  const rootElement = doc.body;
  let newNode = null;
  const map = {};
  if (htmlFragmentString) {
    newNode = createNodeFromHtmlString(htmlFragmentString, map);
  } else {
    newNode = getNewNode('span', createNewDomId(), 'text-goes-here');
  }

  if (element) {
    element.appendChild(newNode);
  } else if (domId === null) {
    rootElement.appendChild(newNode);
  }
  return { html: rootElement.innerHTML, map };
};

export const dragNodeFromHtmlString = (fromDomId, toDomId, htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const draggedElement = doc.getElementById(fromDomId);
  const droppedItem = doc.getElementById(toDomId);
  if (draggedElement && droppedItem) {
    const clonedNode = draggedElement.cloneNode(true);
    droppedItem.insertAdjacentElement('beforebegin', clonedNode);
    draggedElement.remove();
  }
  const rootElement = doc.body;
  return rootElement.innerHTML;
};

export const updateTagNameOfNodeFromHtmlString = (tagName, domId, htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const element = doc.getElementById(domId);
  if (element && element?.tagName?.toLowerCase() !== tagName) {
    const newNode = getNewNode(tagName, domId);
    element.insertAdjacentElement('afterend', newNode);
    element.remove();
  }
  const rootElement = doc.body;
  return rootElement.innerHTML;
};

export const getHTMLStringById = (htmlString, id) => {
  const template = document.createElement('template');
  template.innerHTML = htmlString.trim();
  const element = template.content.getElementById(id);
  return element ? element.outerHTML : null;
};

export const encodeToBase64 = (input) => btoa(input);

export const decodeFromBase64 = (input) => atob(input);
