import PropTypes from 'prop-types';
import { useRef } from 'react';
import addImg from '../../assests/icons/addIcon.svg';
import { getFormComponents } from '../../containers/FormModule/helper';
import ListFormModuleComponents from './ListFormModuleComponents';

function ListFormModule(props) {
  const {
    editConfigurations,
    formModule,
    handleOnClick,
    handleOnDelete,
    handleOnAdd,
    handleOnCopy,
    handleOnDrag,
    formComponentsConfig,
    selectedComponentLocation,
    enableAddButton,
    enableCopyButton,
    enableDeleteButton,
  } = props;
  const {
    pathArray: selectedComponentPathArray,
    basePath: selectedComponentRootPath,
  } = selectedComponentLocation;
  const draggedIndexRef = useRef(null);
  return (
    <div className="component-list-div">
      {editConfigurations.map(({ basePath, title }) => (
        <>
          <div className="component-heading">
            <div className="component-list-div__heading">
              {title}
            </div>
            <button disabled={!enableAddButton} type="button" className="component-add-btn" onClick={(event) => { event.stopPropagation(); handleOnAdd([], basePath); }}>
              <img src={addImg} alt="add" />
            </button>
          </div>
          <ListFormModuleComponents
            formComponents={getFormComponents(formModule, basePath)}
            handleOnClick={(pathArray) => handleOnClick(pathArray, basePath)}
            handleOnDelete={(pathArray) => handleOnDelete(pathArray, basePath)}
            handleOnCopy={(pathArray) => handleOnCopy(pathArray, basePath)}
            handleOnAdd={(pathArray) => handleOnAdd(pathArray, basePath)}
            handleOnDrag={(fromComponentId, toComponentId) => {
              handleOnDrag(fromComponentId, toComponentId, basePath);
            }}
            formComponentsConfig={formComponentsConfig}
            selectedComponentPath={
              selectedComponentRootPath === basePath ?
                selectedComponentPathArray :
                []
            }
            enableCopyButton={enableCopyButton}
            enableDeleteButton={enableDeleteButton}
            enableAddButton={enableAddButton}
            draggedIndexRef={draggedIndexRef}
          />
        </>
      ))}
    </div>
  );
}

export default ListFormModule;

ListFormModule.propTypes = {
  formModule: PropTypes.object.isRequired,
  editConfigurations: PropTypes.array.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  handleOnDelete: PropTypes.func.isRequired,
  handleOnAdd: PropTypes.func.isRequired,
  handleOnCopy: PropTypes.func.isRequired,
  handleOnDrag: PropTypes.func.isRequired,
  formComponentsConfig: PropTypes.array.isRequired,
  selectedComponentLocation: PropTypes.object.isRequired,
  enableAddButton: PropTypes.bool.isRequired,
  enableCopyButton: PropTypes.func.isRequired,
  enableDeleteButton: PropTypes.func.isRequired,
};
