import { useState } from 'react';
import PropTypes from 'prop-types';
import WorkflowOutputVariable from './WorkflowOutputVariable';
import WorkflowOutputSelectionDrawer from './WorkflowOutputSelectionDrawer';
import plus from '../../assests/icons/Plus.svg';

function WorkflowOutput({
  sdkResponse,
  setVariableName,
  deleteVariable,
  setOutputValue,
  menuOptions,
  setSdkResponse,
}) {
  const [activeTab, setActiveTab] = useState(1);
  const [configDrawer, setConfigDrawer] = useState(false);
  const handleClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const openConfigDrawer = () => {
    setConfigDrawer(true);
  };
  const handleAddVariables = (selectedVal) => {
    setSdkResponse(selectedVal);
  };

  const displayForm = () => (
    <div className="workflowOutput__bottomContent_body">
      {
        sdkResponse?.map(({ variable, value, id }) => (
          <WorkflowOutputVariable
            key={id}
            id={id}
            variable={variable}
            value={value}
            setVariableName={setVariableName}
            setOutputValue={setOutputValue}
            deleteVariable={deleteVariable}
            sdkResponse={sdkResponse}
            menuOptions={menuOptions}
          />
        ))
      }
      <div className="workflowOutput_buttonContainer">
        <button type="button" className="workflowOutput_addBtn" onClick={openConfigDrawer}>
          <img alt="plus" src={plus} />
          <div>Add Output Variables</div>
        </button>
      </div>
      {configDrawer && <WorkflowOutputSelectionDrawer page="ModuleOutputs" menuOptions={menuOptions} sdkResponse={sdkResponse} onAddVariables={handleAddVariables} onClose={() => { setConfigDrawer(false); }} />}
    </div>
  );
  return (
    <>
      <div className="workflowInput__topContent">
        <div className="workflowInput__topContent_heading">Workflow Outputs</div>
        <div className="workflowInput__topContent_subheading">
          If you want to get outputs from the workflow, please configure the SDK response below
        </div>
      </div>
      <div className="workflowInput__bottomContent">
        <div className="options-ribbon__configurations">
          <button
            type="button"
            className={`options-ribbon__configurations__tab ${activeTab === 1 ? 'options-ribbon__active' : ''}`}
            onClickCapture={() => handleClick(1)}
          >
            Configure
          </button>
          <button
            type="button"
            className={`options-ribbon__configurations__tab ${activeTab === 2 ? 'options-ribbon__active' : ''}`}
            onClickCapture={() => handleClick(2)}
          >
            Schema Preview
          </button>
        </div>
        {
            activeTab === 2
              ? <span>Work in progress</span>
              : displayForm()
          }
      </div>
    </>
  );
}

WorkflowOutput.propTypes = {
  setVariableName: PropTypes.func.isRequired,
  sdkResponse: PropTypes.array.isRequired,
  deleteVariable: PropTypes.func.isRequired,
  setOutputValue: PropTypes.func.isRequired,
  menuOptions: PropTypes.object.isRequired,
  setSdkResponse: PropTypes.func.isRequired,
};

export default WorkflowOutput;
