import { replaceAll } from '../utils/helper';
import { endStates } from '../components/constants';
import MODULE_COUNTRY_PICKER from '../constants/miscellaneous';

const generateBaseStructureForModule = (subType) => ({
  type: 'superModule',
  id: '<will_be_generated>',
  subType,
  nextStep: '',
  initialStep: '',
  deprecated: false,
  builderProperties: {},
  properties: {},
  variables: [],
  library: {
    properties: {},
    modules: [],
    conditions: {},
    conditionalVariables: {},
  },
});

const generateBaseStructureForUiConfig = (moduleName, description) => ({
  moduleName,
  description,
  sections: {
    inputs: [],
    configurations: [],
    outputs: [],

  },
});

const convertInputTypeToDataType = (inputType) => {
  switch (inputType) {
    case 'toggle':
      return 'boolean';
    case 'singleSelectDropDown':
    case 'multiSelectDropDown':
      return 'array';
    default:
      return 'string';
  }
};

const parseValues = (values) => {
  try {
    const parsedValues = JSON.parse(values);
    return parsedValues;
  } catch (error) {
    return values;
  }
};

const replaceBuilderPropertiesDelimiter = (visibleProperty) => replaceAll(visibleProperty, 'builderProperties.', 'builderProperties[-]');

const transformRawBuilderPropertiesToModuleConfig =
  (builderProperties) => builderProperties.reduce((agg, property) => {
    const propertyValue = {};
    propertyValue.type = convertInputTypeToDataType(property.inputType);
    propertyValue.values = parseValues(property.possibleValues, property.inputType) || '';
    propertyValue.required = property.required === 'yes';
    propertyValue.default = property.defaultValue;
    // eslint-disable-next-line no-param-reassign
    agg[property.name] = propertyValue;
    return agg;
  }, {});

const handleDefaultValuesForBoolean = (value) => value === 'true';

const transformRawInputsToProperties = (inputs) => inputs.reduce((agg, input) => {
  if (input.inputModuleId.includes('builderProperties')) {
    return agg;
  }
  const propertyValue = {};
  const dataType = convertInputTypeToDataType(input.inputType);
  propertyValue.type = dataType;
  propertyValue.values = parseValues(input.possibleValues) || '';
  propertyValue.visible = replaceBuilderPropertiesDelimiter(input.visible || '');
  propertyValue.required = input.required === 'yes';
  propertyValue.default = dataType === 'boolean' ? handleDefaultValuesForBoolean(input.defaultValue) : input.defaultValue;
  const key = `${input.inputModuleId}[+]${input.inputModuleKey}`;
  // eslint-disable-next-line no-param-reassign
  agg[key] = propertyValue;
  return agg;
}, {});

const generateWorkflowKey = (moduleId, moduleKey) => {
  const delimiter = moduleId === 'builderProperties' ? '[-]' : '[+]';
  return `${moduleId}${delimiter}${moduleKey}`;
};

const transformRawOutputToVariables = (outputs) => outputs.map((output) => ({
  name: output.id,
  path: output.selectedValue,
}));

const generateInputsForUiConfig = (inputs) => inputs
  .filter((input) => input.category === 'Input')
  .map((input) => ({
    workflowKey: generateWorkflowKey(input.inputModuleId, input.inputModuleKey),
    type: input.inputType,
    required: input.required === 'yes',
    title: input.name,
    sourceTitle: 'Source',
    sourceLabel: 'Source',
    variableTitle: 'Variable',
    variableLabel: 'Variable',
    visible: replaceBuilderPropertiesDelimiter(input.visible || ''),
  }));

const generateInputsForBuilderProperties = (builderProperties) => builderProperties
  .filter((property) => property.category === 'Input')
  .map((property) => ({
    workflowKey: generateWorkflowKey('builderProperties', property.name),
    type: property.inputType,
    required: property.required === 'yes',
    title: property.name,
    sourceTitle: 'Source',
    sourceLabel: 'Source',
    variableTitle: 'Variable',
    variableLabel: 'Variable',
  }));

const getTextAndLabelBasedOnType = (configuration) => {
  if (!['multiSelectDropDown', 'singleSelectDropDown'].includes(configuration.inputType)) {
    return { text: configuration.name };
  }
  if (configuration.inputType === 'singleSelectDropDown') {
    return {
      label: configuration.name,
      title: configuration.inputTitle,
      text: JSON.parse(configuration.possibleValuesLabels),
    };
  }
  return {
    label: configuration.name,
    title: configuration.inputTitle,
  };
};

const parseDefaultValuesBasedOnType = (defaultValue, inputType) => {
  switch (inputType) {
    case 'multiSelectDropDown':
      return JSON.parse(defaultValue);
    case 'toggle':
      return handleDefaultValuesForBoolean(defaultValue);
    default:
      return defaultValue;
  }
};

const generateConfigurationFromInputsForUiConfig = (inputs) => inputs
  .filter((input) => input.category === 'Configuration')
  .map((configuration) => ({
    workflowKey: generateWorkflowKey(configuration.inputModuleId, configuration.inputModuleKey),
    type: configuration.inputType,
    required: configuration.required === 'yes',
    values: parseValues(configuration.possibleValues),
    ...getTextAndLabelBasedOnType(configuration),
    default: parseDefaultValuesBasedOnType(configuration.defaultValue, configuration.inputType),
    ...(configuration.inputType === 'textbox' ? {
      regex: configuration.regexForTextBox,
      errortext: configuration.errorMessageForTextBox,
      placeholder: configuration.placeholderForTextBox,
    } : {}),
    ...(
      configuration.inputType === 'toggle' && Array.isArray(parseValues(configuration.possibleValues)) ? {
        on: parseValues(configuration.possibleValues)[0],
        off: parseValues(configuration.possibleValues)[1],
      } : {}),

  }));

const generateConfigurationFromBuilderPropertiesForUiConfig =
  (builderProperties) => builderProperties
    .filter((property) => property.category === 'Configuration')
    .map((property) => ({
      workflowKey: generateWorkflowKey('builderProperties', property.name),
      type: property.inputType,
      required: property.required === 'yes',
      values: parseValues(property.possibleValues),
      ...getTextAndLabelBasedOnType(property),
      default: parseDefaultValuesBasedOnType(property.defaultValue, property.inputType),
      ...(property.inputType === 'textbox' ? {
        regex: property.regexForTextBox,
        errortext: property.errorMessageForTextBox,
        placeholder: property.placeholderForTextBox,
      } : {}),
      ...(
        property.inputType === 'toggle' && Array.isArray(parseValues(property.possibleValues)) ? {
          on: parseValues(property.possibleValues)[0],
          off: parseValues(property.possibleValues)[1],
        } : {}),
    }));

const transformOutputsForUiConfig = (outputs) => outputs.map((output) => ({
  type: 'outputItem',
  key: output.id,
  displayName: output.name,
  description: output.description,
}));

export const generateModuleConfigsFromWorkflow = (
  workflow,
  inputs,
  outputs,
  builderProperties,
  subtype,
) => {
  const baseModuleConfig = generateBaseStructureForModule(subtype);
  const workflowStringified = JSON.stringify(workflow);

  const workflowWithBuilderPropertiesReplaced = replaceAll(workflowStringified, 'builderProperties.', 'builderProperties[-]');
  const workflowWithExitNodes = Object.keys(endStates)
    .reduce((intermediateString, keyToReplace) => {
      const replacedString = replaceAll(intermediateString, keyToReplace, 'EXIT_POINT');
      return replacedString;
    }, workflowWithBuilderPropertiesReplaced);
  const { modules, conditionalVariables, conditions } = JSON.parse(workflowWithExitNodes);
  const modulesWithoutCountryPicker = modules.filter(
    (module) => module.id !== MODULE_COUNTRY_PICKER,
  );
  const countryPickerModule = modules.find((module) => module.id === MODULE_COUNTRY_PICKER);

  const modulesWithSuperModuleProperties = modulesWithoutCountryPicker.map((module) => ({
    ...module,
    superModuleType: subtype,
    superModuleId: '',
  }));

  baseModuleConfig.initialStep = countryPickerModule.nextStep;
  baseModuleConfig.library.modules = modulesWithSuperModuleProperties;
  baseModuleConfig.library.conditionalVariables = conditionalVariables;
  baseModuleConfig.library.conditions = conditions;
  baseModuleConfig
    .builderProperties = transformRawBuilderPropertiesToModuleConfig(builderProperties);
  baseModuleConfig.properties = transformRawInputsToProperties(inputs);
  baseModuleConfig.variables = transformRawOutputToVariables(outputs);
  return baseModuleConfig;
};

export const generateModuleUiConfigFromWorkflow =
  (workflow, inputs, outputs, builderProperties) => {
    const baseUiConfig = generateBaseStructureForUiConfig(
      workflow?.properties?.name,
      workflow?.properties?.description,
    );
    baseUiConfig.sections.inputs = [
      ...generateInputsForUiConfig(inputs),
      ...generateInputsForBuilderProperties(builderProperties),
    ];
    baseUiConfig.sections.configurations = [
      ...generateConfigurationFromInputsForUiConfig(inputs),
      ...generateConfigurationFromBuilderPropertiesForUiConfig(builderProperties),
    ];
    baseUiConfig.sections.outputs = transformOutputsForUiConfig(outputs);
    return baseUiConfig;
  };

export const generateWorkflowFromModuleConfig = () => { };

// module.exports = {
//   generateModuleConfigsFromWorkflow,
// }
