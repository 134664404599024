import PropTypes from 'prop-types';
// import { Grid } from '@mui/material';
import './Workflow.scss';
import { Handle, Position } from 'reactflow';
import { useSelector } from 'react-redux';
import conditionalNodeIcon from '../assests/icons/conditionalNodeIcon.svg';
import deleteIcon from '../assests/icons/deleteIcon.svg';
import '../containers/Container.scss';
// import conditionDeleteIcon from '../assests/icons/conditionDeleteIcon.svg';
import { selectSelectedNode, selectSelectedWorkflow } from '../reducers/workflow';

function ConditionNode({ data }) {
  const {
    deleteCondition,
    id,
    parentId,
    name,
  } = data;
  const workflow = useSelector(selectSelectedWorkflow);
  const selectedNode = useSelector(selectSelectedNode);

  const onClickDelete = (event) => {
    event.stopPropagation();
    deleteCondition(id, parentId, workflow);
  };
  return (
    <div className="conditions_module_node_outer">

      <div className={`conditions_module_node ${selectedNode.id === id && 'conditions_module_node_selected'}`}>
        <div className="conditions_module_node_inner">

          <Handle id="topHandle" type="target" position={Position.Top} isConnectable />

          <Handle id="leftHandle" type="source" position={Position.Bottom} isConnectable />

          <img
            src={conditionalNodeIcon}
            alt="edge_two_arrows"
            className="custom_edge_button__img"
          />
          <div className="conditions_module_text">
            {name}
          </div>

          <Handle id="rightHandle" type="source" position={Position.Bottom} isConnectable />
        </div>
      </div>
      <button type="button" className="conditions_module_node_outer__delete_button" onClick={onClickDelete}>
        <img
          src={deleteIcon}
          alt="delete"
        />
      </button>
    </div>
  );
}

ConditionNode.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ConditionNode;
