export const removeAllTheDivWrappers = (wrapperClassName) => {
  const wrappers = document.getElementsByClassName(wrapperClassName);
  // eslint-disable-next-line guard-for-in
  for (let i = 0; i < wrappers.length; i += 1) {
    const wrapper = wrappers[i];
    const parent = wrapper.parentNode;
    if (parent) {
      while (wrapper.firstChild) {
        parent.insertBefore(wrapper.firstChild, wrapper);
      }
      parent.removeChild(wrapper);
    }
  }
};

export const attachBorder = (domId, wrapperClassName, borderStyle) => {
  removeAllTheDivWrappers(wrapperClassName);

  const customComponent = document.getElementById(domId);
  if (!customComponent) return;
  if (customComponent.parentNode.classList.contains(wrapperClassName)) return;

  const wrapper = document.createElement('div');
  wrapper.className = wrapperClassName;

  customComponent.parentNode.insertBefore(wrapper, customComponent);
  wrapper.appendChild(customComponent);
  wrapper.style.border = borderStyle;
};
