import {
  Divider, Grid, Paper, InputBase, IconButton, SvgIcon,
} from '@mui/material';
// import DirectionsIcon from '@mui/icons-material/Directions';
import PropTypes from 'prop-types';
import {
  useState,
//   useMemo,
//   useRef,
//   useEffect,
} from 'react';
import './ChatInput.scss';
// import useDebounce from '../../../../utils/useDebounce';

function ChatInput(props) {
  const {
    defaultInput,
    label,
    placeholder,
    onSave,
    isDisabled,
  } = props;

  const [value, setValue] = useState(defaultInput);
  //   const prevValue = useRef(defaultInput || '');
  //   const debouncedValue = useDebounce(value, 500);

  const onValueChange = (event) => {
    setValue(event.target.value);
  };

  //   useEffect(() => {
  //     if (prevValue.current !== debouncedValue) onSave(debouncedValue);
  //     prevValue.current = debouncedValue;
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [debouncedValue]);

  const handleClick = () => {
    onSave(value);
  };

  return (
    <div className="text-input-div">
      <Grid container alignItems="center" rowSpacing={1}>
        <Grid item xs={12} className={isDisabled ? 'text-input-div__label--disabled' : ''}>
          {label}
        </Grid>
        <Grid item xs={12}>
          <Paper
            className="chat-input-div__text-input"
            component="form"
            sx={{
              p: '2px 4px', display: 'flex', alignItems: 'center',
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={placeholder}
            //   inputProps={{ 'aria-label': 'search google maps' }}
              value={value}
              onChange={onValueChange}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={handleClick}>
              {/* <DirectionsIcon /> */}
              <SvgIcon>
                {/* credit: plus icon from https://heroicons.com/ */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495"
                  />
                </svg>
              </SvgIcon>
            </IconButton>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

ChatInput.propTypes = {
  label: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  defaultInput: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

ChatInput.defaultProps = {
  isDisabled: false,
};

export default ChatInput;
