import PropTypes from 'prop-types';
import NestedDropdownWithInputs from '../ViewWorkflow/v2/NestedDropdown/NestedDropdown';
import './ModuleInputsEditPanel.scss';
import TextInput from '../ImportWorkflow/TextInput';

// TODO Add visible property
function ModuleOutputsEditPanel({
  id,
  name,
  description,
  dropDownOptions,
  selectedValue,
  setModuleOutputProperty,
}) {
  return (
    <div className="module-builder-inputs-container">
      <div className="module-builder-inputs-container-dropdown">
        <NestedDropdownWithInputs
          defaultValue={selectedValue}
          items={dropDownOptions}
          noSelectLabel="Select Module Property type"
          onChange={(selectedItem) => setModuleOutputProperty(id, 'selectedValue', selectedItem.value)}
        />
      </div>
      <div className="module-builder-inputs-container-dropdown">
        <TextInput
          label="Input name"
          placeholder={name}
          isInvalid={!name?.length}
          setDefaultValue
          onChange={(value) => setModuleOutputProperty(id, 'name', value)}
        />
      </div>
      <div className="module-builder-inputs-container-dropdown">
        <TextInput
          label="Input description"
          placeholder={description}
          isInvalid={!description?.length}
          setDefaultValue
          onChange={(value) => setModuleOutputProperty(id, 'description', value)}
        />
      </div>
    </div>
  );
}

ModuleOutputsEditPanel.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  dropDownOptions: PropTypes.string.isRequired,
  selectedValue: PropTypes.string,
  setModuleOutputProperty: PropTypes.func.isRequired,
};

ModuleOutputsEditPanel.defaultProps = {
  description: '',
  selectedValue: null,
};

export default ModuleOutputsEditPanel;
