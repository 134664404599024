import PropTypes from 'prop-types';
import deleteImg from '../../assests/icons/deleteIcon3.svg';
import copyImg from '../../assests/icons/copyIcon.svg';
import addImg from '../../assests/icons/addIcon.svg';

function ListFormModuleComponents(props) {
  const {
    formComponents,
    handleOnClick,
    handleOnDelete,
    handleOnAdd,
    handleOnDrag,
    handleOnCopy,
    formComponentsConfig,
    selectedComponentPath,
    enableCopyButton,
    enableDeleteButton,
    enableAddButton,
    draggedIndexRef,
  } = props;
  const clickHandler = (pathArr) => {
    handleOnClick(pathArr);
  };

  const copyClickHandler = (pathArr) => {
    handleOnCopy(pathArr);
  };

  const deleteClickHandler = (pathArr) => {
    handleOnDelete(pathArr);
  };

  const handleDragStart = (id) => {
    draggedIndexRef.current = id;
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (dropId) => {
    handleOnDrag(draggedIndexRef.current, dropId);
  };

  const getSelectedIndexForChild = (currentSelectedIndex, currentIndex) => {
    if (currentSelectedIndex?.length && currentSelectedIndex[0] === currentIndex) {
      const newSelectedIndexArr = [...currentSelectedIndex];
      newSelectedIndexArr.shift();
      return newSelectedIndexArr;
    }
    return [];
  };

  const isCurrentComponentSelected = (currentSelectedIndex, currentIndex) => {
    if (currentSelectedIndex.length === 1 && currentSelectedIndex[0] === currentIndex) return true;
    return false;
  };

  return (
    <div className="component-list-div-children">
      {
   (formComponents || []).map((component, index) => {
     const key = (formComponentsConfig.filter(
       (comp) => comp.type === component.type,
     )[0]
     )?.primaryBrandingKey;
     if (component.type === 'vertical' || component.type === 'horizontal') {
       return (
         <div
           key={`${component.id}`}
           role="menuitem"
           tabIndex={0}
           onKeyUp={() => {}} // currently not handling keyboard events
           draggable
           onDragStart={(event) => { event.stopPropagation(); handleDragStart(component.id); }}
           onDragOver={handleDragOver}
           onDrop={(event) => { event.stopPropagation(); handleDrop(component.id); }}
           onClick={(event) => { event.stopPropagation(); clickHandler([index]); }}
         >
           <div className="sub-component-list-div">
             <div className="sub-component-heading">
               <div className="sub-component-list-div__heading">
                 {component?.text || component?.id}
               </div>
               <button
                 className="sub-component-add-btn"
                 disabled={!enableCopyButton}
                 onClick={(event) => { event.stopPropagation(); copyClickHandler([index]); }}
                 type="button"
               >
                 <img src={copyImg} alt="copy" />
               </button>
               <button
                 className="sub-component-add-btn"
                 disabled={!enableDeleteButton}
                 onClick={(event) => { event.stopPropagation(); deleteClickHandler([index]); }}
                 type="button"
               >
                 <img src={deleteImg} alt="delete" />
               </button>
               <button
                 className="sub-component-add-btn"
                 disabled={!enableAddButton}
                 onClick={(event) => { event.stopPropagation(); handleOnAdd([index]); }}
                 type="button"
               >
                 <img src={addImg} alt="add" />
               </button>
             </div>
             <ListFormModuleComponents
               formComponents={component?.subComponents}
               handleOnClick={(path) => clickHandler([index, ...path])}
               handleOnDelete={(path) => deleteClickHandler([index, ...path])}
               handleOnAdd={(path) => handleOnAdd([index, ...path])}
               handleOnCopy={(path) => copyClickHandler([index, ...path])}
               selectedComponentPath={getSelectedIndexForChild(selectedComponentPath, index)}
               formComponentsConfig={formComponentsConfig}
               handleOnDrag={(fromComponentId, toComponentId) => {
                 handleOnDrag(fromComponentId, toComponentId);
               }}
               enableCopyButton={enableCopyButton}
               enableDeleteButton={enableDeleteButton}
               enableAddButton={enableAddButton}
               draggedIndexRef={draggedIndexRef}
             />
           </div>

         </div>
       );
     }

     return (
       <div
         key={`${component.id}`}
         role="menuitem"
         tabIndex={0}
         onKeyUp={() => {}} // currently not handling keyboard events
         className={`component-name${isCurrentComponentSelected(selectedComponentPath, index) ? '__active' : ''}`}
         onClick={(event) => { event.stopPropagation(); clickHandler([index]); }}
         draggable
         onDragStart={(event) => { event.stopPropagation(); handleDragStart(component.id); }}
         onDragOver={handleDragOver}
         onDrop={(event) => { event.stopPropagation(); handleDrop(component.id); }}
       >
         <div className="component-name-label">
           {component[key] || component.text || component.title || component.id}
         </div>
         <div className="component-btn-grp">
           <button
             disabled={!enableCopyButton}
             type="button"
             onClick={(event) => {
               event.stopPropagation();
               copyClickHandler([index]);
             }}
             className="component-name-dlt-btn"
           >
             <img src={copyImg} alt="copy" />
           </button>
           <button
             type="button"
             disabled={!enableDeleteButton}
             onClick={(event) => {
               event.stopPropagation();
               deleteClickHandler([index]);
             }}
             className="component-name-dlt-btn"
           >
             <img src={deleteImg} alt="delete" />
           </button>
         </div>
       </div>
     );
   })
}
    </div>
  );
}

export default ListFormModuleComponents;

ListFormModuleComponents.propTypes = {
  formComponents: PropTypes.array.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  handleOnDelete: PropTypes.func.isRequired,
  handleOnAdd: PropTypes.func.isRequired,
  handleOnDrag: PropTypes.func.isRequired,
  handleOnCopy: PropTypes.func.isRequired,
  formComponentsConfig: PropTypes.array.isRequired,
  selectedComponentPath: PropTypes.array.isRequired,
  enableCopyButton: PropTypes.func.isRequired,
  enableDeleteButton: PropTypes.func.isRequired,
  enableAddButton: PropTypes.func.isRequired,
  draggedIndexRef: PropTypes.object.isRequired,
};
