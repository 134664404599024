import Joi from 'joi-browser';
import { cloneDeep, set } from 'lodash';
import { setValueInWorkflow, unSetValueInWorkflow } from '../components/ViewWorkflow/v2/ConfigurationPanel/helper';
import {
  setModulePropertyInWorkflow,
  setPreviousStepInWorkflow,
  unsetModulePropertyInWorkflow,
  unsetPreviousStepInWorkflow,
} from '../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';
import {
  addNewNodeInWorkflow,
  deleteNodeFromWorkflow,
  editIfTrueIfFalseReason,
  swapBranchesInCondition,
  updateConditionRule,
  updateNodeName,
} from '../containers/workflowOperations';

const workflowOperations = {
  DELETE_CONDITION: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      // TODO: Can this parentId be removed?
      parentId: Joi.string().required(),
    }),
    // Controller
    operatingFn: (workflow, actionData) => {
      const { targetNodeId, parentId } = actionData;
      const { workflow: operatedWorkflow, success } =
        deleteNodeFromWorkflow(targetNodeId, parentId, workflow);
      if (success) return { workflow: operatedWorkflow, success };
      return { workflow, success: false };
    },
  },
  DELETE_MODULE: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      // TODO: Can this parentId be removed?
      parentId: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const { targetNodeId, parentId } = actionData;
      const { workflow: operatedWorkflow, success } =
        deleteNodeFromWorkflow(targetNodeId, parentId, workflow);
      if (success) return { workflow: operatedWorkflow, success };
      return { workflow, success: false };
    },
  },
  ADD_CONDITION: {
    actionDataSchema: Joi.object({
      addNodeBetween: Joi.object({
        parent: Joi.string().required(),
        child: Joi.string().required(),
      }),
      // TODO: Remove dependency on the below two properties.
      nodes: Joi.array(),
      localOrderOfNodes: Joi.array(),
    }),
    operatingFn: (workflow, actionData) => {
      const { addNodeBetween, nodes, localOrderOfNodes } = actionData;
      const { workflow: operatedWorkflow, newNodeId, success } =
        addNewNodeInWorkflow({
          addNodeBetween, nodes, workflowConfig: workflow, localOrderOfNodes, type: 'condition',
        });
      if (success) return { workflow: operatedWorkflow, extraData: { newNodeId }, success };
      return { workflow, success: false };
    },
  },
  ADD_MODULE: {
    actionDataSchema: Joi.object({
      node: Joi.object(),
      addNodeBetween: Joi.object({
        parent: Joi.string().required(),
        child: Joi.string().required(),
      }),
      countryDocMapping: Joi.object(),
      defaultFormSections: Joi.array(),
      // TODO: Remove dependency on the below two properties.
      nodes: Joi.array(),
      localOrderOfNodes: Joi.array(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        node, countryDocMapping, defaultFormSections, addNodeBetween, nodes, localOrderOfNodes,
      } = actionData;
      const {
        workflow: operatedWorkflow, highLevelUiConfig, newModule, success,
      } =
        addNewNodeInWorkflow({
          node,
          addNodeBetween,
          nodes,
          workflowConfig: workflow,
          countryDocMapping,
          localOrderOfNodes,
          defaultFormSections,
          type: 'module',
        });
      if (success) {
        return {
          workflow: operatedWorkflow,
          extraData: { highLevelUiConfig, newModule },
          success,
        };
      }
      return { workflow, success: false };
    },
  },
  SET_WORKFLOW_ATTRIBUTE: {
    actionDataSchema: Joi.object({
      path: Joi.string().required(),
      value: Joi.any().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        path, value,
      } = actionData;
      const clonedWorkflow = cloneDeep(workflow);
      set(clonedWorkflow, path, value);
      return { workflow: clonedWorkflow, success: true };
    },
  },
  SET_MODULE_PROPERTY: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      workflowKey: Joi.string().required(),
      value: Joi.any().required(),
      moduleConfig: Joi.object().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId,
        workflowKey,
        value,
        moduleConfig,
      } = actionData;
      const updatedWorkflow = setModulePropertyInWorkflow(
        workflow,
        targetNodeId,
        workflowKey,
        value,
        moduleConfig,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  SET_PREVIOUS_STEP: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      previousStep: Joi.string().required().allow(''),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId,
        previousStep,
      } = actionData;
      const updatedWorkflow = setPreviousStepInWorkflow(
        workflow,
        targetNodeId,
        previousStep,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  UNSET_PREVIOUS_STEP: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId,
      } = actionData;
      const updatedWorkflow = unsetPreviousStepInWorkflow(
        workflow,
        targetNodeId,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  UNSET_MODULE_PROPERTY: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      workflowKey: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId,
        workflowKey,
      } = actionData;
      const updatedWorkflow = unsetModulePropertyInWorkflow(
        workflow,
        targetNodeId,
        workflowKey,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  SWAP_CONDITION_BRANCHES: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId,
      } = actionData;
      const updatedWorkflow = swapBranchesInCondition(
        targetNodeId,
        workflow,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  SET_CONDITION_REASON: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      branch: Joi.string().valid('if_true', 'if_false').required(),
      reason: Joi.string().allow('').required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, branch, reason,
      } = actionData;
      const updatedWorkflow = editIfTrueIfFalseReason(
        workflow,
        targetNodeId,
        branch,
        reason,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  SET_CONDITION_RULE: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      rule: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, rule,
      } = actionData;
      const updatedWorkflow = updateConditionRule(
        workflow,
        targetNodeId,
        rule,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  SET_NODE_NAME: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      name: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, name,
      } = actionData;
      const { workflow: updatedWorkflow, success } = updateNodeName(
        workflow,
        targetNodeId,
        name,
      );
      return { workflow: updatedWorkflow, success };
    },
  },
  SET_VALUE_IN_WORKFLOW: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      rootWorkflowKey: Joi.string().required(),
      value: Joi.any().required(),
      moduleConfig: Joi.object().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, rootWorkflowKey, value, moduleConfig,
      } = actionData;
      const updatedWorkflow = setValueInWorkflow(
        workflow,
        value,
        rootWorkflowKey,
        targetNodeId,
        moduleConfig,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  UNSET_VALUE_IN_WORKFLOW: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      rootWorkflowKey: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, rootWorkflowKey,
      } = actionData;
      const updatedWorkflow = unSetValueInWorkflow(
        workflow,
        rootWorkflowKey,
        targetNodeId,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
};

export const supportedWorkflowOperations = Object.keys(workflowOperations);

export const workflowOperationsObj = supportedWorkflowOperations
  .reduce((acc, curr) => ({ [curr]: curr, ...acc }), { });

export const operateOnWorkflow = (workflow, action) => {
  try {
    const { operation = null, actionData = {} } = action;
    // TODO: success is true because the operation is not yet migrated,
    // Will make it false once all are migrated.
    if (!supportedWorkflowOperations.includes(operation)) return { workflow, success: true };
    const { actionDataSchema, operatingFn } = workflowOperations[operation];
    const { error } = actionDataSchema.validate(actionData);
    // TODO: Log error here ?
    if (error === null) return operatingFn(workflow, actionData);
    return { workflow, success: false };
  } catch (err) {
    // TODO: Log error here?
    return { workflow, success: false };
  }
};
