import { cloneDeep, set, unset } from 'lodash';
import { copyUiConfigurations, findPathArrayForComponentId } from '../../components/FormModule/utils';
import { getSelectedModule } from '../../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';
import { formComponentList } from '../../constants/dynamicFormComponents';
import { logDeleteFormComponent, logDragFormComponent, logSetFormComponentProperty } from '../../logger/logHighLevelWorkflowUpdates';
import { updateReloadSdk, updateSelectedComponentPath } from '../../reducers/dynamicForm';
import { updateCustomUiConfig } from '../../reducers/editBranding';
import store from '../../store';
import { workflowOperationsObj } from '../../workflowOperations';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import {
  addComponent, copyComponent, deleteComponent, dragComponent, updateComponent,
} from '../FormModule/formOperations';
import {
  canDeleteComponent as isLastButton,
  getComponentFromPath,
  getFormComponents,
  updateOnReloadDependencyForModule,
  computeFinalDragPath,
  getModuleFromId,
} from '../FormModule/helper';

const processWorkflowBeforeSave = (inputWorkflow) => {
  const workflow = cloneDeep(inputWorkflow);
  const updatedModules = workflow.modules.map((module) => {
    if (module.type === 'dynamicForm') {
      const updatedModule = updateOnReloadDependencyForModule(module);
      return updatedModule;
    }
    return module;
  });
  workflow.modules = updatedModules;
  return workflow;
};

const updateWorkflow = (workflow, rootPath, isFormPartOfSuperModule, moduleId) => {
  if (!isFormPartOfSuperModule) {
    const processedWorkflow = processWorkflowBeforeSave(workflow);
    updateWorkflowInState(processedWorkflow, true, { sourceType: 'dynamicForm' });
    store.dispatch(updateReloadSdk());
  } else {
    const updatedModule = getSelectedModule(workflow, moduleId);
    const { mappingId, superModuleId } = updatedModule;
    const updatedComponents = getFormComponents(updatedModule, rootPath);
    updateWorkflowInState({}, true, {
      operation: workflowOperationsObj.SET_MODULE_PROPERTY,
      actionData: {
        targetNodeId: superModuleId,
        workflowKey: `${mappingId}[+]sections[0].${rootPath}`,
        value: updatedComponents,
        moduleConfig: {
          key: 'something',
        },
      },
    });
  }
};

export const handleOnDrag = ({
  fromComponentId,
  toComponentId,
  rootPath,
  isFormPartOfSuperModule,
  workflow,
  moduleId,
}) => {
  const module = workflow.modules.find(
    (workflowModule) => workflowModule.id === moduleId,
  );
  if (fromComponentId !== toComponentId) {
    const components = getFormComponents(module, rootPath);
    const fromPathArray = findPathArrayForComponentId(fromComponentId, components);
    const toPathArray = findPathArrayForComponentId(toComponentId, components);
    const updatedToPathArray = computeFinalDragPath(components, fromPathArray, toPathArray);
    if (fromPathArray.length > 0 && toPathArray.length > 0 && updatedToPathArray) {
      logDragFormComponent({
        id: moduleId,
        fromPathArray,
        updatedToPathArray,
      });
      const updatedWorkflow = dragComponent(
        workflow,
        moduleId,
        fromPathArray,
        updatedToPathArray,
        rootPath,
      );
      updateWorkflow(updatedWorkflow, rootPath, isFormPartOfSuperModule, moduleId);
    }
  }
};

export const handleOnAdd = ({
  pathArray,
  rootPath,
  moduleId,
  isFormPartOfSuperModule,
  workflow,
}) => {
  const module = getModuleFromId(workflow, moduleId);
  const components = getFormComponents(module, rootPath);
  const component = getComponentFromPath(components, pathArray);

  const indexToAddInPathArray = component ? component.subComponents.length : components.length;
  const defaultConfig = formComponentList[0];
  const updatedWorkflow = addComponent(workflow, moduleId, defaultConfig, pathArray, rootPath);
  updateWorkflow(updatedWorkflow, rootPath, isFormPartOfSuperModule, moduleId);
  store.dispatch(updateSelectedComponentPath(
    { pathArray: [...pathArray, indexToAddInPathArray], basePath: rootPath },
  ));
  // Currently adding the first component by default on add
};

export const handleOnCopy = ({
  pathArray,
  rootPath,
  moduleId,
  isFormPartOfSuperModule,
  workflow,
  customUiConfig,
}) => {
  const { workflow: updatedWorkflow, originalToClonedComponentIdMap } = copyComponent(
    workflow,
    moduleId,
    pathArray,
    rootPath,
    formComponentList,
  );
  const updatedUiConfig = copyUiConfigurations(
    customUiConfig,
    moduleId,
    originalToClonedComponentIdMap,
  );
    // TODO: Create a helper function for this
  store.dispatch(updateCustomUiConfig({ uiConfig: updatedUiConfig }));
  updateWorkflow(updatedWorkflow, rootPath, isFormPartOfSuperModule, moduleId);
};

export const handleOnUpdate = ({
  pathArray,
  rootPath,
  moduleId,
  isFormPartOfSuperModule,
  workflow,
  newComponent,
  componentUIConfig,
  currUiConfig,
}) => {
  const editedWorkflow = cloneDeep(workflow);
  const editedUiConfig = cloneDeep(currUiConfig);

  // Remove UI config of old component if present
  const module = workflow.modules.find(
    (workflowModule) => workflowModule.id === moduleId,
  );
  const components = getFormComponents(module, rootPath);
  const componentToBeDeleted = getComponentFromPath(components, pathArray);
  const oldCompPath = `${moduleId}.${componentToBeDeleted.id}`;
  const isUiConfigUpdated = unset(editedUiConfig, oldCompPath);
  const hasComponentUiConfig = Boolean(Object.keys(componentUIConfig || {}).length);

  if (hasComponentUiConfig) {
    // Add UI config of new component
    // TODO: Set high level UI config if form is part of super module.
    const newCompPath = `${moduleId}.${newComponent.id}`;
    set(editedUiConfig, newCompPath, componentUIConfig);
  }
  const setUiConfigSourceToCustom = isUiConfigUpdated || hasComponentUiConfig;
  if (setUiConfigSourceToCustom) store.dispatch(updateCustomUiConfig({ uiConfig: editedUiConfig }));

  const updatedWorkflow = updateComponent(
    editedWorkflow,
    moduleId,
    newComponent,
    pathArray,
    rootPath,
    setUiConfigSourceToCustom,
  );

  logSetFormComponentProperty({
    id: moduleId,
    pathArray,
    newComponent,
  });
  updateWorkflow(updatedWorkflow, rootPath, isFormPartOfSuperModule, moduleId);
};

const isComponentDependent = (module, pathArray, rootPath, currentWorkflow, checkDepsFn) => {
  const components = getFormComponents(module, rootPath);
  const componentToBeDeleted = getComponentFromPath(components, pathArray);
  const isDependent = checkDepsFn({
    variableId: componentToBeDeleted.id,
    nodeId: module.id,
    workflow: currentWorkflow,
  });
  return isDependent;
};

export const handleOnDelete = ({
  pathArray,
  rootPath,
  moduleId,
  isFormPartOfSuperModule,
  checkDepsFn,
  currCustomUiConfig,
  workflow,
  selectedComponentPathArray,
}) => {
  // TODO: Move it to the below function
  const currSelectedModule = getSelectedModule(workflow, moduleId);
  const isDependent =
    isComponentDependent(currSelectedModule, pathArray, rootPath, workflow, checkDepsFn);
  if (isDependent) return null;
  const canDelete = isLastButton(currSelectedModule, pathArray, rootPath);
  if (!canDelete) {
    alert('Can not delete this button as this is the last one');
    return null;
  }
  const module = getModuleFromId(workflow, moduleId);
  const components = getFormComponents(module, rootPath);
  const pathArrayforParent = pathArray.slice(0, pathArray.length - 1);
  const component = getComponentFromPath(components, pathArrayforParent);
  const indexToAddInPathArray = component ? component.subComponents?.length : components.length;
  if (JSON.stringify(pathArray) === JSON.stringify(selectedComponentPathArray)) {
    if (indexToAddInPathArray === pathArray[pathArray.length - 1] + 1) {
      const updatedPathArray = pathArray.slice();
      if (updatedPathArray[updatedPathArray.length - 1] !== 0) {
        updatedPathArray[updatedPathArray.length - 1] -= 1;
      } else {
        updatedPathArray.pop();
      }
      store.dispatch(updateSelectedComponentPath(
        { basePath: rootPath, pathArray: [...updatedPathArray] },
      ));
    } else {
      store.dispatch(updateSelectedComponentPath(
        { basePath: rootPath, pathArray: [...pathArray] },
      ));
    }
  }
  const componentToBeDeleted = getComponentFromPath(components, pathArray);
  const editedUiConfig = cloneDeep(currCustomUiConfig);
  unset(editedUiConfig, `${moduleId}.${componentToBeDeleted.id}`);

  const updatedWorkflow = deleteComponent(
    workflow,
    moduleId,
    pathArray,
    rootPath,
  );
  logDeleteFormComponent({
    id: moduleId,
    pathArray,
  });
  updateWorkflow(updatedWorkflow, rootPath, isFormPartOfSuperModule, moduleId);
  store.dispatch(updateCustomUiConfig({ uiConfig: editedUiConfig }));
  return null;
};
