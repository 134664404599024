import { checkComponentForNextStep, getNextStepForModule } from '../components/utils';
import { getAllFormComponents } from '../containers/FormModule/helper';
import { endStates } from '../components/constants';

// TODO: Update the test cases for this
export const isNextStepPresentInDynamicForm = (workflow) => {
  const isEveryModuleValid = workflow.modules.every((module) => {
    if (module.type === 'dynamicForm') {
      const formComponents = getAllFormComponents(module);
      return formComponents.some((component) => checkComponentForNextStep(component));
    }
    if (module.type === 'dynamicFormV2') {
      // TODO: remove this hardcoded true.
      // const allNextSteps = getNextStepForModule(module);
      // return allNextSteps?.length;
      return true;
    }
    return module.nextStep !== '';
  });
  return isEveryModuleValid;
};

export const isNextStepOfNodeNotPointingToItSelf = (workflow) => {
  const { conditions = {}, modules } = workflow;

  const selfReferencingConditionsPresent = Object.keys(conditions).some((conditionId) => {
    const { if_true: ifTrueId, if_false: ifFalseId } = conditions[conditionId];
    return (conditionId === ifFalseId || conditionId === ifTrueId);
  });
  if (selfReferencingConditionsPresent) return false;

  const selfReferencingModulesPresent = modules.some((module) => {
    const nextSteps = getNextStepForModule(module);
    const anyNextStepPointingToSelf = nextSteps.some(({ nextStep }) => nextStep === module.id);
    return anyNextStepPointingToSelf;
  });
  if (selfReferencingModulesPresent) return false;
  return true;
};

export const isDeletedModulesOrConditionsPresentInWorkflow = (workflow) => {
  const { modules, conditions } = workflow;
  const deletedModulesOrConditions = [];
  const removeDuplicates = (array) => Array.from(new Set(array));
  const isStepPresent = (step) => modules.some(
    (mod) => mod.id === step,
  ) || Object.keys(conditions).includes(step);
  modules.forEach((module) => {
    const nextSteps = getNextStepForModule(module);
    nextSteps.forEach(({ nextStep }) => {
      if (
        !endStates[nextStep] &&
        !modules.some((mod) => mod.id === nextStep) &&
        !Object.keys(conditions).includes(nextStep) &&
        nextStep !== 'dismissToParent'
      ) {
        deletedModulesOrConditions.push(nextStep);
      }
    });
  });
  Object.values(conditions).forEach((condition) => {
    if (condition.next_node_type && typeof condition.next_node_type.if_true === 'string') {
      if (condition.next_node_type.if_true.toLowerCase() === 'goto' && !isStepPresent(condition.if_true)) {
        deletedModulesOrConditions.push(condition.if_true);
      }
    }
    if (condition.next_node_type && typeof condition.next_node_type.if_false === 'string') {
      if (condition.next_node_type.if_false.toLowerCase() === 'goto' && !isStepPresent(condition.if_false)) {
        deletedModulesOrConditions.push(condition.if_false);
      }
    }
  });
  const uniqueDeletedModulesOrConditions = removeDuplicates(deletedModulesOrConditions);
  return uniqueDeletedModulesOrConditions;
};
