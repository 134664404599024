import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import TextInput from './TextInput';
import { fetchCurrentValueFromWorkflow, getSelectedModule } from '../InputsToModule/utils/updateWorkflow';
import { selectSelectedNode, selectSelectedWorkflow, selectVersionedModules } from '../../../../reducers/workflow';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';
import { workflowOperationsObj } from '../../../../workflowOperations';

function TextInputWrapper({ element, isDisabled }) {
  const {
    workflowKey, text: label, errortext: errorText, placeholder, regex, default: defaultValue,
  } = element;

  const selectedWorkflow = useSelector(selectSelectedWorkflow);

  const selectedNode = useSelector(selectSelectedNode);
  const {
    id: selectedNodeId,
    nodeType: selectedNodeType,
    version: selectedNodeVersion = 'v1',
  } = selectedNode || {};
  const versionedModules = useSelector(selectVersionedModules);
  const selectedModuleConfig = versionedModules?.[selectedNodeType]?.[selectedNodeVersion]?.config;

  const defaultInput = useMemo(() => {
    const selectedModule = getSelectedModule(selectedWorkflow, selectedNodeId);
    const currentValue = fetchCurrentValueFromWorkflow(
      selectedModule,
      workflowKey,
    );
    const valueFetchedFromWorkflow = typeof currentValue === 'string';
    const fallbackValue = typeof defaultValue === 'string' ? defaultValue : null;
    const value = valueFetchedFromWorkflow ? currentValue : fallbackValue;
    return value;
  }, [defaultValue, selectedModuleConfig, selectedNodeId, selectedWorkflow, workflowKey]);

  const onSave = (text) => {
    updateWorkflowInState({}, true, {
      operation: workflowOperationsObj.SET_MODULE_PROPERTY,
      actionData: {
        targetNodeId: selectedNodeId,
        workflowKey,
        value: text,
        moduleConfig: selectedModuleConfig,
      },
    });
  };

  return (
    <div className="text-input-wrapper">
      <TextInput
        defaultInput={defaultInput}
        label={label}
        errorText={errorText}
        placeholder={placeholder}
        regex={regex}
        onSave={onSave}
        isDisabled={isDisabled}
      />
    </div>
  );
}

TextInputWrapper.propTypes = {
  element: PropTypes.objectOf(PropTypes.any).isRequired,
  isDisabled: PropTypes.bool,
};

TextInputWrapper.defaultProps = {
  isDisabled: false,
};

export default TextInputWrapper;
