const standartWorkflow = {
  properties: {
    name: 'Default Name',
    description: 'Default Description',
    builtOnBuilder: true,
    enableResumeWorkflow: false,
    redirectToMobile: 'optional',
    sdkVersions: {
      mobile: {
        maximum: '10.0.0',
        minimum: '0.3.0',
      },
      web: {
        maximum: '10.0.0',
        minimum: '5.0.0',
      },
    },
  },
  conditions: {},
  modules: [
    {
      type: 'countries',
      subType: 'countries',
      id: 'module_countryPicker',
      nextStep: 'approve',
      properties: {
        countriesSupported: [
          'ind',
        ],
      },
    },
  ],
};

export default standartWorkflow;
