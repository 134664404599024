import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@mui/material';
import deleteIcon from '../../../assests/icons/deleteRuleIcon.svg';
import generateUniqueID from '../../../utils/generateUniqueId';
import useDebounce from '../../../utils/useDebounce';

function JsonBuilder({
  label,
  onChange,
  defaultValue,
}) {
  const getInitialValue = (initialValue) => Object.keys(initialValue).map((key) => ({
    id: generateUniqueID(),
    key,
    value: `${initialValue[key]}`,
    type: typeof initialValue[key],
  }));
  const [keyValuePairs, setKeyValuePairs] = useState(getInitialValue(defaultValue || {}));
  const debouncedValue = useDebounce(keyValuePairs, 500);
  const prevValue = useRef(keyValuePairs);
  const canConvertToDataType = (value, dataType) => {
    if (dataType === 'boolean') {
      const lowerCasedValue = value?.toLowerCase();
      return ['true', 'false'].includes(lowerCasedValue);
    }
    if (dataType === 'number') return !Number.isNaN(Number(value));
    return true;
  };

  const handleInputChange = (id, field, value) => {
    const newKeyValuePairs =
      keyValuePairs.map((pair) => (pair.id === id ? {
        ...pair,
        [field]: value,
        ...(field === 'value' && !canConvertToDataType(value, pair.type) ?
          { type: 'string' } : {}),
      } : pair));
    setKeyValuePairs(newKeyValuePairs);
  };

  const handleAddKeyValuePair = () => {
    setKeyValuePairs([...keyValuePairs, {
      id: generateUniqueID(), key: '', value: '', type: 'string',
    }]);
  };

  const handleDeleteKeyValuePair = (id, localArray) => {
    const trimmedArr = localArray.filter((element) => element.id !== id);
    setKeyValuePairs(trimmedArr);
  };

  const canConvertToDataTypeInArr = (id, localArray, dataType) => {
    const value = localArray.find((element) => element.id === id)?.value;
    return canConvertToDataType(value, dataType);
  };

  const convertToDataType = (value, dataType) => {
    if (!canConvertToDataType(value, dataType)) return value;
    if (dataType === 'boolean') {
      const lowerCasedValue = value?.toLowerCase();
      return lowerCasedValue === 'true';
    }
    if (dataType === 'number') return Number(value);
    return value;
  };

  useEffect(() => {
    if (JSON.stringify(prevValue.current) !== JSON.stringify(debouncedValue)) {
      const data = {};
      debouncedValue.forEach(({ key, value, type }) => {
        data[key] = convertToDataType(value, type);
      });
      onChange(data);
    }
    prevValue.current = debouncedValue;
  }, [debouncedValue]);

  return (
    <div className="edit-properties-div__border">
      <div className="edit-properties-div__title">{label}</div>
      {keyValuePairs.map((pair) => (
        <div key={pair.id} className="edit-properties-div__container">
          <input
            type="text"
            className="edit-properties-div__text-input"
            value={pair.key}
            onChange={(e) => handleInputChange(pair.id, 'key', e.target.value)}
            placeholder="key"
          />
          <input
            type="text"
            className="edit-properties-div__text-input"
            value={pair.value}
            onChange={(e) => handleInputChange(pair.id, 'value', e.target.value)}
            placeholder="value"
          />
          <select
            className="edit-properties-div__dropdown"
            value={pair.type}
            onChange={(e) => handleInputChange(pair.id, 'type', e.target.value)}
          >
            <option value="string" disabled={!canConvertToDataTypeInArr(pair.id, keyValuePairs, 'string')}>
              String
            </option>
            <option value="number" disabled={!canConvertToDataTypeInArr(pair.id, keyValuePairs, 'number')}>
              Number
            </option>
            <option value="boolean" disabled={!canConvertToDataTypeInArr(pair.id, keyValuePairs, 'boolean')}>
              Boolean
            </option>
          </select>
          <button
            type="button"
            className="condition__content__var__delete"
            onClick={() => { handleDeleteKeyValuePair(pair.id, keyValuePairs); }}
          >
            <img className="condition__content__var__delete-copy-icon" src={deleteIcon} alt="delete" />
          </button>
        </div>
      ))}
      <Button variant="contained" color="primary" onClick={handleAddKeyValuePair}>
        Add Key-Value Pair
      </Button>
    </div>
  );
}

JsonBuilder.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
};

export default JsonBuilder;
