import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const CustomizableToolTip = styled(({ className, customStyles, ...props }) => (
  <Tooltip
      // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    classes={{ popper: className }}
    PopperProps={{
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -5],
          },
        },
      ],
    }}
  />
))(({ customStyles }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    ...customStyles.tooltip,
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    ...customStyles.arrow,
  },
}));

export default CustomizableToolTip;
