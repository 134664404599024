/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import { useEffect, useRef, useState } from 'react';
import { cloneDeep, get, unset } from 'lodash';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  selectedComponentPath,
} from '../../reducers/dynamicForm';
import {
  addNodeFromHtmlString,
  copyNodeFromHtmlString,
  createNewDomId,
  decodeFromBase64,
  deleteNodeFromHtmlString,
  dragNodeFromHtmlString,
  encodeToBase64,
  getAllowedOperations,
  getFilteredComponentConfig,
  getFormHtmlStringForV2,
  getHTMLStringById,
  updateTagNameOfNodeFromHtmlString,
} from './helper';
import FormModuleEditProperties from '../../components/FormModule/FormModuleEditProperties';
import { selectOrderOfNodes } from '../../reducers/workflow';
import {
  selectDefaultUIConfig,
  selectSupportedFonts,
} from '../../reducers/editBranding';
import ListFormModule from '../../components/FormModule/ListFormModule';
import './FormModuleDrawer.scss';
import { formComponentList as formComponentsConfig } from '../../constants/dynamicFormComponents';
import { selectModuleProperties } from '../../reducers/moduleBuilder';
import WebFormComponentEditProperties from '../../components/FormModule/WebFormComponentEditProperties';
import CopyIcon from '../../assests/icons/contentCopyIcon.svg';
import addImg from '../../assests/icons/addIcon.svg';
import reviewImg from '../../assests/icons/review.svg';
import { setModuleProperty } from '../../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';
import InputComponent from '../../components/ViewWorkflow/v2/InputComponent/InputComponent';
import ChatInput from '../../components/ViewWorkflow/v2/ChatInput/ChatInput';
import useApiHooks from '../../utils/useApiHooks';
import generateUniqueID from '../../utils/generateUniqueId';
import ListFormModuleComponentsV2 from '../../components/FormModule/ListFormModuleComponentsV2';
import { attachBorder } from './sdkHelper';
import { replaceAll } from '../../utils/helper';

function FormModuleDrawerV2({
  formModule,
  onUpdateProperties,
}) {
  const { getHtmlAndCssFromPrompt } = useApiHooks();
  const [randomId, setRandomId] = useState('');
  const draggedIndexRef = useRef(null);
  const getNestedComponentList = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    function nodeToArray(node) {
      // Initialize the array with the tag name
      if (!node?.id) node.id = createNewDomId();
      const data = {
        tagName: node.tagName.toLowerCase(),
        id: node?.id,
        text: `${node.tagName.toLowerCase()} : ${node?.id}`,
      };
      // Recursively add children
      const subComponents = [];
      for (const child of node.children) {
        subComponents.push(nodeToArray(child));
      }
      if (subComponents?.length) data.subComponents = subComponents;
      return data;
    }

    const rootElement = doc.body;
    const result = [];
    for (const child of rootElement.children) {
      result.push(nodeToArray(child));
    }
    return result;
  };

  const getTagName = (domId, htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const element = doc.querySelector(`#${domId}`);
    return element?.tagName?.toLowerCase();
  };

  const [selectedComponentId, setSelectedComponentId] = useState('');
  const [draggingOverId, setDraggingId] = useState('');

  const onItemSelect = (componentId) => {
    setSelectedComponentId(componentId);
    setDraggingId('');
  };

  const getSelectedComponent = (moduleProperties, workflowKey) => get(moduleProperties, workflowKey, {});

  const handleComponentUpdate = (workflowKey, newValue) => {
    onUpdateProperties(workflowKey, newValue);
  };

  const handleTagNameUpdate = (tagName, componentId, htmlString) => {
    if (tagName && componentId && htmlString) {
      const updatedHtml = updateTagNameOfNodeFromHtmlString(tagName, componentId, htmlString);
      onUpdateProperties('content', updatedHtml);
    }
  };

  const handleOnPrompt = async (prompt) => {
    try {
      const response = await getHtmlAndCssFromPrompt(prompt);
      const responseObj = JSON.parse(response);
      const { html, css } = responseObj;
      handleComponentUpdate('content', html);
      handleComponentUpdate('styles', css);
      setRandomId(generateUniqueID());
    } catch (err) {
      console.log('>>> ERR :', err);
    }
  };

  const configurations = [
    {
      workflowKey: 'scripts',
      text: 'Script URLs',
      placeholder: 'Enter in stringified JSON',
      formatAsJson: true,
      type: 'jsonBox',
    },
    {
      workflowKey: 'stylesheets',
      text: 'Stylesheets URLs',
      placeholder: 'Enter in stringified JSON',
      formatAsJson: true,
      type: 'jsonBox',
    },
    {
      workflowKey: 'content',
      text: 'HTML',
      placeholder: 'Enter the HTML',
      formatAsJson: false,
      type: 'jsonBox',
    },
  ];

  useEffect(() => {
    const wrapperClassName = 'hv-builder-click-border-wrapper';
    const borderStyle = '1px dotted rgb(24, 129, 24)';
    attachBorder(selectedComponentId, wrapperClassName, borderStyle);
  }, [selectedComponentId]);

  useEffect(() => {
    const wrapperClassName = 'hv-builder-dragging-border-wrapper';
    const borderStyle = '1px dotted #0000FF';
    attachBorder(draggingOverId, wrapperClassName, borderStyle);
  }, [draggingOverId]);

  const handleOnClick = (componentId) => {
    onItemSelect(componentId);
  };

  const handleOnDelete = (componentId, htmlContent, componentConfigs) => {
    onItemSelect('');
    const { html: updatedHtml, deletedNodeIds } = deleteNodeFromHtmlString(componentId, htmlContent);
    const clonedComponentConfigs = cloneDeep(componentConfigs);
    deletedNodeIds.forEach((id) => {
      unset(clonedComponentConfigs, id);
    });
    const { success } = onUpdateProperties('componentConfigs', clonedComponentConfigs);
    if (success) {
      onUpdateProperties('content', updatedHtml);
    }
  };

  const handleOnCopyToClipboard = (componentId, htmlContent, componentConfigs) => {
    const componentHTML = getHTMLStringById(htmlContent, componentId);
    const dataToPasteToClipboard = JSON.stringify({
      html: encodeToBase64(componentHTML),
      componentConfigs,
    });
    const copyContent = async (text) => {
      try {
        await navigator.clipboard.writeText(text);
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    };
    copyContent(dataToPasteToClipboard);
  };

  const getCopiedConfigs = (componentConfigs, map) => {
    const copiedConfigs = {};
    Object.keys(map).forEach((oldId) => {
      const newId = map[oldId];
      let configStr = JSON.stringify(componentConfigs[oldId] || {});
      configStr = replaceAll(configStr, oldId, newId);
      const copiedConfig = JSON.parse(configStr);
      copiedConfig.events = {};
      copiedConfigs[newId] = copiedConfig;
    });
    return copiedConfigs;
  };

  const copyComponentConfigs = (componentConfigs, map) => {
    const copiedConfigs = getCopiedConfigs(componentConfigs, map);
    const clonedComponentConfig = cloneDeep(componentConfigs);
    const updatedConfigs = {
      ...(clonedComponentConfig || {}),
      ...(copiedConfigs || {}),
    };
    // replace all the old
    handleComponentUpdate('componentConfigs', updatedConfigs);
  };

  const handleOnCopy = (componentId, htmlContent, componentConfigs) => {
    const { html: updatedHtml, map } = copyNodeFromHtmlString(componentId, htmlContent);
    onUpdateProperties('content', updatedHtml);
    copyComponentConfigs(componentConfigs, map);
  };

  const handleOnAdd = (componentId, htmlContent, htmlFragmentString = null) => {
    const { html: updatedHtml } = addNodeFromHtmlString(componentId, htmlContent, htmlFragmentString);
    onUpdateProperties('content', updatedHtml);
  };

  const handleOnPaste = async (htmlContent, componentConfigs) => {
    try {
      const text = await navigator.clipboard.readText();
      const parsedObj = JSON.parse(text);
      const { html: htmlFragmentStringEncoded, componentConfigs: copiedConfigsRef } = parsedObj;
      const htmlFragmentString = decodeFromBase64(htmlFragmentStringEncoded);
      const { html: updatedHtml, map } = addNodeFromHtmlString(null, htmlContent, htmlFragmentString);

      onUpdateProperties('content', updatedHtml);
      const copiedConfigs = getCopiedConfigs(copiedConfigsRef, map);

      const updatedComponentConfigs = {
        ...(cloneDeep(componentConfigs || {})),
        ...(copiedConfigs || {}),
      };
      handleComponentUpdate('componentConfigs', updatedComponentConfigs);
    } catch (err) {
      console.log('>>> SOMETHING WENT WRONG WHILE PASTING', err);
    }
  };

  const handleOnDrag = (fromComponentId, toComponentId, htmlContent) => {
    if (fromComponentId !== toComponentId) {
      const updatedHtml = dragNodeFromHtmlString(fromComponentId, toComponentId, htmlContent);
      onUpdateProperties('content', updatedHtml);
      setDraggingId('');
    }
  };

  return (
    <div className="master">
      <div className="component-list-div">
        <div className="component-heading">
          <div className="component-list-div__heading">
            STRUCTURE
          </div>
        </div>
        <div className="component-list-div-children">
          <div
            role="menuitem"
            tabIndex={0}
            onKeyUp={() => {}} // currently not handling keyboard events
            className={`component-name${selectedComponentId === 'editStructureId' ? '__active' : ''}`}
            onClick={() => { onItemSelect('editStructureId'); }}
          >
            <div className="component-name-label">
              Edit structure
            </div>
          </div>
        </div>
        <div className="component-heading">
          <div className="component-list-div__heading">
            COMPONENT LIST V2
          </div>
          <button
            type="button"
            className="component-add-btn"
            onClick={(event) => {
              event.stopPropagation(); handleOnPaste(
                getFormHtmlStringForV2(formModule),
                getSelectedComponent(formModule?.properties, 'componentConfigs'),
              );
            }}
          >
            <img src={reviewImg} alt="review" />
          </button>
          <button type="button" className="component-add-btn" onClick={(event) => { event.stopPropagation(); handleOnAdd(null, getFormHtmlStringForV2(formModule)); }}>
            <img src={addImg} alt="add" />
          </button>
        </div>
        <ListFormModuleComponentsV2
          formComponents={getNestedComponentList(getFormHtmlStringForV2(formModule))}
          handleOnClick={(componentId) => handleOnClick(componentId)}
          handleOnDelete={(componentId) => handleOnDelete(
            componentId,
            getFormHtmlStringForV2(formModule),
            getSelectedComponent(formModule?.properties, 'componentConfigs'),
          )}
          handleOnCopy={(componentId) => handleOnCopy(
            componentId,
            getFormHtmlStringForV2(formModule),
            getSelectedComponent(formModule?.properties, 'componentConfigs'),
          )}
          handleOnAdd={(componentId) => handleOnAdd(componentId, getFormHtmlStringForV2(formModule))}
          handleOnDrag={(fromComponentId, toComponentId) => {
            handleOnDrag(fromComponentId, toComponentId, getFormHtmlStringForV2(formModule));
          }}
          selectedComponentPath={selectedComponentId}
          enableCopyButton
          enableDeleteButton
          enableAddButton
          draggedIndexRef={draggedIndexRef}
          handleOnDragOver={(id) => { setDraggingId(id); }}
          draggingComponentId={draggingOverId}
          handleCopyToClipBoard={(componentId) => handleOnCopyToClipboard(
            componentId,
            getFormHtmlStringForV2(formModule),
            getSelectedComponent(formModule?.properties, 'componentConfigs'),
          )}
        />
      </div>
      {
        selectedComponentId === 'editStructureId'
          ? (
            <div className="edit-properties-div">
              <div className="edit-properties-div__property">
                {
                  configurations.map((config) => (
                    <InputComponent
                      key={`property-${config?.workflowKey}-${randomId}`}
                      element={config}
                      isDisabled={false}
                    />
                  ))
                }
              </div>
              <ChatInput
                label="Enter your prompt here"
                onSave={handleOnPrompt}
                defaultInput="something"
                placeholder="Type prompt here"
              />
            </div>
          ) : null
      }
      {
        selectedComponentId && selectedComponentId !== 'editStructureId'
          ? (
            <WebFormComponentEditProperties
              selectedComponentTagName={getTagName(selectedComponentId, getFormHtmlStringForV2(formModule))}
              selectedModuleId={formModule?.id}
              selectedComponentId={selectedComponentId}
              onUpdateComponent={(property, value) => { handleComponentUpdate(`componentConfigs['${selectedComponentId}'].${property}`, value); }}
              selectedComponent={getSelectedComponent(formModule?.properties, `componentConfigs['${selectedComponentId}']`)}
              onTagNameUpdate={(tagName) => {
                handleTagNameUpdate(
                  tagName,
                  selectedComponentId,
                  getFormHtmlStringForV2(formModule),
                );
              }}
            />
          ) : null
      }
    </div>
  );
}

FormModuleDrawerV2.propTypes = {
  formModule: PropTypes.object.isRequired,
  onUpdateProperties: PropTypes.func.isRequired,
};

export default FormModuleDrawerV2;
