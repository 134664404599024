import {
  useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import closeButton from '../../assests/icons/closeButton.svg';
import WorkflowOutput from './WorkflowOutput';
import WorkflowInput from './WorkflowInput';
import { selectSelectedWorkflow, selectVersionedModules } from '../../reducers/workflow';
import DisplayConditionalVariables from './v2/ConditionalVariablesV2/DisplayConditionalVariables';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import withDeletionDependencyCheck from '../../utils/withDeletionDependencyCheck';
import { formComponentList as formComponents } from '../../constants/dynamicFormComponents';
import { fetchDependencyOfField } from '../../utils/fetchDependencyList';
import pushBulkLogs from '../../logger/pushBulkLogs';
import { createNestedMenuData } from './v2/NestedDropdown/utils';
import {
  getConditionalVariables, getAllModuleOutputs, getWorkflowInputVariables, getPredefinedValues,
} from './v2/InputsToModule/utils';
import generateUniqueID from '../../utils/generateUniqueId';
import { workflowOperationsObj as operations } from '../../workflowOperations';
import {
  selectModuleInputs, selectModuleBuilderMode, selectModuleOutputs,
  selectModuleProperties, updateModuleBuilderInputs,
  updateModuleBuilderOutputs,
  updateModuleBuilderProperties,
} from '../../reducers/moduleBuilder';
import ModuleInput from '../ModuleBuilder/ModuleInput';
import ModuleOutput from '../ModuleBuilder/ModuleOutput';
import MODULE_COUNTRY_PICKER from '../../constants/miscellaneous';

function WorkflowConfigurationModal(props) {
  const { onClose, page, checkDependencies: checkDeletionDependencies } = props;

  const [currentPage, setCurrentPage] = useState(page);
  const [sdkInputs, setSdkInputs] = useState([]);
  const [sdkResponse, setSdkResponse] = useState([]);
  const [conditionalVariables, setConditionalVariables] = useState({});
  const [conditionalVariableLogs, setConditionalVariableLogs] = useState([]);
  const [moduleInputs, setModuleInputs] = useState([]);
  const [moduleOutputs, setModuleOutputs] = useState([]);
  const [moduleProperties, setModuleProperties] = useState([]);

  const workflow = useSelector(selectSelectedWorkflow);
  const versionedModules = useSelector(selectVersionedModules);
  const moduleBuilderMode = useSelector(selectModuleBuilderMode);
  const existingModuleInputs = useSelector(selectModuleInputs);
  const existingModuleOutputs = useSelector(selectModuleOutputs);
  const existingModuleProperties = useSelector(selectModuleProperties);

  const dispatch = useDispatch();

  useEffect(() => {
    const inputs = workflow.properties.inputsRequired || {};
    if (inputs) {
      setSdkInputs(
        Object.entries(inputs).map(
          ([key, value]) => ({ id: generateUniqueID(), variable: key, type: value }
          ),
        ),
      );
    }
    const outputs = workflow.sdkResponse || {};
    if (outputs) {
      setSdkResponse(
        Object.entries(outputs).map(
          ([key, value]) => ({ id: generateUniqueID(), variable: key, value }
          ),
        ),
      );
    }

    const condVars = workflow.conditionalVariables || {};
    setConditionalVariables(condVars);

    const currentModuleInputs = existingModuleInputs || [];
    if (currentModuleInputs.length) {
      setModuleInputs(currentModuleInputs.map((itr) => ({ ...itr, id: generateUniqueID() })));
    }

    const currentModuleOutputs = existingModuleOutputs || [];
    if (currentModuleOutputs.length) {
      setModuleOutputs(currentModuleOutputs.map((itr) => ({ ...itr, id: generateUniqueID() })));
    }

    const currentModuleProperties = existingModuleProperties || [];
    if (currentModuleProperties.length) {
      setModuleProperties(
        currentModuleProperties.map((itr) => ({ ...itr, id: generateUniqueID() })),
      );
    }
  }, [workflow]);

  const setVariableName = (id, variable) => {
    setSdkInputs((prevInputs) => prevInputs.map((input) => {
      if (input.id === id) return { ...input, variable };
      return input;
    }));
  };
  const setOutputVariableName = (id, variable) => {
    setSdkResponse((prevOutputs) => prevOutputs.map((output) => {
      if (output.id === id) return { ...output, variable };
      return output;
    }));
  };
  const setOutputValue = (id, value) => {
    setSdkResponse((prevOutputs) => prevOutputs.map((output) => {
      if (output.id === id) return { ...output, value };
      return output;
    }));
  };

  const setType = (id, type) => {
    setSdkInputs((prevInputs) => prevInputs.map((input) => {
      if (input.id === id) return { ...input, type };
      return input;
    }));
  };
  const addNewVariable = () => {
    const id = generateUniqueID();
    const newField = {
      id,
      variable: '',
      type: 'string',
    };
    setSdkInputs((prevInputs) => [...prevInputs, newField]);
  };

  const addNewOutputVariable = () => {
    const id = generateUniqueID();
    const newField = {
      id,
      variable: '',
      value: '',
    };
    setSdkResponse((prevOutputs) => [...prevOutputs, newField]);
  };

  const deleteVariable = (id, selectedWorkflow) => {
    // Check dependencies
    const currInput = sdkInputs.find((input) => input.id === id);
    const isDependent = checkDeletionDependencies({
      variableId: currInput.variable,
      nodeId: 'inputs',
      workflow: selectedWorkflow,
    });
    if (isDependent) return;
    setSdkInputs((prevInputs) => prevInputs.filter((input) => input.id !== id));
  };

  const deleteOutputVariable = (id) => {
    setSdkResponse((prevOutputs) => prevOutputs.filter((output) => output.id !== id));
  };

  const validateInputConfig = () => {
    let isValid = true;
    sdkInputs.forEach((input) => {
      if (!input.variable || input.variable.includes(' ')) {
        isValid = false;
      }
    });
    // eslint-disable-next-line no-alert
    if (!isValid) alert('Variable names cannot be empty or contain spaces!');
    return isValid;
  };

  const validateOutputConfig = () => {
    let isValidVariable = true;
    let isValidValue = true;
    sdkResponse.forEach((output) => {
      if (!output.variable || output.variable.includes(' ')) {
        isValidVariable = false;
      }
      if (!output.value) isValidValue = false;
    });
    // eslint-disable-next-line no-alert
    if (!isValidVariable) alert('Variable names cannot be empty or contain spaces!');
    // eslint-disable-next-line no-alert
    if (!isValidValue) alert('Values cannot be empty!');
    return isValidValue && isValidVariable;
  };

  const saveWorkflowInputs = () => {
    if (validateInputConfig()) {
      // update the workflow
      const newInputs = {};
      sdkInputs.forEach((input) => {
        newInputs[input.variable] = input.type;
      });
      updateWorkflowInState({}, true, {
        operation: operations.SET_WORKFLOW_ATTRIBUTE,
        actionData: {
          path: 'properties.inputsRequired',
          value: newInputs,
        },
      });
      onClose();
    }
  };

  const saveWorkflowOutputs = () => {
    if (validateOutputConfig()) {
      // update the workflow
      const newOutputs = {};
      sdkResponse.forEach((output) => {
        newOutputs[output.variable] = output.value;
      });
      updateWorkflowInState({}, true, {
        operation: operations.SET_WORKFLOW_ATTRIBUTE,
        actionData: {
          path: 'sdkResponse',
          value: newOutputs,
        },
      });
      onClose();
    }
  };

  const canDeleteConditionalVariable = (conVar, condVars, currWorkflow) => {
    const workflowToPass = cloneDeep(currWorkflow);
    workflowToPass.conditionalVariables = condVars;
    return checkDeletionDependencies(
      {
        variableId: conVar,
        nodeId: 'conditionalVariables',
        workflow: workflowToPass,
      },
    );
  };

  const getConditionalVariableDeps = (conVar, condVars, currWorkflow) => {
    const workflowToPass = cloneDeep(currWorkflow);
    workflowToPass.conditionalVariables = condVars;
    return fetchDependencyOfField(conVar, 'conditionalVariables', workflowToPass);
  };
  const setAddVariables = (selectedVal) => {
    setSdkResponse((prevOutputs) => [...prevOutputs, selectedVal]);
  };

  const saveConditionalVariables = (condVars) => {
    pushBulkLogs(conditionalVariableLogs);
    updateWorkflowInState({}, true, {
      operation: operations.SET_WORKFLOW_ATTRIBUTE,
      actionData: {
        path: 'conditionalVariables',
        value: condVars,
      },
    });
  };

  const onSave = () => {
    if (currentPage === 'input') saveWorkflowInputs();
    else if (currentPage === 'output') saveWorkflowOutputs();
    else {
      // TODO: Save is common for all the tabs,
      // Same should be the behavior for the input and output
      dispatch(updateModuleBuilderInputs({ inputs: moduleInputs }));
      dispatch(updateModuleBuilderOutputs({ outputs: moduleOutputs }));
      dispatch(updateModuleBuilderProperties({ properties: moduleProperties }));
    }
    onClose();
  };

  const preDefinedValues = getPredefinedValues(workflow, formComponents);

  const onSaveModuleBuilder = (func) => (id, properties) => {
    func((prevInputs) => prevInputs.map((input) => {
      if (input.id === id) return { ...properties, id };
      return input;
    }));
  };

  const onDeleteRowModuleBuilder = (func) => (id) => {
    func((prevInputs) => prevInputs.filter((input) => input.id !== id));
  };

  const onAddRowModuleBuilder = (func) => (properties = {}) => {
    const id = generateUniqueID();
    const newElement = {
      id,
      ...properties,
    };
    func((prevInputs) => [...prevInputs, newElement]);
  };

  const onSaveModuleInput = onSaveModuleBuilder(setModuleInputs);
  const onDeleteModuleInput = onDeleteRowModuleBuilder(setModuleInputs);
  const onSaveModuleOutput = onSaveModuleBuilder(setModuleOutputs);
  const onDeleteModuleOutput = onDeleteRowModuleBuilder(setModuleOutputs);
  const onSaveModuleBuilderProperties = onSaveModuleBuilder(setModuleProperties);

  const onAddModuleInput = onAddRowModuleBuilder(setModuleInputs);
  const onAddModuleOutput = onAddRowModuleBuilder(setModuleOutputs);
  const onAddModuleBuilderProperties = onAddRowModuleBuilder(setModuleProperties);
  const onDeleteBuilderProperty = onDeleteRowModuleBuilder(setModuleProperties);

  const menuOptions = useMemo(() => {
    const newWorkflow = cloneDeep(workflow);
    const newInputs = {};
    sdkInputs.forEach((input) => {
      if (input.variable && !input.variable.includes(' ')) newInputs[input.variable] = input.type;
    });
    newWorkflow.properties.inputsRequired = newInputs;
    newWorkflow.conditionalVariables = conditionalVariables;
    const moduleOutputOptions = getAllModuleOutputs(
      newWorkflow,
      formComponents,
      versionedModules,
    );
    const conditionalVariableOptions = getConditionalVariables(newWorkflow);
    const workflowInputOptions = getWorkflowInputVariables(newWorkflow);
    return createNestedMenuData(
      workflowInputOptions,
      conditionalVariableOptions,
      moduleOutputOptions,
      moduleBuilderMode ? existingModuleProperties : null,
    );
  }, [
    conditionalVariables,
    formComponents,
    versionedModules,
    sdkInputs,
    workflow,
  ]);

  const menuOptionsForModuleBuilder = useMemo(() => {
    const clonedWorkflow = cloneDeep(workflow);
    const items = clonedWorkflow.modules
      .map((module) => ({ label: module.name, value: module.id }))
      .filter((module) => module.value !== MODULE_COUNTRY_PICKER);
    return {
      items,
    };
  }, [workflow]);
  return (
    <div className="workflow_configuration_modal__overlay">
      <div className="workflow_configuration_modal__body">
        <div className="workflow_configuration_modal__header">
          <span>
            Input/Output Definition
          </span>
          <button
            type="button"
            className="workflow_configuration_modal__header_button"
            onClick={onSave}
          >
            Save
          </button>
          <button type="button" className="closeModalButton" onClick={onClose}>
            <img
              src={closeButton}
              alt="close"
            />
          </button>
        </div>

        <div className="workflow_configuration_modal__content">
          <div className="workflow_configuration_modal__sidebar">
            <button
              type="button"
              className={currentPage === 'input' ? 'workflow_configuration_modal__sidebar_button_active' : 'workflow_configuration_modal__sidebar_button'}
              onClick={() => setCurrentPage('input')}
            >
              Workflow Inputs
            </button>
            <button
              type="button"
              className={currentPage === 'output' ? 'workflow_configuration_modal__sidebar_button_active' : 'workflow_configuration_modal__sidebar_button'}
              onClick={() => setCurrentPage('output')}
            >
              Workflow Outputs
            </button>
            <button
              type="button"
              className={currentPage === 'conditionalVariables' ? 'workflow_configuration_modal__sidebar_button_active' : 'workflow_configuration_modal__sidebar_button'}
              onClick={() => setCurrentPage('conditionalVariables')}
            >
              Conditional Variables
              {moduleBuilderMode}
            </button>
            {moduleBuilderMode ? (
              <>
                <button
                  type="button"
                  className={currentPage === 'moduleInputs' ? 'workflow_configuration_modal__sidebar_button_active' : 'workflow_configuration_modal__sidebar_button'}
                  onClick={() => setCurrentPage('moduleInputs')}
                >
                  Module  Inputs
                </button>
                <button
                  type="button"
                  className={currentPage === 'moduleOutputs' ? 'workflow_configuration_modal__sidebar_button_active' : 'workflow_configuration_modal__sidebar_button'}
                  onClick={() => setCurrentPage('moduleOutputs')}
                >
                  Module  Outputs
                </button>
                <button
                  type="button"
                  className={currentPage === 'builderProperties' ? 'workflow_configuration_modal__sidebar_button_active' : 'workflow_configuration_modal__sidebar_button'}
                  onClick={() => setCurrentPage('builderProperties')}
                >
                  Builder Properties
                </button>
              </>
            ) : ''}
          </div>
          <div className="workflow_configuration_modal__maincontent">
            {
              currentPage === 'output'
                ? (
                  <WorkflowOutput
                    sdkResponse={sdkResponse}
                    setVariableName={setOutputVariableName}
                    addNewVariable={addNewOutputVariable}
                    deleteVariable={deleteOutputVariable}
                    setOutputValue={setOutputValue}
                    onAddVariables={setAddVariables}
                    menuOptions={menuOptions}
                    setSdkResponse={setSdkResponse}
                    onClose={onClose}
                  />
                ) : ''
            }
            {
              currentPage === 'input'
                ? (
                  <WorkflowInput
                    sdkInputs={sdkInputs}
                    setVariableName={setVariableName}
                    setType={setType}
                    addNewVariable={addNewVariable}
                    deleteVariable={(...args) => deleteVariable(...args, workflow)}
                  />
                ) : ''
            }
            {
              (currentPage === 'conditionalVariables') ? (
                <DisplayConditionalVariables
                  conditionalVariables={conditionalVariables}
                  saveConditionalVariables={
                    (condVars) => {
                      saveConditionalVariables(condVars);
                    }
                  }
                  canDeleteConditionalVariable={
                    (conVar) => canDeleteConditionalVariable(
                      conVar,
                      conditionalVariables,
                      workflow,
                    )
                  }
                  dropDownOptions={menuOptions.items}
                  getConditionalVariableDeps={
                    (conVar) => getConditionalVariableDeps(
                      conVar,
                      conditionalVariables,
                      workflow,
                    )
                  }
                  setConditionalVariableLogs={
                    (log) => setConditionalVariableLogs((currLogs) => [...currLogs, log])
                  }
                  preDefinedValues={preDefinedValues}
                />
              )
                : ''
            }
            {
              (currentPage === 'moduleInputs') ? (
                <ModuleInput
                  moduleInputs={moduleInputs}
                  onAddModuleInput={onAddModuleInput}
                  onSaveModuleInput={onSaveModuleInput}
                  onDeleteModuleInput={onDeleteModuleInput}
                  isBuilderPropertiesSection={false}
                  moduleBuilderProperties={moduleProperties}
                  moduleIdsDropdown={menuOptionsForModuleBuilder}
                />
              ) : ''
            }
            {
              (currentPage === 'moduleOutputs') ? (
                <ModuleOutput
                  moduleOutputs={moduleOutputs}
                  onAddModuleOutput={onAddModuleOutput}
                  onSaveModuleOutput={onSaveModuleOutput}
                  onDeleteModuleOutput={onDeleteModuleOutput}
                  dropdownOptions={menuOptions}
                />
              ) : ''
            }
            {
              (currentPage === 'builderProperties') ? (
                <ModuleInput
                  moduleInputs={moduleProperties}
                  onAddModuleInput={onAddModuleBuilderProperties}
                  onSaveModuleInput={onSaveModuleBuilderProperties}
                  onDeleteModuleInput={onDeleteBuilderProperty}
                  isBuilderPropertiesSection
                  moduleBuilderProperties={moduleProperties}
                />
              ) : ''
            }
          </div>
        </div>
      </div>
    </div>
  );
}

WorkflowConfigurationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  checkDependencies: PropTypes.func.isRequired,
};

export default withDeletionDependencyCheck(WorkflowConfigurationModal);
