/* eslint-disable no-unused-vars */

import PropTypes from 'prop-types';
import { get } from 'lodash';
import DebouncedJsonInput from './Common/DebouncedJsonInput';
import JsonBuilder from './Common/SimpleObjectInput2';
import SimpleCssInput from './Common/SimpleCssInput';
import JsonEditor from './Common/JsonEditor';

function FormWebComponentEditor(props) {
  const {
    component,
    domId,
    handleComponentPropertyChange,
    propsToRender,
  } = props;

  const handleOnChange = (prop, value) => {
    handleComponentPropertyChange(prop, value);
  };

  const getCurrentValue = (comp, prop) => get(comp, prop);

  return (
    <div
      className="edit-properties-div__property"
    >
      {
      (propsToRender || []).map(({
        label, property, formatAsJson, type, rootName,
      }) => {
        switch (type) {
          case 'jsonBuilder':
            return (
              <JsonBuilder
                key={`${domId}.${property}`}
                label={label}
                onChange={(value) => {
                  handleOnChange(property, value);
                }}
                defaultValue={getCurrentValue(component, property)}
              />
            );
          case 'cssEditor':
            return (
              <SimpleCssInput
                key={`${domId}.${property}`}
                label={label}
                onChange={(value) => {
                  handleOnChange(property, value);
                }}
                defaultValue={getCurrentValue(component, property) || `#${domId} {}`}
                domId={domId}
              />
            );
          case 'jsonCodeBlock':
            return (
              <DebouncedJsonInput
                label={label}
                key={`${domId}.${property}`}
                formatAsJson={formatAsJson}
                onChange={(value) => {
                  handleOnChange(property, value);
                }}
                defaultValue={getCurrentValue(component, property)}
                readOnly={false}
                placeholder="add properties in JSON here"
              />
            );
          case 'jsonEditor':
            return (
              <JsonEditor
                label={label}
                key={`${domId}.${property}`}
                formatAsJson={formatAsJson}
                onChange={(value) => {
                  handleOnChange(property, value);
                }}
                defaultValue={getCurrentValue(component, property)}
                readOnly={false}
                placeholder="add properties in JSON here"
                rootName={rootName}
              />
            );
          default:
            return null;
        }
      })
    }
    </div>
  );
}

FormWebComponentEditor.propTypes = {
  component: PropTypes.object.isRequired,
  domId: PropTypes.string.isRequired,
  handleComponentPropertyChange: PropTypes.func.isRequired,
  propsToRender: PropTypes.array.isRequired,
};

export default FormWebComponentEditor;
