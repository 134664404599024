import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import useDebounce from '../../../utils/useDebounce';
import editIcon from '../../../assests/icons/conditionEditIcon.svg';
import ConditionsDrawer from '../../Conditions/ConditionsDrawer';

function ShowConditionDrawer({
  defaultValue, onChange, dropDownOptions, preDefinedValues,
}) {
  const prevValue = useRef(defaultValue || '');
  const [value, setValue] = useState(defaultValue || '');
  const debouncedValue = useDebounce(value, 500);
  const [showConditionDrawer, setShowConditionDrawer] = useState(false);

  useEffect(() => {
    if (prevValue.current !== debouncedValue) onChange(debouncedValue);
    prevValue.current = debouncedValue;
  }, [debouncedValue]);

  const handleClick = () => {
    setValue('');
  };

  const onConditionSave = (rule) => {
    setValue(rule);
    setShowConditionDrawer(false);
  };

  const addCondition = !!(value !== 'yes' && value !== 'no');

  return (
    <div>
      {!addCondition && (
        <button
          className="edit-settings-div__condition_button"
          type="button"
          onClick={handleClick}
        >
          + add condition
        </button>
      )}
      {addCondition && (
        <>
          {
            showConditionDrawer && (
              <ConditionsDrawer
                onClose={() => setShowConditionDrawer(false)}
                rule={value}
                dropDownOptions={dropDownOptions}
                onConditionSave={onConditionSave}
                allowDefaultRules={false}
                preDefinedValues={preDefinedValues}
              />
            )
          }
          <div className="edit-settings-div__condition_input__container">

            <input
              type="text"
              className="edit-settings-div__condition_input"
              value={value}
              placeholder="Add a rule here"
              readOnly
            />
            <button
              type="button"
              onClick={() => setShowConditionDrawer(true)}
              className="edit-settings-div__condition_input__edit"
            >
              <img src={editIcon} alt="edit" />
            </button>
          </div>

        </>
      )}
    </div>
  );
}

ShowConditionDrawer.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dropDownOptions: PropTypes.array.isRequired,
  preDefinedValues: PropTypes.array.isRequired,
};

export default ShowConditionDrawer;
