/* eslint-disable no-param-reassign */

import { useEffect } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, merge } from 'lodash';

import useApiHooks from '../utils/useApiHooks';
import AlertCode from '../constants/alertCodes';
import {
  resetIsEditedWorkflow,
  resetSelectedWorkflow,
  selectIsWorkflowEdited,
  selectSelectedWorkflow,
  selectVersionedModules,
  undoSelectedWorkflow,
  updateVersionedModules,
} from '../reducers/workflow';
import {
  selectModuleBuilderMode,
  updateModuleBuilderInputs,
  updateModuleBuilderOutputs,
  updateModuleBuilderProperties,
  updateModuleBuilderSubtype,
} from '../reducers/moduleBuilder';
import getWorkflowById from '../actions/getWorkflowById';
import decompile from '../compilerDecompiler/decompiler';
import { updateWorkflowInState } from '../workflowOperations/updateWorkflow';
import useShowErrorAlert from '../utils/customHooks';
import {
  resetBrandingScreens,
  selectSelectedLanguage,
  updateCustomTextConfig,
  updateCustomUiConfig,
  updateSelectedLanguage,
  updateFontStyleSheets,
} from '../reducers/editBranding';
import ErrorBoundaryWrapper from '../containers/ErrorBoundaryWrapper';
import FallbackComponent from '../components/Common/FallbackComponent';
import { closeExistingSDK } from '../components/utils';
import {
  replaceActualModuleIdsWithSuperModuleIds,
  extractCustomFontsFromUiConfig,
} from '../containers/uiConfigOperations';
import { convertOldNextNodeTypeKeysToNew } from '../containers/workflowOperations';

function WorkflowRouter() {
  const {
    fetchCustomTextConfig, fetchCustomUiConfig, getDefaultTextConfig,
    fetchModuleByVersion,
  } = useApiHooks();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const workflowId = searchParams.get('id');

  const workflowConfig = useSelector(selectSelectedWorkflow);
  const currentAppId = useSelector((state) => state.user.currentAppId);
  const versionedModules = useSelector(selectVersionedModules);
  const isWorkflowEdited = useSelector(selectIsWorkflowEdited);
  const selectedLanguage = useSelector(selectSelectedLanguage);
  const moduleBuilderMode = useSelector(selectModuleBuilderMode);

  const showErrorAlert = useShowErrorAlert();

  const fetchWorkflow = async (id) => {
    try {
      let workflow = workflowConfig;
      if (!workflowConfig || Object.keys(workflowConfig).length === 0) {
        const rawWorkflow = await getWorkflowById(id, currentAppId);
        const transformedWorkflow = convertOldNextNodeTypeKeysToNew(rawWorkflow);

        // Get the versions of module configs that will be needed for decompilation
        const requiredModuleVersions = {};
        rawWorkflow.modules.forEach((module) => {
          if (module.superModuleId) {
            requiredModuleVersions[module.superModuleId] = {
              moduleType: module.superModuleType,
              moduleVersion: rawWorkflow?.properties?.builder?.superModuleMetaData?.[module.superModuleId]?.version || 'v1',
            };
          } else {
            requiredModuleVersions[module.id] = {
              moduleType: module.subType,
              moduleVersion: module.version || 'v1',
            };
          }
        });

        // Fetch the required module config versions, if not available in versionedModules
        const fetchModuleConfigPromises =
          Object.values(requiredModuleVersions).map(({ moduleType, moduleVersion }) => {
            if (!versionedModules[moduleType][moduleVersion]) {
              return fetchModuleByVersion(moduleType, moduleVersion);
            }
            return null;
          }).filter((promise) => promise !== null);

        const requiredModuleConfigs = await Promise.all(fetchModuleConfigPromises);
        const newVersionedModules = {};
        requiredModuleConfigs.forEach(({ module }) => {
          const { version = 'v1', config, uiConfig } = module;
          newVersionedModules[config.subType] = {
            ...newVersionedModules[config.subType],
            [version]: { config, uiConfig },
          };
        });
        const updatedVersionedModules = cloneDeep(versionedModules);
        merge(updatedVersionedModules, newVersionedModules);
        dispatch(updateVersionedModules({ modules: updatedVersionedModules }));

        // lowLevelWorkflow, moduleConfigs, libraries
        // Decompile the workflow here
        workflow = decompile(transformedWorkflow, updatedVersionedModules);
        return { workflow, rawWorkflow: transformedWorkflow };
      }
      // updateWorkflowInState(workflow, false);
      // TODO: Understand, Why do we need it?
      dispatch(resetIsEditedWorkflow());
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.fetchWorkflow });
    }
    return null;
  };

  const changeCSS = () => {
    const style = document.createElement('style');
    style.innerHTML = '.react-flow__handle.connectable { visibility: hidden;}';
    document.getElementsByTagName('head')[0].appendChild(style);
  };

  // fetching the workflow by workflow id
  useEffect(() => {
    getDefaultTextConfig();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!workflowId) {
      navigate('/');
    }
    (async () => {
      const { workflow, rawWorkflow } = await fetchWorkflow(workflowId);
      updateWorkflowInState(workflow, false);
      changeCSS();
      dispatch(resetBrandingScreens());
      const uiConfig = await fetchCustomUiConfig(workflowId);
      const highLevelUiConfig =
        replaceActualModuleIdsWithSuperModuleIds(rawWorkflow?.modules || [], uiConfig);
      const textConfig = await fetchCustomTextConfig(workflowId, selectedLanguage);
      if (highLevelUiConfig) dispatch(updateCustomUiConfig({ uiConfig: highLevelUiConfig }));
      if (textConfig) dispatch(updateCustomTextConfig({ textConfig, language: selectedLanguage }));
      const customFonts = extractCustomFontsFromUiConfig(highLevelUiConfig);
      const customFontStyleSheets = customFonts.reduce((styleSheets, font) => ({
        ...styleSheets,
        [font]: (process.env.REACT_APP_CUSTOM_FONT_URL || '').replace('<SELECTED_FONT>', font),
      }), {});
      dispatch(updateFontStyleSheets({ fontStyleSheets: customFontStyleSheets }));
      if (moduleBuilderMode) {
        const moduleBuilderConfig = workflow?.properties?.moduleBuilder || {};
        const {
          input: moduleBuilderInput = [],
          output: moduleBuilderOutput = [],
          builderProperties: moduleBuilderProperties = [],
          subType: moduleBuilderSubType = '',
        } = moduleBuilderConfig;
        dispatch(updateModuleBuilderSubtype({ subType: moduleBuilderSubType }));
        dispatch(updateModuleBuilderInputs({ inputs: moduleBuilderInput }));
        dispatch(updateModuleBuilderOutputs({ outputs: moduleBuilderOutput }));
        dispatch(updateModuleBuilderProperties({ properties: moduleBuilderProperties }));
      }
    })();

    return () => {
      dispatch(resetSelectedWorkflow());
      dispatch(updateCustomUiConfig({ uiConfig: {} }));
      dispatch(updateCustomTextConfig({ textConfig: {} }));
      dispatch(updateSelectedLanguage({ language: 'en' }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowId]);

  useEffect(() => {
    const beforeUnloadListener = (event) => {
      event.preventDefault();
      // eslint-disable-next-line no-return-assign
      return (event.returnValue = '');
    };
    if (isWorkflowEdited) {
      window.addEventListener('beforeunload', beforeUnloadListener);
      window.addEventListener('pagehide', beforeUnloadListener);
      return () => {
        window.removeEventListener('beforeunload', beforeUnloadListener);
        window.removeEventListener('pagehide', beforeUnloadListener);
      };
    }
    return () => { };
  }, [isWorkflowEdited]);

  return (
    Object.keys(workflowConfig || {}).length === 0 ? (
      <div className="reload-loading">loading please wait</div>
    ) :
      <Outlet />
  );
}

function WorkflowRouterWrapper() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const workflowId = searchParams.get('id');

  return (
    <ErrorBoundaryWrapper
      FallbackComponent={FallbackComponent}
      resetFunction={() => {
        // reset the state of your app here
        closeExistingSDK();
        dispatch(undoSelectedWorkflow());
        navigate(`/view?id=${workflowId}`);
      }}
    >
      <WorkflowRouter />
    </ErrorBoundaryWrapper>
  );
}

export default WorkflowRouterWrapper;
