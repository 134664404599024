const calculateTotalNumberOfVariablesAdded = (
  sdkResponse,
  preSelectedCustomInput,
  selectedVariablesOutput,
  menuValues,
  customInputVariables,
) => {
  const sdkResponseValues = sdkResponse.map((variable) => variable.value);
  const customInputId = preSelectedCustomInput.map(((variable) => variable.id));
  const outputWithoutCustomInput = selectedVariablesOutput.filter(
    (item) => menuValues?.includes(item.value),
  );
  let addedVariablesCount = 0;

  outputWithoutCustomInput.forEach((sdkVariable) => {
    if (!sdkResponseValues.includes(sdkVariable.value)) {
      addedVariablesCount += 1;
    }
  });
  customInputVariables.forEach((sdkVariable) => {
    if (!customInputId.includes(sdkVariable.id)) {
      addedVariablesCount += 1;
    }
  });

  return addedVariablesCount;
};

export default calculateTotalNumberOfVariablesAdded;
