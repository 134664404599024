import PropTypes from 'prop-types';
import { useState } from 'react';
import { Grid } from '@mui/material';
import NestedDropdownWithInputs from '../ViewWorkflow/v2/NestedDropdown/NestedDropdown';
import ConfigureValidationDrawer from '../FormModule/Common/ConfigureValidationDrawer';

import './ModuleInputsEditPanel.scss';
import TextInput from '../ImportWorkflow/TextInput';
import { transformModuleBuilderPropertiesIntoDropdownItems } from '../ViewWorkflow/v2/NestedDropdown/utils';

const getHelpText = (inputType) => {
  switch (inputType) {
    case 'toggle':
      return `
      1. Possible Values are by default expected to be boolean. Sample Input [true, false]
      2. Provide in double quotes to set them as string. Example ["yes", "no"]
      3. First element of array is set as on, second as no.
      `;
    case 'singleSelectDropDown':
      return `
        1. Possible Values is expected to be passed as an array.
        2. An additional Property Possible Value Labels would be available which also expects an array containing labels of possible values stored as text in config.
        3. An additional Property title would also be available which is set as title in config.
        4. Input Name would be used as label.
        5. Provide in double quotes to set them as string. Example ["yes", "no"]
        `;
    case 'multiSelectDropDown':
      return `
            1. Possible Values is expected to be passed as an array of objects like 
            [
              {"value": 200, "label": "200"},
              {"value": 201, "label": "201"},...
            ]
            2. An additional Property title would also be available which is set as title in config.
            3. Input Name would be used as label in config.
            4. Provide in double quotes to set them as string. Example ["yes", "no"]
            `;
    case 'inputToModule':
    case 'textbox':
      return 'Go wild!';
    default:
      return `Please select a valid input type to get info. current selected ${inputType}`;
  }
};

function ModuleInputsEditPanel({
  id,
  name,
  inputType,
  description,
  setModuleInputProperty,
  category,
  inputModuleKey,
  possibleValues,
  regexForTextBox,
  errorMessageForTextBox,
  placeholderForTextBox,
  required,
  defaultValue,
  isBuilderPropertiesSection = false,
  visible,
  moduleBuilderProperties,
  moduleIdsDropdown,
  inputModuleId,
  possibleValuesLabels,
  inputTitle,
}) {
  const parsedDropDownOptions = [{
    label: 'Builder Properties',
    items: transformModuleBuilderPropertiesIntoDropdownItems(moduleBuilderProperties),
    disabled: moduleBuilderProperties?.length === 0,
  }];
  const [selectedInputType, setSelectedInputType] = useState(inputType);
  return (
    <div className="module-builder-inputs-container">
      <div className="conditional__content__rules_info">
        <div className="conditional__content__rules_info_heading"> Rules to follow</div>
        <pre>
          {getHelpText(selectedInputType)}
        </pre>
      </div>
      <div className="module-builder-inputs-container-dropdown">
        <div className="text-input-div">
          <Grid container alignItems="center" rowSpacing={1}>
            <Grid item xs={12}>Position</Grid>
            <Grid item xs={12}>
              <NestedDropdownWithInputs
                defaultValue={category}
                items={{
                  items: [{
                    label: 'Input',
                    value: 'Input',
                  },
                  {
                    label: 'Configuration',
                    value: 'Configuration',
                  }],
                }}
                noSelectLabel="Select Category type"
                onChange={(selectedItem) => setModuleInputProperty(id, 'category', selectedItem.value)}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="module-builder-inputs-container-dropdown">
        <div className="text-input-div">
          <Grid container alignItems="center" rowSpacing={1}>
            <Grid item xs={12}>Input type</Grid>
            <Grid item xs={12}>
              <NestedDropdownWithInputs
                defaultValue={inputType}
                items={{
                  items: [{
                    label: 'Toggle',
                    value: 'toggle',
                  },
                  {
                    label: 'Single Select',
                    value: 'singleSelectDropDown',
                  },
                  {
                    label: 'Multi Select',
                    value: 'multiSelectDropDown',
                  },
                  {
                    label: 'Input to Module',
                    value: 'inputToModule',
                  },
                  {
                    label: 'TextBox',
                    value: 'textbox',
                  },
                  ],
                }}
                noSelectLabel="Select Input type"
                onChange={(selectedItem) => {
                  setModuleInputProperty(id, 'inputType', selectedItem.value);
                  setSelectedInputType(selectedItem.value);
                }}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="module-builder-inputs-container-dropdown">
        <TextInput
          label="Input name"
          placeholder={name}
          isInvalid={!name?.length}
          setDefaultValue
          onChange={(value) => setModuleInputProperty(id, 'name', value)}
        />
      </div>
      <div className="module-builder-inputs-container-dropdown">
        <TextInput
          label="Input description"
          placeholder={description}
          isInvalid={!description?.length}
          setDefaultValue
          onChange={(value) => setModuleInputProperty(id, 'description', value)}
        />
      </div>
      {!isBuilderPropertiesSection ?
        (
          <div className="module-builder-inputs-container-dropdown">
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <div className="text-input-div">
                <Grid container alignItems="center" rowSpacing={1}>
                  <Grid item xs={12}>Module</Grid>
                  <Grid item xs={12}>
                    <NestedDropdownWithInputs
                      onChange={(selectedItem) => setModuleInputProperty(id, 'inputModuleId', selectedItem.value)}
                      noSelectLabel="Select Module Id"
                      items={moduleIdsDropdown}
                      defaultValue={inputModuleId}
                    />
                  </Grid>
                </Grid>
              </div>
              <TextInput
                label="Workflow Key"
                placeholder={inputModuleKey}
                isInvalid={!inputModuleKey?.length}
                setDefaultValue
                onChange={(value) => setModuleInputProperty(id, 'inputModuleKey', value)}
              />
            </div>
          </div>
        ) : ''}
      <div className="module-builder-inputs-container-dropdown">
        <TextInput
          label="Default For Input"
          placeholder={defaultValue}
          isInvalid={!defaultValue?.length}
          setDefaultValue
          onChange={(value) => setModuleInputProperty(id, 'defaultValue', value)}
        />
      </div>
      {['singleSelectDropDown', 'multiSelectDropDown', 'toggle'].includes(inputType) ? (
        <div className="module-builder-inputs-container-dropdown">
          <TextInput
            label="Possible Values. Enter Stringified JSON as input"
            placeholder={possibleValues}
            isInvalid={!possibleValues?.length}
            setDefaultValue
            onChange={(value) => setModuleInputProperty(id, 'possibleValues', value)}
          />
        </div>
      ) : ''}
      {['singleSelectDropDown'].includes(inputType) ? (
        <div className="module-builder-inputs-container-dropdown">
          <TextInput
            label="Possible Values for Labels. Enter Stringified JSON as input"
            placeholder={possibleValuesLabels}
            isInvalid={!possibleValuesLabels?.length}
            setDefaultValue
            onChange={(value) => setModuleInputProperty(id, 'possibleValuesLabels', value)}
          />
        </div>
      ) : ''}
      {['singleSelectDropDown', 'multiSelectDropDown'].includes(inputType) ? (
        <div className="module-builder-inputs-container-dropdown">

          <TextInput
            label="Input Title"
            placeholder={inputTitle}
            isInvalid={!inputTitle?.length}
            setDefaultValue
            onChange={(value) => setModuleInputProperty(id, 'inputTitle', value)}
          />
        </div>
      ) : ''}
      {inputType === 'textbox' ? (
        <>
          <div className="module-builder-inputs-container-dropdown">

            <TextInput
              label="Regex Validation"
              placeholder={regexForTextBox}
              isInvalid={!regexForTextBox?.length}
              setDefaultValue
              onChange={(value) => setModuleInputProperty(id, 'regexForTextBox', value)}
            />
          </div>
          <div className="module-builder-inputs-container-dropdown">

            <TextInput
              label="Error Message"
              placeholder={errorMessageForTextBox}
              isInvalid={!errorMessageForTextBox?.length}
              setDefaultValue
              onChange={(value) => setModuleInputProperty(id, 'errorMessageForTextBox', value)}
            />
          </div>
          <div className="module-builder-inputs-container-dropdown">

            <TextInput
              label="Placeholder For TextBox"
              placeholder={placeholderForTextBox}
              isInvalid={!placeholderForTextBox?.length}
              setDefaultValue
              onChange={(value) => setModuleInputProperty(id, 'placeholderForTextBox', value)}
            />
          </div>
        </>
      ) : ''}
      <div className="module-builder-inputs-container-dropdown">
        <ConfigureValidationDrawer
          defaultValue={{ type: 'rule', value: visible }}
          hideRegexTab
          onChange={(val) => setModuleInputProperty(id, 'visible', val.value)}
          dropDownOptions={parsedDropDownOptions}
          hideErrorMessageInput
          texts={{ title: 'Configure Visibility' }}
        />
      </div>
      <div className="module-builder-inputs-container-dropdown">
        <NestedDropdownWithInputs
          defaultValue={required}
          items={{
            items: [{
              label: 'yes',
              value: 'yes',
            },
            {
              label: 'no',
              value: 'no',
            },
            ],
          }}
          noSelectLabel="Input Config Required"
          onChange={(selectedItem) => setModuleInputProperty(id, 'required', selectedItem.value)}
        />
      </div>
    </div>
  );
}

ModuleInputsEditPanel.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setModuleInputProperty: PropTypes.func.isRequired,
  inputType: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.string,
  inputModuleKey: PropTypes.string,
  defaultValue: PropTypes.string,
  possibleValues: PropTypes.string,
  regexForTextBox: PropTypes.string,
  errorMessageForTextBox: PropTypes.string,
  placeholderForTextBox: PropTypes.string,
  required: PropTypes.string,
  isBuilderPropertiesSection: PropTypes.bool,
  visible: PropTypes.string,
  moduleBuilderProperties: PropTypes.array.isRequired,
  moduleIdsDropdown: PropTypes.object,
  inputModuleId: PropTypes.string,
  possibleValuesLabels: PropTypes.string,
  inputTitle: PropTypes.string,
};

ModuleInputsEditPanel.defaultProps = {
  inputType: null,
  description: '',
  category: null,
  inputModuleKey: '',
  possibleValues: '',
  regexForTextBox: '',
  errorMessageForTextBox: '',
  placeholderForTextBox: '',
  required: null,
  defaultValue: '',
  isBuilderPropertiesSection: false,
  visible: null,
  moduleIdsDropdown: { items: [] },
  inputModuleId: '',
  possibleValuesLabels: '',
  inputTitle: '',
};

export default ModuleInputsEditPanel;
