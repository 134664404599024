import { cloneDeep, set } from 'lodash';
import { eventHandlers } from '../../components/constants';
import { formComponentsBasePath } from '../../constants/dynamicFormComponents';
import { getAllFormComponentsObj } from '../../containers/FormModule/helper';
import replaceNextStepInConditions from './replaceNextStepInConditions';

export const checkAndUpdateNextStepsInFormComponents = (components, replacementMap) => {
  if (!components?.length || typeof replacementMap !== 'object') return [];
  const clonnedComponents = cloneDeep(components);
  clonnedComponents.forEach((component, index) => {
    eventHandlers.forEach((nextStepEvent) => {
      const nextStep = component?.[nextStepEvent]?.nextStep;
      if (nextStep && replacementMap[nextStep]) {
        clonnedComponents[index][nextStepEvent].nextStep = replacementMap[nextStep];
      }
    });
    if (component?.subComponents?.length) {
      const currentSubComponents = component?.subComponents || [];
      const updatedSubComponents =
          checkAndUpdateNextStepsInFormComponents(currentSubComponents, replacementMap);
      clonnedComponents[index].subComponents = updatedSubComponents;
    }
  });
  return clonnedComponents;
};

const checkAndUpdateNextStepsInFormModule = (module, replacementMap) => {
  const updatedModule = cloneDeep(module);
  const formComponentsObj = getAllFormComponentsObj(updatedModule);
  const basePathKeys = Object.keys(formComponentsObj);
  basePathKeys.forEach((basePath) => {
    const actualPathFromSection = formComponentsBasePath[basePath];
    const components = formComponentsObj[basePath];
    if (components.length) {
      const updatedComponents = checkAndUpdateNextStepsInFormComponents(components, replacementMap);
      // Assumption: Will always have only one section in form.
      set(updatedModule, `properties.sections[0].${actualPathFromSection}`, updatedComponents);
    }
  });
  return updatedModule;
};

const replaceNextStepId = (currentWorkflow, replacements) => {
  let workflow = cloneDeep(currentWorkflow);
  // Optimize to O(n)
  const replacementMap = {};
  replacements.forEach((replacement) => {
    const { key, value } = replacement;
    replacementMap[key] = value;
  });
  workflow.modules.forEach((module, index) => {
    if (module.type === 'dynamicForm') {
      workflow.modules[index] = checkAndUpdateNextStepsInFormModule(module, replacementMap);
    } else if (replacementMap[module.nextStep]) {
      workflow.modules[index].nextStep = replacementMap[module.nextStep];
    }
  });

  // Replace next step in conditions
  workflow = replaceNextStepInConditions(workflow, replacementMap);
  return workflow;
};

export default replaceNextStepId;
