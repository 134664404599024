const findPreviousModules = (orderOfModules, indexOfCurrentModule) => {
  const previousModules = orderOfModules.slice(0, indexOfCurrentModule)
    .map((obj) => (
      !(['condition', 'decline', 'approve', 'start', 'manualReview'].includes(obj.nodeType))
        ? obj.id : null))
    .filter((id) => id !== null);
  return previousModules;
};

export const isBoundaryNode = (nodeType) => {
  const boundaryNodes = ['decline', 'approve', 'start', 'manualReview', 'goto'];
  return boundaryNodes.includes(nodeType);
};

export const findPreviousModulesWithNames = (
  orderOfModules,
  indexOfCurrentModule,
) => {
  const orderOfModulesToBeDisplayed = isBoundaryNode(orderOfModules[indexOfCurrentModule]?.nodeType)
    ? orderOfModules
    : orderOfModules.slice(0, indexOfCurrentModule);

  const previousModules = orderOfModulesToBeDisplayed
    .map((obj) => (
      !(isBoundaryNode(obj.nodeType)) && !(obj.nodeType === 'condition')
        ? { id: obj.id, name: obj.name } : null))
    .filter((id) => id !== null);
  return previousModules;
};

export const isModuleVariable = (promisedId, modules) => (
  !!modules.find((module) => module.id === promisedId)
);

export const getStringInputDisplayValue = (
  value,
  orderOfModules,
  selectedNodeId,
  selectedWorkflow,
) => {
  const { modules, conditionalVariables } = selectedWorkflow;
  const arr = value.split('.');
  if (isModuleVariable(arr[0], modules)) {
    const indexOfCurrentModule = orderOfModules.findIndex(
      (module) => module.id === selectedNodeId,
    );
    const previousModules = findPreviousModulesWithNames(
      orderOfModules,
      indexOfCurrentModule,
      selectedWorkflow,
    );
    const moduleId = arr[0];
    const { name } = previousModules.find((module) => module.id === moduleId);
    if (name) return `${name}  >  ${arr[1]}`;
    return value;
  }
  if (arr[0].startsWith('conditionalVariable')) return `conditionalVariable  >  ${conditionalVariables[arr[1]]?.name || arr[1]}`;
  if (arr[0].startsWith('inputs')) return `inputs  >  ${arr[1]}`;
  return value;
};

export default findPreviousModules;
