import PropTypes from 'prop-types';
import Description from '../Description/Description';
import Duration from '../Duration/Duration';
import Inputs from '../InputsToModule/Inputs';
import JsonBox from '../JsonBox/JsonBox';
import MultiSelectDropdown from '../MultiSelectDropDown/MultiSelectDropDown';
import CountriesSelect from '../SelectCountries/Countries';
import DocumentSelect from '../SelectDocuments/DocumentsSelect';
import SingleSelectDropDown from '../SingleSelectDropdown/SingleSelectDropDown';
import TextInputWrapper from '../TextInput/TextInputWrapper';
import Toggle from '../Toggle/Toggle';

function InputComponent({ element, isDisabled }) {
  const { type: componentType } = element;
  switch (componentType) {
    case 'toggle':
      return <Toggle description={element} isDisabled={isDisabled} />;
    case 'duration':
      return <Duration element={element} isDisabled={isDisabled} />;
    case 'singleSelectDropDown':
      return <SingleSelectDropDown options={element} isDisabled={isDisabled} />;
    case 'multiSelectDropDown':
      return <MultiSelectDropdown options={element} isDisabled={isDisabled} />;
    case 'documentsSupported':
      return <DocumentSelect options={element} isDisabled={isDisabled} />;
    case 'countriesSupported':
      return <CountriesSelect options={element} isDisabled={isDisabled} />;
    case 'description':
      return <Description text={element.text} />;
    case 'inputToModule':
      return <Inputs input={element} isDisabled={isDisabled} />;
    case 'textbox':
      return <TextInputWrapper element={element} isDisabled={isDisabled} />;
    case 'jsonBox':
      return <JsonBox element={element} isDisabled={isDisabled} />;
    default:
      return null;
  }
}

InputComponent.propTypes = {
  element: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default InputComponent;
