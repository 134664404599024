import PropTypes, { object } from 'prop-types';
import '../../Workflow.scss';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import BaseDrawer from '../BaseDrawer';
import {
  updateCloseBrandingWindow,
} from '../../../reducers/editBranding';
import { selectVersionedModules, setCurrentModuleUIConfig } from '../../../reducers/workflow';
import PropertiesTab from '../../../containers/PropertiesTab/PropertiesTab';
import NewConditionDrawer from './NewConditionDrawer/NewConditionDrawer';
import { getGroupedListOfModules } from '../../../utils/editBrandingUtils';

function NewBaseDrawer(props) {
  const {
    selectedNode,
    workflowId,
    uiConfig,
  } = props;
  const dispatch = useDispatch();
  const closeBrandingWindow = useSelector(
    (state) => state.editBranding.closeBrandingWindow,
  );
  const supportedScreens = useSelector((state) => state.editBranding.screens);
  const versionedModules = useSelector(selectVersionedModules);
  const selectedWorkflow = useSelector((state) => state.workflow.selectedWorkflow);
  const {
    inputs,
    configurations,
    outputs,
    modalConfig,
  } = uiConfig.sections;
  const [activeTab, setActiveTab] = useState(1);

  const handleClick = (tabNumber) => {
    if (tabNumber === 2) {
      dispatch(updateCloseBrandingWindow({ closeBrandingWindow: false }));
    }
    setActiveTab(tabNumber);
  };

  const isUIScreensDisabled = useMemo(() => {
    const { listOfListOfModules } = getGroupedListOfModules(
      selectedWorkflow,
      versionedModules,
      supportedScreens,
    );
    return (!listOfListOfModules?.length);
  }, [selectedWorkflow, supportedScreens, versionedModules]);

  useEffect(() => {
    dispatch(setCurrentModuleUIConfig({ uiConfig }));
  }, [JSON.stringify(uiConfig)]);

  useEffect(() => {
    if (closeBrandingWindow === true) {
      setActiveTab(1);
    }
  }, [closeBrandingWindow]);

  const renderTabElements = () => {
    if (activeTab === 2 && !closeBrandingWindow) {
      return (
        <Navigate to={`/view/ui-screens?id=${workflowId}`} />
      );
    }
    return (
      <>
        <PropertiesTab
          inputs={inputs}
          configurations={configurations}
          outputs={outputs}
          modalConfig={modalConfig}
        />
        {selectedNode.nodeType === 'condition' && <NewConditionDrawer />}
      </>
    );
  };

  return (
    <BaseDrawer
      heading={uiConfig.moduleName}
      uiProps={{ icon: uiConfig.icon }}
    >
      <div className="options-ribbon">
        <button
          type="button"
          className={`options-ribbon__tab ${activeTab === 1 ? 'options-ribbon__active' : ''}`}
          onClick={() => handleClick(1)}
        >
          Properties
        </button>
        <button
          type="button"
          className={`options-ribbon__tab ${activeTab === 2 ? 'options-ribbon__active' : ''}`}
          onClick={() => handleClick(2)}
          disabled={isUIScreensDisabled}
        >
          UI Screens
        </button>
        <button
          type="button"
          className={`options-ribbon__tab__info ${activeTab === 3 ? 'options-ribbon__active' : ''}`}
          // onClickCapture={() => handleClick()}
        >
          Info
        </button>
      </div>
      {renderTabElements()}
    </BaseDrawer>
  );
}

NewBaseDrawer.propTypes = {
  selectedNode: PropTypes.objectOf(object).isRequired,
  workflowId: PropTypes.string.isRequired,
  uiConfig: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default NewBaseDrawer;
