/* eslint-disable no-unused-vars */
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { cloneDeep } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  updateSelectedScreenState,
  updateSelectedModuleState,
  updateSelectedModuleSubType,
  updateSelectedModuleType,
  updateCloseBrandingWindow,
  selectSelectedModuleSubType,
  updateCloseSDK,
  selectSelectedModuleType,
  updateBrandingElements,
  selectSelectedModuleState,
  selectCloseSDK,
  selectDefaultTextConfig,
  updateSelectedLanguage,
  selectSelectedLanguage,
  updateCustomTextConfig,
  selectCustomTextConfig,
  selectFontStyleSheets,
  selectCustomUIConfig,
  updateFontStyleSheets,
} from '../../reducers/editBranding';
import {
  selectReloadSdk, updateSelectedComponentPath, selectedComponentPath,
} from '../../reducers/dynamicForm';
import './EditBrandingContainer.scss';
import BrandingNavBar from '../../components/Branding/BrandingNavBar';
import ShowListOfModuleScreens from '../../components/Branding/ShowListOfModuleScreens';
import DisplaySDKScreen from '../../components/Branding/DisplaySDKScreen';
import ShowListOfElements from '../../components/Branding/ShowListOfElements';
import { closeExistingSDK } from '../../components/utils';
import EditSDKBranding from './EditSDKBranding';
import EditIndividualScreens from './EditIndividualScreens';
import FormModuleDrawer from '../FormModule/FormModuleDrawer';
import {
  selectOrderOfNodes, selectSelectedNode, selectSelectedWorkflow, selectVersionedModules,
} from '../../reducers/workflow';
import useApiHooks from '../../utils/useApiHooks';
import { getGroupedListOfModules } from '../../utils/editBrandingUtils';
import languageCodes from '../../components/Branding/utils/languageCodeMapping';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import { workflowOperationsObj } from '../../workflowOperations';
import { getSelectedModule } from '../../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';
import withDeletionDependencyCheck from '../../utils/withDeletionDependencyCheck';
import defaultEditConfiguration, { defaultAllowedOperationsForSuperModules } from './constants';
import {
  getConditionalVariables, getModuleOutputs, getPredefinedValues, getWorkflowInputVariables,
} from '../../components/ViewWorkflow/v2/InputsToModule/utils';
import {
  handleOnAdd, handleOnCopy, handleOnDelete, handleOnDrag, handleOnUpdate,
} from './helper';
import { compileUiConfig } from '../uiConfigOperations';
import { formComponentList as formComponentsConfig } from '../../constants/dynamicFormComponents';
import FormModuleDrawerV2 from '../FormModule/FormModuleDrawerV2';

function EditBrandingContainer(props) {
  const { workflowId, checkDependencies: checkDeletionDependencies } = props;
  const dispatch = useDispatch();
  const {
    pathArray: selectedComponentPathArray,
  } = useSelector(selectedComponentPath);
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const versionedModules = useSelector(selectVersionedModules);
  const selectedNode = useSelector(selectSelectedNode);
  const uiConfig = useSelector(selectCustomUIConfig);
  const textConfig = useSelector(selectCustomTextConfig);
  const defaultConfigs = useSelector(selectDefaultTextConfig);
  const selectedLanguage = useSelector(selectSelectedLanguage);
  const supportedScreens = useSelector((state) => state.editBranding.screens);
  const selectedModuleType = useSelector(selectSelectedModuleType);
  const selectedModuleSubType = useSelector(selectSelectedModuleSubType);
  const brandingElements = useSelector((state) => state.editBranding.brandingElements);
  const selectedScreen = useSelector((state) => state.editBranding.selectedScreenState);
  const selectedModuleId = useSelector(selectSelectedModuleState);
  const orderOfNodes = useSelector(selectOrderOfNodes);

  const jwtToken = useSelector((state) => state.user.appIdKeyToken);
  const customStyleSheetsObj = useSelector(selectFontStyleSheets);
  const customStyleSheets = Object.values(customStyleSheetsObj);
  const reloadSdk = useSelector(selectReloadSdk);
  const closeSdk = useSelector(selectCloseSDK);

  const [selectedElementIndex, setSelectedElementIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(2);
  const [closePopup, setClosePopup] = useState(false);
  // TODO: Move this state inside ShowListOfModulesSuper
  const [screensToDisplay, setScreensToDisplay] = useState([]);

  // TODO: Move to page
  const navigate = useNavigate();
  const selectedElement = useMemo(
    () => brandingElements[selectedElementIndex] || {},
    [brandingElements, selectedElementIndex],
  );

  const { fetchCustomTextConfig } = useApiHooks();

  const languagesSupported = useMemo(() => {
    const textConfigKeys = Object.keys(defaultConfigs || {})
      .filter((configName) => configName.endsWith('text_config') && languageCodes[configName.split('_')[1] || '']);
    return textConfigKeys.map((key) => {
      const languageCode = key.split('_')[1] || '';
      return {
        id: languageCode,
        name: languageCodes[languageCode],
        value: languageCode,
      };
    });
  }, [defaultConfigs]);

  const onLanguageChange = (value) => {
    dispatch(updateSelectedLanguage({ language: value }));
  };

  const handleClick = (tabNumber) => {
    if (activeTab !== tabNumber) {
      setActiveTab(tabNumber);
    }
  };

  const updateScreenName = useCallback(
    (module) => {
      setScreensToDisplay((oldScreens) => {
        if (oldScreens.includes(module)) {
          return oldScreens.filter((screen) => screen !== module);
        }
        return [...oldScreens, module];
      });
    },
    [setScreensToDisplay],
  );

  const handleScreenSelect = useCallback(
    (screen, module) => {
      dispatch(updateSelectedModuleState({ module }));
      dispatch(updateSelectedModuleSubType({ module }));
      dispatch(updateSelectedModuleType({ module }));
      dispatch(updateSelectedScreenState({ screen }));
    },
    [dispatch],
  );

  // Set modules to be displayed in UI screens
  const modulesAndCompiledWorkflow = useMemo(() => {
    const { listOfListOfModules, workflow } = getGroupedListOfModules(
      selectedWorkflow,
      versionedModules,
      supportedScreens,
    );
    return { listOfListOfModules, workflow };
  /* selectedWorkflow can change from any children of EditBrandingContainer,
     but we don't want to recalculate modulesInWorkflow to avoid re-triggers,
     hence it should be skipped from the deps array */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionedModules, supportedScreens, JSON.stringify(selectedWorkflow)]);

  const getCurrentSelectedModuleData = (highLevelWorkflow, compiledWorkflow, moduleId) => {
    if (!moduleId || !highLevelWorkflow || !compiledWorkflow) return null;
    const moduleFromDecompiledWorkflow = getSelectedModule(highLevelWorkflow, moduleId);
    const moduleData = {
      module: moduleFromDecompiledWorkflow,
      subType: '',
      version: 'v1',
      isFormPartOfSuperModule: false,
      superModuleId: '',
      mappingId: '',
    };

    if (!moduleFromDecompiledWorkflow) {
      const moduleFromCompiledWorkflow = getSelectedModule(compiledWorkflow, moduleId);
      if (moduleFromCompiledWorkflow) {
        const { superModuleId, superModuleType, mappingId } = moduleFromCompiledWorkflow;
        const superModule = getSelectedModule(highLevelWorkflow, superModuleId);
        moduleData.isFormPartOfSuperModule = true;
        moduleData.superModuleId = superModuleId;
        moduleData.subType = superModuleType;
        moduleData.mappingId = mappingId;
        moduleData.version = superModule?.version || 'v1';
        moduleData.module = moduleFromCompiledWorkflow;
      }
    }
    return moduleData;
  };

  const [selectedModuleData, setSelectedModuleData] = useState(getCurrentSelectedModuleData(
    selectedWorkflow,
    modulesAndCompiledWorkflow.workflow,
    selectedModuleId,
  ));

  useEffect(() => {
    const moduleData = getCurrentSelectedModuleData(
      selectedWorkflow,
      modulesAndCompiledWorkflow.workflow,
      selectedModuleId,
    );
    setSelectedModuleData(moduleData);
  }, [JSON.stringify(selectedWorkflow),
    selectedModuleId,
    JSON.stringify(modulesAndCompiledWorkflow),
  ]);

  const getSelectedModuleConfigs = (moduleData, versionedModuleConfigs) => {
    if (!moduleData || !versionedModuleConfigs) return null;
    const { subType, version } = moduleData;

    if (!versionedModuleConfigs?.[subType]?.[version]) return null;
    const { config: moduleConfig, uiConfig: moduleUiConfig } =
      versionedModuleConfigs[subType][version] || {};
    return {
      config: moduleConfig,
      uiConfig: moduleUiConfig,
    };
  };

  const [selectedModuleConfigs, setModuleConfigs] = useState(
    getSelectedModuleConfigs(selectedModuleData, versionedModules),
  );

  useEffect(() => {
    if (selectedModuleData) {
      setModuleConfigs(getSelectedModuleConfigs(selectedModuleData, versionedModules));
    }
  }, [JSON.stringify(selectedModuleData), JSON.stringify(versionedModules)]);

  const getEditConfigurations = (moduleConfigs, moduleData) => {
    if (!moduleConfigs || !moduleData) return null;
    const { isFormPartOfSuperModule, mappingId } = moduleData;
    if (!isFormPartOfSuperModule) return defaultEditConfiguration;
    const { config: moduleConfig, uiConfig: moduleUiConfig } = moduleConfigs;
    const keysToEdit = (moduleUiConfig?.sections?.configurations || [])
      .filter((config) => config?.type === 'componentEdits')
      .filter((config) => config.workflowKey.split('[+]')?.[0] === mappingId)
      .map((config) => ({ workflowKey: config?.workflowKey, title: config?.text }));

    const componentEdits = keysToEdit.map(({ workflowKey, title }) => {
      const data = moduleConfig?.properties?.[workflowKey];
      return {
        allowedOperations: defaultAllowedOperationsForSuperModules,
        title,
        basePath: data.basePath,
      };
    });
    return componentEdits;
  };

  const [editConfigurations, setEditConfigurations] = useState(
    getEditConfigurations(selectedModuleConfigs, selectedModuleData),
  );

  useEffect(() => {
    setEditConfigurations(
      getEditConfigurations(selectedModuleConfigs, selectedModuleData),
    );
  }, [JSON.stringify(selectedModuleConfigs), JSON.stringify(selectedModuleData)]);

  useEffect(() => {
    if (textConfig && Object.keys(textConfig).length) return;

    fetchCustomTextConfig(workflowId, selectedLanguage)
      .then((res) => {
        updateWorkflowInState({}, true, {
          operation: workflowOperationsObj.SET_WORKFLOW_ATTRIBUTE,
          actionData: {
            path: `properties.textConfigSource.${selectedLanguage}`,
            value: Object.keys(res || {}).length ? 'custom' : 'default',
          },
        });
        dispatch(updateCustomTextConfig({ textConfig: res, language: selectedLanguage }));
      }).catch(() => {
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage, workflowId]);

  useEffect(() => {
    dispatch(updateCloseSDK({ closeSDK: false }));

    return () => {
      dispatch(updateCloseSDK({ closeSDK: true }));
    };
  }, [dispatch]);

  const onSetSelectedElement = (index) => {
    setSelectedElementIndex(index);
  };

  // Runs once on mount
  useEffect(() => {
    const currentModule =
      modulesAndCompiledWorkflow.listOfListOfModules.find(
        (module) => module.id === selectedNode?.id,
      ) || modulesAndCompiledWorkflow.listOfListOfModules[0];
    updateScreenName(currentModule);

    const currentModuleType = currentModule?.modules[0].moduleType || 'NA';
    const currentScreen = supportedScreens?.[currentModuleType]?.[0].state || supportedScreens?.[currentModuleType]?.[0].name || '';
    handleScreenSelect(currentScreen, currentModule?.modules[0] || { id: '', nodeType: '' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedNode?.id, updateScreenName, supportedScreens, handleScreenSelect,
  ]);

  // Update brandingElements when selectedModuleType, selectedScreen change
  useEffect(() => {
    const selectedScreenBrandingConfig = supportedScreens[selectedModuleType]?.find(
      (screens) => screens.state === selectedScreen,
    );
    if (selectedScreenBrandingConfig?.brandingKeys) {
      const { brandingKeys } = selectedScreenBrandingConfig;
      dispatch(updateBrandingElements({ brandingElements: brandingKeys }));
    } else {
      dispatch(updateBrandingElements({ brandingElements: [] }));
    }
    setSelectedElementIndex(0);
  }, [selectedModuleType, selectedScreen, dispatch, supportedScreens]);

  const onElementUpdate = (element) => {
    const newBrandingKeys = cloneDeep(brandingElements);
    newBrandingKeys[selectedElementIndex] = element;
    dispatch(updateBrandingElements({ brandingElements: newBrandingKeys }));
  };

  const handleSave = async () => {
    dispatch(updateCloseBrandingWindow({ closeBrandingWindow: true }));
    closeExistingSDK();
    dispatch(updateCloseSDK({ closeSDK: true }));
    setClosePopup(true);
    navigate(`/view?id=${workflowId}`);
  };

  const handleOnClick = (pathArray, rootPath) => {
    dispatch(updateSelectedComponentPath({ pathArray, basePath: rootPath }));
  };

  const addNewFontURL = (fontName) => {
    const url = process.env.REACT_APP_CUSTOM_FONT_URL.replace('<SELECTED_FONT>', fontName);
    dispatch(updateFontStyleSheets({ fontStyleSheets: { [fontName]: url } }));
  };

  const renderSDKBranding = () => (
    activeTab === 1 ? <EditSDKBranding /> : null
  );

  const onUpdateModuleProperties = (moduleId, workflowKey, value) => {
    if (value === null) {
      return updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.UNSET_MODULE_PROPERTY,
        actionData: {
          targetNodeId: moduleId,
          workflowKey,
        },
      });
    }
    return updateWorkflowInState({}, true, {
      operation: workflowOperationsObj.SET_MODULE_PROPERTY,
      actionData: {
        targetNodeId: moduleId,
        workflowKey,
        value,
        moduleConfig: {},
      },
    });
  };

  const renderFormModuleDrawer = () => (
    (activeTab === 2 && selectedModuleSubType === 'form' && selectedModuleData?.module) ? (
      <FormModuleDrawer
        editConfigurations={editConfigurations || defaultEditConfiguration}
        formModule={selectedModuleData.module}
        onClickComponent={handleOnClick}
        onDragComponent={(fromComponentId, toComponentId, rootPath) => {
          handleOnDrag({
            fromComponentId,
            toComponentId,
            rootPath,
            moduleId: selectedModuleData?.module?.id,
            isFormPartOfSuperModule: selectedModuleData?.isFormPartOfSuperModule,
            workflow: selectedModuleData?.isFormPartOfSuperModule
              ? modulesAndCompiledWorkflow.workflow
              : selectedWorkflow,
          });
        }}
        onAddComponent={(pathArray, rootPath) => handleOnAdd({
          pathArray,
          rootPath,
          moduleId: selectedModuleData?.module?.id,
          isFormPartOfSuperModule: selectedModuleData?.isFormPartOfSuperModule,
          workflow: selectedModuleData?.isFormPartOfSuperModule ?
            modulesAndCompiledWorkflow.workflow : selectedWorkflow,
        })}
        onDeleteComponent={(pathArray, rootPath, currCustomUiConfig) => handleOnDelete({
          pathArray,
          rootPath,
          moduleId: selectedModuleData?.module?.id,
          isFormPartOfSuperModule: selectedModuleData?.isFormPartOfSuperModule,
          checkDepsFn: checkDeletionDependencies,
          currCustomUiConfig,
          workflow: selectedModuleData?.isFormPartOfSuperModule ?
            modulesAndCompiledWorkflow.workflow : selectedWorkflow,
          selectedComponentPathArray,
        })}
        onUpdateComponent={(
          pathArray,
          rootPath,
          newComponent,
          componentUIConfig,
          currUiConfig,
        ) => handleOnUpdate({
          pathArray,
          rootPath,
          moduleId: selectedModuleData?.module?.id,
          isFormPartOfSuperModule: selectedModuleData?.isFormPartOfSuperModule,
          workflow: selectedModuleData?.isFormPartOfSuperModule ?
            modulesAndCompiledWorkflow.workflow : selectedWorkflow,
          newComponent,
          componentUIConfig,
          currUiConfig,
        })}
        onCopyComponent={(pathArray, rootPath) => handleOnCopy({
          pathArray,
          rootPath,
          moduleId: selectedModuleData?.module?.id,
          isFormPartOfSuperModule: selectedModuleData?.isFormPartOfSuperModule,
          workflow: selectedModuleData?.isFormPartOfSuperModule ?
            modulesAndCompiledWorkflow.workflow : selectedWorkflow,
          customUiConfig: uiConfig,
        })}
        onAddFontUrl={addNewFontURL}
        moduleOutputs={getModuleOutputs(
          orderOfNodes,
          selectedNode?.id,
          selectedWorkflow,
          formComponentsConfig,
          versionedModules,
        )}
        conditionalVariables={getConditionalVariables(selectedWorkflow)}
        workflowInputs={getWorkflowInputVariables(selectedWorkflow)}
        preDefinedValues={getPredefinedValues(selectedWorkflow, formComponentsConfig)}
        uiConfig={compileUiConfig(uiConfig, modulesAndCompiledWorkflow.workflow)}
      />
    ) : null
  );

  const renderFormModuleDrawerV2 = () => (
    (activeTab === 2 && selectedModuleSubType === 'formV2' && selectedModuleData?.module) ? (
      <FormModuleDrawerV2
        onUpdateProperties={(workflowKey, value) => onUpdateModuleProperties(
          selectedModuleData?.module?.id,
          workflowKey,
          value,
        )}
        formModule={selectedModuleData.module}
      />
    ) : null
  );

  const renderOtherUIElements = () => {
    if (activeTab === 2 && selectedModuleSubType !== 'form' && selectedModuleSubType !== 'formV2') {
      return selectedElement &&
        Object.keys(selectedElement) &&
        brandingElements.length > 0 &&
        brandingElements.find((elem) => Boolean(elem.textConfig)) ? (
          <Grid container>
            <Grid item xs={5}>
              <ShowListOfElements
                selectedElement={selectedElement}
                brandingElements={brandingElements}
                setSelectedElement={onSetSelectedElement}
                selectedModuleId={selectedModuleId}
                selectedWorkflow={selectedWorkflow}
              />
            </Grid>
            <Grid item xs={7} alignItems="center">
              {selectedElement && Object.keys(selectedElement) && (
                <EditIndividualScreens
                  selectedElement={selectedElement}
                  onElementUpdate={onElementUpdate}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <div className="edit-branding-popup__no-content">Nothing to edit here!</div>
        );
    }
    return null;
  };

  return closePopup === false ? (
    <div className="edit-branding-popup-background">
      <div className="edit-branding-popup">
        <BrandingNavBar
          activeTab={activeTab}
          handleClick={handleClick}
          isSaving={false}
          handleSave={handleSave}
          languagesSupported={languagesSupported}
          onLanguageChange={onLanguageChange}
          selectedLanguage={languageCodes[selectedLanguage || 'en']}
        />
        <div className="edit-branding-popup__body">
          <ShowListOfModuleScreens
            modulesInWorkflow={modulesAndCompiledWorkflow.listOfListOfModules}
            screensToDisplay={screensToDisplay}
            supportedScreens={supportedScreens}
            updateScreenNames={updateScreenName}
            handleScreenSelect={handleScreenSelect}
            selectedScreen={selectedScreen}
            selectedModuleType={selectedModuleType}
            selectedModuleId={selectedModuleId}
          />
          <div className="sdk-screen">
            <DisplaySDKScreen
              versionedModules={versionedModules}
              selectedWorkflow={modulesAndCompiledWorkflow.workflow}
              screenToTeleport={selectedScreen}
              uiConfig={uiConfig}
              textConfig={textConfig}
              defaultTextConfig={defaultConfigs[`default_${selectedLanguage}_text_config`]}
              moduleToTeleport={selectedModuleId}
              jwtToken={jwtToken}
              customStyleSheets={customStyleSheets}
              reloadSdk={reloadSdk}
              closeSdk={closeSdk}
              selectedLanguage={selectedLanguage}
              selectedElement={selectedElement}
              workflowId={workflowId}
            />
          </div>
          <div className="edit-branding-popup__body__settings">
            {renderSDKBranding()}
            {renderFormModuleDrawer()}
            {renderFormModuleDrawerV2()}
            {renderOtherUIElements()}
          </div>
        </div>
      </div>
    </div>
  ) : (
    ''
  );
}

EditBrandingContainer.propTypes = {
  checkDependencies: PropTypes.func.isRequired,
  workflowId: PropTypes.string.isRequired,
};

export default withDeletionDependencyCheck(EditBrandingContainer);
