import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import Toggle from '../FormModule/Common/Toggle';
import DropDown from '../FormModule/Common/DropDown';
import IntTextInput from './v2/IntTextInput/IntTextInput';
import { workflowOperationsObj as operations } from '../../workflowOperations';

// TODO: Create a config file for all these configurations
function DisplayWorkflowConfigurations({ workflowConfig }) {
  const onSetRedirectToMobile = (value) => {
    updateWorkflowInState({}, true, {
      operation: operations.SET_WORKFLOW_ATTRIBUTE,
      actionData: {
        path: 'properties.redirectToMobile',
        value,
      },
    });
  };

  const onSetServerSideResume = (value) => {
    const { enable } = value;
    if (typeof enable !== 'undefined') {
      updateWorkflowInState({}, true, {
        operation: operations.SET_WORKFLOW_ATTRIBUTE,
        actionData: {
          path: 'properties.enableServerSideResume',
          value: enable,
        },
      });
    }
  };

  const onSetResumeWorkflow = (value) => {
    const { enable, duration } = value;
    const newWorkflow = cloneDeep(workflowConfig);
    if (typeof enable !== 'undefined') {
      updateWorkflowInState({}, true, {
        operation: operations.SET_WORKFLOW_ATTRIBUTE,
        actionData: {
          path: 'properties.enableResumeWorkflow',
          value: enable,
        },
      });
    }
    if (typeof duration !== 'undefined') {
      updateWorkflowInState({}, true, {
        operation: operations.SET_WORKFLOW_ATTRIBUTE,
        actionData: {
          path: 'properties.resumeWorkflowDuration',
          value: duration,
        },
      });
      newWorkflow.properties.resumeWorkflowDuration = duration;
    }
  };

  const onSetReturnPartialSDKResponse = (value) => {
    updateWorkflowInState({}, true, {
      operation: operations.SET_WORKFLOW_ATTRIBUTE,
      actionData: {
        path: 'properties.web.returnPartialSDKResponse',
        value,
      },
    });
  };

  return (
    <>
      <div className="drawer_content__heading_default">CONFIGURATIONS OF THE WORKFLOW</div>
      <div className="edit-properties-div__property">
        <DropDown
          label="Redirect to mobile"
          options={[
            { key: 'yes', name: 'Yes' },
            { key: 'no', name: 'No' },
            { key: 'optional', name: 'Optional' },
          ]}
          defaultValue={workflowConfig.properties?.redirectToMobile || ''}
          onChange={(data) => {
            onSetRedirectToMobile(data);
          }}
        />
      </div>
      <div className="edit-properties-div__property">
        <Toggle
          label="Enable server side resume"
          onChange={(val) => {
            onSetServerSideResume({ enable: val });
          }}
          value={workflowConfig.properties?.enableServerSideResume === true}
        />
      </div>
      <div className="edit-properties-div__property">
        <Toggle
          label="Resume workflow"
          onChange={(val) => {
            onSetResumeWorkflow({ enable: val });
          }}
          value={workflowConfig.properties?.enableResumeWorkflow === true}
        />
      </div>
      {
        workflowConfig.properties?.enableResumeWorkflow ? (
          <IntTextInput
            label="Resume duration"
            onChange={(data) => {
              const duration = parseInt(data, 10);
              if (typeof duration === 'number' && duration > 0) onSetResumeWorkflow({ duration });
            }}
            defaultValue={workflowConfig.properties?.resumeWorkflowDuration}
          />
        )
          : null
      }
      <div className="edit-properties-div__property">
        <Toggle
          label="Return partial SDK response"
          onChange={(val) => {
            onSetReturnPartialSDKResponse(val);
          }}
          value={workflowConfig.properties?.web?.returnPartialSDKResponse === true}
        />
      </div>
    </>
  );
}

DisplayWorkflowConfigurations.propTypes = {
  workflowConfig: PropTypes.object.isRequired,
};

export default DisplayWorkflowConfigurations;
