/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { JsonEditor as Json1 } from 'json-edit-react';
import useDebounce from '../../../utils/useDebounce';

function JsonEditor(props) {
  const {
    label,
    onChange,
    formatAsJson,
    defaultValue,
    readOnly,
    placeholder,
    rootName,
  } = props;
  const [value, setValue] = useState(defaultValue || {});

  return (
    <Grid container alignItems="center" className="edit-properties-div__text-input-container">
      <Grid item xs={12}>
        <label htmlFor={label} className="edit-properties-div__label">{label}</label>
      </Grid>
      <Grid item xs={12}>
        <Json1
          data={value}
          rootFontSize="12px"
          rootName={rootName}
          onUpdate={(val) => {
            onChange(val.newData);
          }}
          theme={[
            'githubLight',
            {
              iconEdit: 'grey',
              boolean: {
                color: 'red', fontStyle: 'italic', fontWeight: 'light', fontSize: '10%',
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

JsonEditor.propTypes = {
  label: PropTypes.string.isRequired,
  formatAsJson: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  rootName: PropTypes.string,
};

JsonEditor.defaultProps = {
  placeholder: 'Please enter a value',
  rootName: 'events',
};

export default JsonEditor;
