import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import ModuleVariable from './ModuleVariable';
import isValidData from './validate';

function ModuleVariables(props) {
  const {
    onVariablesChange, variables, nameLabel, pathLabel, addBtnLabel, canDeleteOutputVariable,
  } = props;
  const addNewVariable = (variableList) => {
    const newVariablesList = cloneDeep(variableList || []);
    newVariablesList.push({ name: '', path: '' });
    onVariablesChange(newVariablesList);
  };

  const deleteVariable = (index, variableList) => {
    if (!canDeleteOutputVariable(variableList[index].name)) return;
    const newVariablesList = cloneDeep(variableList || []);
    newVariablesList.splice(index, 1);
    onVariablesChange(newVariablesList);
  };

  const onChange = (newValue, index, key, variableList) => {
    const newVariablesList = cloneDeep(variableList || []);
    if (index < newVariablesList.length) newVariablesList[index][key] = newValue;
    onVariablesChange(newVariablesList);
  };

  const isValid = (variableList) => {
    const data = variableList || [];
    return isValidData(data);
  };

  return (
    <div className="workflowOutput__bottomContent_body">
      {
        isValid(variables) ?
          (variables || []).map(({ name, path }, index) => (
            <ModuleVariable
              // TODO: What else can serve as a key?
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              name={name}
              path={path}
              nameTitle={nameLabel}
              pathTitle={pathLabel}
              onNameChange={(value) => { onChange(value, index, 'name', variables); }}
              onPathChange={(value) => { onChange(value, index, 'path', variables); }}
              onDelete={() => { deleteVariable(index, variables); }}
            />
          ))
          : <p>Opps! Workflow has invalid data</p>
      }
      {
        isValid(variables) ? <button type="button" onClick={() => { addNewVariable(variables); }} className="workflowInput__bottomContent_button">{addBtnLabel}</button> : null
      }
    </div>
  );
}

ModuleVariables.propTypes = {
  variables: PropTypes.any.isRequired,
  onVariablesChange: PropTypes.func.isRequired,
  nameLabel: PropTypes.string.isRequired,
  pathLabel: PropTypes.string.isRequired,
  addBtnLabel: PropTypes.string.isRequired,
  canDeleteOutputVariable: PropTypes.func.isRequired,
};

export default ModuleVariables;
