import { useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import '../SelectDocuments/DocumentsSelect.scss';
import './Countries.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectGlobalConfig, selectSelectedNode, selectSelectedWorkflow,
  updateSelectedCountries,
} from '../../../../reducers/workflow';
import setIntialCountries from './utils/setIntialCountries';
import CountriesPopup from './CountriesPopup';
import SelectedCountriesTable from './SelectedCountriesTable';
import updateWorkflow from './utils/updateWorkflow';
import updateDocumentsInWorkflow from './updateDocumentsInWorkflow';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';

function CountriesSelect({ options, isDisabled }) {
  const dispatch = useDispatch();
  const documentList = useSelector(selectGlobalConfig);
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const selectedNodeId = useSelector(selectSelectedNode)?.id;
  const selectedWorkflowModules = selectedWorkflow.modules;
  const selectedModule = selectedWorkflowModules?.find(
    (module) => module.id === selectedNodeId,
  );
  const countriesList = documentList.map(({ name, id }) => ({ id, name }));

  const [countriesSelected, setCountriesSelected] = useState(
    setIntialCountries(selectedModule, countriesList),
  );

  // State to track whether the popup is open or closed
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the popup state
  const togglePopup = () => {
    setCountriesSelected(setIntialCountries(selectedModule, countriesList));
    setIsOpen(!isOpen);
  };

  const handleConfirmClick = () => {
    const finalCountriesSelected = countriesSelected.filter((country) => country.id !== '');
    if (finalCountriesSelected.length === 0) {
      // eslint-disable-next-line no-alert
      alert('select atleast one country');
    } else {
      togglePopup();

      dispatch(updateSelectedCountries({ countries: finalCountriesSelected }));

      // update workflow
      let editedWorkflow = updateWorkflow(selectedWorkflow, finalCountriesSelected, selectedNodeId);
      editedWorkflow = updateDocumentsInWorkflow(
        editedWorkflow,
        finalCountriesSelected,
        documentList,
      );
      updateWorkflowInState(editedWorkflow);
    }
  };

  const handleSelectAllCountries = (selectAll) => {
    let newCountriesList = countriesList;
    if (!selectAll) {
      newCountriesList = [];
    }
    setCountriesSelected(newCountriesList);
  };

  const handleOptionChange = (option) => {
    const index = countriesSelected
      .findIndex(
        (country) => option.id === country.id,
      );
    const newcountriesSelected = cloneDeep(countriesSelected);
    if (index === -1) {
      newcountriesSelected.push(option);
    } else {
      newcountriesSelected.splice(index, 1);
    }
    if (newcountriesSelected.length === 0) newcountriesSelected.push({ id: '', name: '' });
    setCountriesSelected(newcountriesSelected);
  };

  const shouldSelectCheckBox = (option) => {
    const index = countriesSelected.findIndex((country) => country.id === option.id);

    if (index !== -1) return true;
    return false;
  };

  return (
    <div>
      <SelectedCountriesTable
        options={options}
        countriesSelected={setIntialCountries(selectedModule, countriesList)}
        togglePopup={togglePopup}
        isDisabled={isDisabled}
      />
      {isOpen && (
      <CountriesPopup
        options={options}
        documentList={documentList}
        handleOptionChange={handleOptionChange}
        shouldSelectCheckBox={shouldSelectCheckBox}
        togglePopup={togglePopup}
        selectAllCountries={handleSelectAllCountries}
        handleConfirmClick={handleConfirmClick}
      />
      )}
    </div>

  );
}

CountriesSelect.propTypes = {
  options: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
};

CountriesSelect.defaultProps = {
  isDisabled: false,
};

export default CountriesSelect;
