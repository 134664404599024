import { useSelector } from 'react-redux';
import JSZip from 'jszip';

import Button from '@mui/material/Button';

import '../../../containers/Container.scss';
import { useLocation } from 'react-router-dom';
import { selectSelectedWorkflow, selectVersionedModules } from '../../../reducers/workflow';
import compile from '../../../compilerDecompiler/compiler';
import {
  generateModuleConfigsFromWorkflow,
  generateModuleUiConfigFromWorkflow,
} from '../../../compilerDecompiler/workflowModuleTransformer';
import {
  selectModuleBuilderMode, selectModuleInputs,
  selectModuleOutputs, selectModuleProperties,
  selectModuleBuilderSubtype,
} from '../../../reducers/moduleBuilder';
import { selectCustomTextConfig, selectCustomUIConfig, selectSelectedLanguage } from '../../../reducers/editBranding';
import { compileUiConfig } from '../../../containers/uiConfigOperations';
import DownloadIcon from '../../../assests/icons/downloadIcon.svg';
import { formComponentList } from '../../../constants/dynamicFormComponents';

function DownloadWorkflow() {
  const { search } = useLocation();
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const customTextConfig = useSelector(selectCustomTextConfig);
  const customUiConfig = useSelector(selectCustomUIConfig);
  const versionedModules = useSelector(selectVersionedModules);
  const selectedLanguage = useSelector(selectSelectedLanguage);
  const moduleBuilderMode = useSelector(selectModuleBuilderMode);
  const moduleBuilderInputs = useSelector(selectModuleInputs);
  const moduleBuilderOutputs = useSelector(selectModuleOutputs);
  const moduleBuilderProperties = useSelector(selectModuleProperties);
  const moduleBuilderSubType = useSelector(selectModuleBuilderSubtype);
  const workflowId = new URLSearchParams(search).get('id');

  const handleClick = async () => {
    const compiledWorkflow = compile(selectedWorkflow, versionedModules, formComponentList);
    const zip = new JSZip();

    if (moduleBuilderMode) {
      const moduleConfig = generateModuleConfigsFromWorkflow(
        compiledWorkflow,
        moduleBuilderInputs,
        moduleBuilderOutputs,
        moduleBuilderProperties,
        moduleBuilderSubType,
      );
      const uiConfig = generateModuleUiConfigFromWorkflow(
        compiledWorkflow,
        moduleBuilderInputs,
        moduleBuilderOutputs,
        moduleBuilderProperties,
      );
      zip.file('moduleConfig.json', JSON.stringify(moduleConfig));
      zip.file('uiConfig.json', JSON.stringify(uiConfig));
    } else {
      // Add JSON files to the ZIP
      zip.file(`${workflowId}.json`, JSON.stringify(compiledWorkflow));
      if (Object.keys(customUiConfig).length > 0) {
        const compiledUiConfig = compileUiConfig(customUiConfig, compiledWorkflow);
        zip.file(`${workflowId}_ui_config.json`, JSON.stringify(compiledUiConfig));
      }
      if (Object.keys(customTextConfig).length > 0) {
        zip.file(`${workflowId}_${selectedLanguage || 'en'}_text_config.json`, JSON.stringify(customTextConfig));
      }
    }

    // Generate the ZIP blob
    const content = await zip.generateAsync({ type: 'blob' });

    const url = URL.createObjectURL(content);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${workflowId}.zip`;
    a.click();
    URL.revokeObjectURL(url);
  };
  return (
    <Button
      variant="outlined"
      onClick={handleClick}
      className="more-options__option"
    >
      <img className="more-options__option-icon" src={DownloadIcon} alt="Download Icon" />
      <span className="more-options__option-text">Download Workflow</span>
    </Button>
  );
}

export default DownloadWorkflow;
