import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ModuleInputRow from './ModuleInputRow';
import ModuleInputsEditPanel from './ModuleInputsEditPanel';
import generateUniqueID from '../../utils/generateUniqueId';

function ModuleInput({
  moduleInputs,
  onAddModuleInput,
  onSaveModuleInput,
  onDeleteModuleInput,
  isBuilderPropertiesSection = false,
  moduleBuilderProperties,
  moduleIdsDropdown,
}) {
  const [showInputDetailPanel, setShowInputDetailPanel] = useState(false);
  const [currentSelectedInput, setCurrentSelectedInput] = useState({});

  useEffect(() => {
    if (currentSelectedInput?.id) onSaveModuleInput(currentSelectedInput.id, currentSelectedInput);
  }, [JSON.stringify(currentSelectedInput)]);

  const addModule = () => {
    onAddModuleInput({ name: `${isBuilderPropertiesSection ? 'builder_property' : 'input'}_${generateUniqueID()}` });
  };

  const onClickExistingInput = (id) => {
    if (showInputDetailPanel) {
      setShowInputDetailPanel(false);
      setCurrentSelectedInput({});
    } else {
      const selectedModuleInput = moduleInputs.find((input) => input.id === id);
      setCurrentSelectedInput({ ...selectedModuleInput });
      setShowInputDetailPanel(true);
    }
  };

  const onClickDeleteInput = (id) => {
    setShowInputDetailPanel(false);
    setCurrentSelectedInput({});
    onDeleteModuleInput(id);
  };

  const setModuleInputProperty = (id, property, value) => {
    const oldSelectedInputValues = { ...currentSelectedInput };
    oldSelectedInputValues[property] = value;
    setCurrentSelectedInput(oldSelectedInputValues);
  };

  return (
    <>
      <div className="workflowInput__topContent">
        <div className="workflowInput__topContent_heading">
          {isBuilderPropertiesSection ? 'Builder Properties' : 'Module Inputs'}
        </div>
        <div className="workflowInput__topContent_subheading">
          If you want to get outputs from the workflow, please configure the SDK response below
        </div>
      </div>
      <div className="workflowInput__bottomContent">
        <button onClick={() => addModule()} type="button">
          {isBuilderPropertiesSection ? 'Add Builder Properties' : 'Add Input'}
        </button>
        {moduleInputs.map((moduleInput) => (
          <ModuleInputRow
            id={moduleInput.id}
            name={moduleInput.name}
            onClick={(id) => onClickExistingInput(id)}
            onDelete={(id) => onClickDeleteInput(id)}
            key={moduleInput.id}
          />
        ))}
        {showInputDetailPanel ? (
          <>
            <ModuleInputsEditPanel
              id={currentSelectedInput.id}
              name={currentSelectedInput.name}
              inputType={currentSelectedInput.inputType}
              description={currentSelectedInput.description}
              required={currentSelectedInput.required}
              category={currentSelectedInput.category}
              inputModuleKey={currentSelectedInput.inputModuleKey}
              possibleValues={currentSelectedInput.possibleValues}
              regexForTextBox={currentSelectedInput.regexForTextBox}
              errorMessageForTextBox={currentSelectedInput.errorMessageForTextBox}
              placeholderForTextBox={currentSelectedInput.placeholderForTextBox}
              defaultValue={currentSelectedInput.defaultValue}
              setModuleInputProperty={setModuleInputProperty}
              isBuilderPropertiesSection={isBuilderPropertiesSection}
              visible={currentSelectedInput.visible}
              moduleBuilderProperties={moduleBuilderProperties}
              moduleIdsDropdown={moduleIdsDropdown}
              inputModuleId={currentSelectedInput.inputModuleId}
              possibleValuesLabels={currentSelectedInput.possibleValuesLabels}
              inputTitle={currentSelectedInput.inputTitle}
            />
          </>
        ) : ''}
      </div>
    </>
  );
}

ModuleInput.propTypes = {
  moduleInputs: PropTypes.array.isRequired,
  onAddModuleInput: PropTypes.func.isRequired,
  onSaveModuleInput: PropTypes.func.isRequired,
  onDeleteModuleInput: PropTypes.func.isRequired,
  isBuilderPropertiesSection: PropTypes.bool.isRequired,
  moduleBuilderProperties: PropTypes.array.isRequired,
  moduleIdsDropdown: PropTypes.object,
};

ModuleInput.defaultProps = {
  moduleIdsDropdown: {},
};

export default ModuleInput;
