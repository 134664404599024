import { endStateKeys, eventHandlers } from '../../components/constants';
import { getDefaultComponent, refreshComponentIds } from '../../components/FormModule/utils';
import {
  getAllNextSteps, getComponentFromPath, getFormComponents, performOpOnWorkflow, getModuleFromId,
} from './helper';

export const dragComponent = (workflow, moduleId, fromPathArray, toPathArray, rootPath) => {
  if (fromPathArray === toPathArray) return workflow;
  const selectedModule = getModuleFromId(workflow, moduleId);
  const formComponents = getFormComponents(selectedModule, rootPath);
  const draggedComponent = getComponentFromPath(formComponents, fromPathArray);
  let editedWorkflow = performOpOnWorkflow('delete', workflow, moduleId, fromPathArray, rootPath);
  editedWorkflow = performOpOnWorkflow(
    'insert',
    editedWorkflow,
    moduleId,
    toPathArray,
    rootPath,
    draggedComponent,
  );
  return editedWorkflow;
};

export const addComponent = (workflow, moduleId, defaultConfig, pathArray, rootPath) => {
  const selectedModule = getModuleFromId(workflow, moduleId);
  const componentToBeAdded = getDefaultComponent(defaultConfig, selectedModule);
  const editedWorkflow = performOpOnWorkflow(
    'add',
    workflow,
    moduleId,
    pathArray,
    rootPath,
    componentToBeAdded,
  );
  return editedWorkflow;
};

export const updateComponent = (
  workflow,
  moduleId,
  newComponent,
  pathArray,
  rootPath,
  setUiConfigSourceToCustom,
) => {
  const editedWorkflow = performOpOnWorkflow('update', workflow, moduleId, pathArray, rootPath, newComponent);
  if (setUiConfigSourceToCustom) editedWorkflow.properties.uiConfigSource = 'custom';
  return editedWorkflow;
};

export const copyComponent = (workflow, moduleId, pathArray, rootPath, formComponentsConfig) => {
  const selectedModule = getModuleFromId(workflow, moduleId);
  const formComponents = getFormComponents(selectedModule, rootPath);
  const copiedComponent = getComponentFromPath(formComponents, pathArray);
  const { component: updatedComponent, originalToClonedComponentIdMap } = refreshComponentIds(
    copiedComponent,
    selectedModule,
    formComponentsConfig,
  );
  const editedWorkflow = performOpOnWorkflow(
    'insert',
    workflow,
    moduleId,
    pathArray,
    rootPath,
    updatedComponent,
  );

  // All the nextSteps coming out from the component should be goto except end states.
  const nextSteps = getAllNextSteps([updatedComponent], eventHandlers, false);
  const moduleRef = getModuleFromId(editedWorkflow, moduleId);
  const existingNextNodeType = moduleRef.next_node_type || {};
  const newNextNodeTypes = nextSteps
    .filter(({ nextStepId }) => !endStateKeys.includes(nextStepId))
    .map(({ componentId, nextStepEvent }) => `${componentId}.${nextStepEvent}.nextStep`)
    .reduce((acc, curr) => Object.assign(acc, { [curr]: 'goto' }), {});
  moduleRef.next_node_type = {
    ...existingNextNodeType,
    ...newNextNodeTypes,
  };
  return { workflow: editedWorkflow, originalToClonedComponentIdMap, success: true };
};

export const deleteComponent = (workflow, moduleId, pathArray, rootPath) => {
  const updatedWorkflow = performOpOnWorkflow(
    'delete',
    workflow,
    moduleId,
    pathArray,
    rootPath,
  );
  return updatedWorkflow;
};
