import { useEffect, useState } from 'react';
import './NewConditionDrawer.scss';
import { useSelector } from 'react-redux';
import {
  selectSelectedNode,
  selectSelectedWorkflow, selectOrderOfNodes, selectVersionedModules,
} from '../../../../reducers/workflow';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';
import ConditionsDrawer from '../../../Conditions/ConditionsDrawer';
import { createNestedMenuData } from '../NestedDropdown/utils';
import {
  getConditionalVariables, getModuleOutputs, getWorkflowInputVariables, getPredefinedValues,
} from '../InputsToModule/utils';
import TextInput from '../TextInput/TextInput';
import { workflowOperationsObj } from '../../../../workflowOperations';
import { formComponentList as formComponentsConfig } from '../../../../constants/dynamicFormComponents';
import { selectModuleBuilderMode, selectModuleProperties } from '../../../../reducers/moduleBuilder';

function NewConditionDrawer() {
  const orderOfNodes = useSelector(selectOrderOfNodes);
  const selectedNodeId = useSelector(selectSelectedNode).id;
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const versionedModules = useSelector(selectVersionedModules);
  const moduleBuilderMode = useSelector(selectModuleBuilderMode);
  const existingModuleProperties = useSelector(selectModuleProperties);
  const [showConditionEditor, setShowConditionEditor] = useState(false);
  const [rule, setRule] = useState(selectedWorkflow?.conditions[selectedNodeId]?.rule);

  const handleAddConditionClick = () => {
    setShowConditionEditor(true);
  };

  const handleClose = () => {
    setShowConditionEditor(false);
  };

  const onSwapBranch = () => {
    updateWorkflowInState({}, true, {
      operation: workflowOperationsObj.SWAP_CONDITION_BRANCHES,
      actionData: {
        targetNodeId: selectedNodeId,
      },
    });
  };

  const onEditReason = (type, value) => {
    updateWorkflowInState({}, true, {
      operation: workflowOperationsObj.SET_CONDITION_REASON,
      actionData: {
        targetNodeId: selectedNodeId,
        branch: type,
        reason: value,
      },
    });
  };

  useEffect(() => {
    updateWorkflowInState({}, true, {
      operation: workflowOperationsObj.SET_CONDITION_RULE,
      actionData: {
        targetNodeId: selectedNodeId,
        rule,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rule]);

  useEffect(() => {
    const condition = selectedWorkflow.conditions[selectedNodeId];
    setRule(condition.rule);
  }, [selectedNodeId, orderOfNodes, selectedWorkflow.conditions]);

  const moduleOutputs = getModuleOutputs(
    orderOfNodes,
    selectedNodeId,
    selectedWorkflow,
    formComponentsConfig,
    versionedModules,
  );

  const conditionalVariables = getConditionalVariables(selectedWorkflow);

  const workflowInputs = getWorkflowInputVariables(selectedWorkflow);

  const preDefinedValues = getPredefinedValues(selectedWorkflow, formComponentsConfig);

  const dropDownOptions = createNestedMenuData(
    workflowInputs,
    conditionalVariables,
    moduleOutputs,
    moduleBuilderMode ? existingModuleProperties : null,
  ).items;

  return (
    <div className="condition-properties-div">
      <div className="condition-properties-div__title">Branches</div>
      <button
        type="button"
        onClick={handleAddConditionClick}
        className="condition-properties-div__condition-button"
      >
        if (Branch 1)
      </button>
      <button
        type="button"
        className="condition-properties-div__condition-button"
        disabled
      >
        else (Branch 2)
      </button>

      <button
        type="button"
        className="condition-properties-div__swap-button"
        onClick={onSwapBranch}
      >
        Swap branches
      </button>
      <TextInput
        label="If true reason"
        setDefaultValue
        placeholder="Type if true reason"
        onSave={(value) => onEditReason('if_true', value)}
        defaultInput={selectedWorkflow.conditions[selectedNodeId].if_true_reason || ''}

      />
      <TextInput
        label="If false reason"
        setDefaultValue
        placeholder="Type if false reason"
        onSave={(value) => onEditReason('if_false', value)}
        defaultInput={selectedWorkflow.conditions[selectedNodeId].if_false_reason || ''}
      />

      {showConditionEditor && (
      <ConditionsDrawer
        onClose={handleClose}
        rule={rule}
        dropDownOptions={dropDownOptions}
        onConditionSave={(value) => {
          setRule(value);
          setShowConditionEditor(false);
        }}
        allowDefaultRules
        preDefinedValues={preDefinedValues}
      />
      )}
    </div>
  );
}

export default NewConditionDrawer;
