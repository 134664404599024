/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { cloneDeep, get, set } from 'lodash';
import { fetchDependencyList } from '../utils/fetchDependencyList';
import findAllDocumentsInSelectedCountries from '../utils/findAllDocumentsInSelectedCountries';
import {
  findAndUpdateTheOnlyNextStepInModule,
  findComponentWithNextStep,
  updateFormPropertyViaComponentId,
} from '../components/FormModule/utils';
import { setModuleProperty, setModulePropertyInWorkflow } from '../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';
import {
  getAllFormComponents, getAllNextSteps, getSelectedComponent, createNewDomId,
} from './FormModule/helper';
import generateUniqueID from '../utils/generateUniqueId';
import { getHighLevelUiConfig } from './uiConfigOperations';
import {
  logAddCondition,
  logAddNode,
  logConditionRuleUpdate,
  logDeleteCondition,
  logDeleteNode,
  logSwapBranchesInCondition,
  logUpdateGoto,
} from '../logger/logHighLevelWorkflowUpdates';
import { eventHandlers, endStateKeys as endStates } from '../components/constants';
import { getNextStepForModule } from '../components/utils';

const getPathArray = (path) => path.split(':').map(Number).filter((n) => !Number.isNaN(n));

const getParentPathAndBranch = (id, localOrderOrNodes) => {
  const { parentPath, parentBranch } = localOrderOrNodes.find((node) => node.id === id) || {};
  return { parentPath, parentBranch };
};

const getNodeAndType = (id, selectedWorkflow) => {
  const { conditions, modules } = selectedWorkflow;
  if (conditions[id]) {
    return {
      node: conditions[id],
      type: 'condition',
    };
  }
  const module = modules.find((module) => module.id === id);
  if (module) {
    return {
      node: module,
      type: 'module',
    };
  }
  return null;
};

const getNextNodeTypeForDynamicForm = (module, componentId, nextStepHandler) => {
  const key = `${componentId}.${nextStepHandler}.nextStep`;
  return (module?.next_node_type?.[key] || '');
};

const getNewNextStepFromNodeToBeDeleted = (node, type) => {
  if (type === 'condition') {
    // TODO: Implement for condition
    if (!endStates.includes(node.if_false) && !endStates.includes(node.if_true)) return null;
    const preservedBranch = endStates.includes(node.if_false) ? 'if_true' : 'if_false';
    return {
      nextStep: node[preservedBranch],
      nextNodeType: node?.next_node_type?.[preservedBranch] || '',
    };
  }
  // TODO: Refactor this function
  if (type === 'module') {
    if (node?.type === 'dynamicForm') {
      const { component: componentWithNextStep, nextStepHandler } = findComponentWithNextStep(node);
      const componentId = componentWithNextStep?.id;

      const nextNodeType =
        getNextNodeTypeForDynamicForm(node, componentId, nextStepHandler)
        || '';
      return {
        nextStep: get(componentWithNextStep, `${nextStepHandler}.nextStep`),
        nextNodeType,
      };
    }
    if (node?.type === 'dynamicFormV2') {
      const allNextSteps = getNextStepForModule(node);
      const { nextStep, path } = allNextSteps[0] || {};
      return {
        nextStep,
        nextNodeType: node?.next_node_type?.[path] || '',
      };
    }
    return {
      nextStep: node?.nextStep,
      nextNodeType: node?.next_node_type?.default || '',
    };
  }
  // TODO: Throw warning here ?
  return null;
};

export const deleteNodeFromWorkflow = (id, parentId, selectedWorkflow) => {
  const workflow = cloneDeep(selectedWorkflow);
  const { node: nodeToDelete, type: nodeType } = getNodeAndType(id, workflow) || {};
  const { node: parentNode, type: parentNodeType } = getNodeAndType(parentId, workflow) || {};
  if (!parentNode || !parentNodeType) return { workflow, success: false };
  if (!['condition', 'module'].includes(parentNodeType)) return { workflow, success: false };

  // TODO: Warning here ?
  if (!nodeToDelete || !nodeType) return { workflow, success: false };

  // Checking if node can be deleted ?

  // TODO: Update the below logic for formV2
  // if (parentNode?.type !== 'dynamicFormV2') {

  // }

  const { moduleDependencies, conditionDependencies } = fetchDependencyList(id, workflow);
  if (conditionDependencies.length || moduleDependencies.length) {
    // eslint-disable-next-line no-alert
    alert('remove the dependencies');
    return { workflow, success: false };
  }

  if (nodeType === 'condition' && !endStates.includes(nodeToDelete.if_false) && !endStates.includes(nodeToDelete.if_true)) {
    alert('remove the dependencies');
    return { workflow, success: false };
  } if (nodeType === 'module' && nodeToDelete.type === 'dynamicForm') {
    const allComponents = getAllFormComponents(nodeToDelete);
    const allNextSteps = getAllNextSteps(allComponents, eventHandlers);
    if (allNextSteps.length !== 1) {
      alert('Can not delete form module\nAs it has more than one branches');
      return { workflow, success: false };
    }
  } if (nodeType === 'module' && nodeToDelete.type === 'dynamicFormV2') {
    // const allComponents = getAllFormComponents(nodeToDelete);
    const allNextSteps = getNextStepForModule(nodeToDelete);
    if (allNextSteps.length !== 1) {
      alert('Can not delete formV2 module\nAs it has more than one branches');
      return { workflow, success: false };
    }
  }

  const { nextNodeType: nextNodeTypeOfNodeToDelete, nextStep: newNextStep } = getNewNextStepFromNodeToBeDeleted(nodeToDelete, nodeType);
  const { next_node_type: existing_next_node_type = {} } = parentNode;

  if (parentNodeType === 'condition') {
    let nodeInBranch = 'none';
    if (parentNode.if_true === id) nodeInBranch = 'if_true';
    if (parentNode.if_false === id) nodeInBranch = 'if_false';
    if (nodeInBranch === 'none') return workflow;
    workflow.conditions[parentId][nodeInBranch] = newNextStep;
    workflow.conditions[parentId].next_node_type = {
      ...existing_next_node_type,
      [nodeInBranch]: nextNodeTypeOfNodeToDelete,
    };
  } else {
    const parentModule = workflow.modules.find((module) => module.id === parentId);
    if (parentModule.type === 'dynamicForm') {
      // Find the parent of nodeToBeDeleted.
      const { module: updatedModule, componentId, path } = findAndUpdateTheOnlyNextStepInModule(parentModule, id, newNextStep);
      updatedModule.next_node_type = {
        ...existing_next_node_type,
        [`${componentId}.${path}`]: nextNodeTypeOfNodeToDelete,
      };
      const moduleIndex = workflow.modules.findIndex((module) => module.id === parentId);
      workflow.modules[moduleIndex] = updatedModule;
    } else if (parentModule.type === 'dynamicFormV2') {
      const allNextSteps = getNextStepForModule(parentModule);
      const nextStepData = allNextSteps.find(({ nextStep }) => nextStep === id);
      if (!nextStepData) {
        alert('Can not find the parent branch for the next step in formV2');
        return { workflow, success: false };
      }
      const { path } = nextStepData;
      const updatedModule = cloneDeep(parentModule);
      const workflowKey = `componentConfigs.${path}`;
      const updatedProperties = setModuleProperty(workflowKey, newNextStep, parentModule?.properties);
      updatedModule.properties = updatedProperties;
      updatedModule.next_node_type = {
        ...existing_next_node_type,
        [path]: nextNodeTypeOfNodeToDelete,
      };
      const moduleIndex = workflow.modules.findIndex((module) => module.id === parentId);
      workflow.modules[moduleIndex] = updatedModule;
    } else {
      parentModule.nextStep = newNextStep;
      parentModule.next_node_type = nextNodeTypeOfNodeToDelete
        ? { default: nextNodeTypeOfNodeToDelete }
        : {};
    }
  }

  // Deleting module
  if (nodeType === 'module') workflow.modules = workflow.modules.filter((module) => module?.id !== id);
  else delete workflow.conditions[id];

  if (nodeType === 'condition') {
    logDeleteCondition({
      id,
      parentId,
    });
  } else if (nodeType === 'module') {
    logDeleteNode({
      id,
      parentId,
    });
  }
  return { workflow, success: true };
};

export const swapBranchesInCondition = (conditionId, workflowConfig) => {
  const editedWorkflow = cloneDeep(workflowConfig);
  // Find condition
  const selectedCondition = editedWorkflow.conditions[conditionId];
  if (selectedCondition) {
    const currentTrueBranch = selectedCondition.if_true;
    const currentFalseBranch = selectedCondition.if_false;
    const newCondition = cloneDeep(selectedCondition);
    newCondition.if_true = currentFalseBranch;
    newCondition.if_false = currentTrueBranch;
    const trueNextNodeType = newCondition?.next_node_type?.if_true || '';
    const falseNextNodeType = newCondition?.next_node_type?.if_false || '';
    newCondition.next_node_type = {
      if_true: falseNextNodeType,
      if_false: trueNextNodeType,
    };
    editedWorkflow.conditions[conditionId] = newCondition;
    logSwapBranchesInCondition({
      id: conditionId,
      newIfTrueBranch: newCondition.if_true,
      newIfFalseBranch: newCondition.if_false,
    });
  }
  return editedWorkflow;
};

// edit if_false or if_true reason in condition and remove the if_true or if_false reason key from condition if the value is empty
export const editIfTrueIfFalseReason = (workflowConfig, conditionId, type, value) => {
  const editedWorkflow = cloneDeep(workflowConfig);
  const condition = editedWorkflow.conditions[conditionId];

  if (condition) {
    if (value) {
      condition[`${type}_reason`] = value;
    } else {
      delete condition[`${type}_reason`];
    }
  }

  return editedWorkflow;
};

export const updateConditionRule = (workflowConfig, conditionId, rule) => {
  const editedWorkflow = cloneDeep(workflowConfig);
  const selectedRule = editedWorkflow.conditions[conditionId];
  const oldRule = selectedRule.rule;
  if (selectedRule) selectedRule.rule = rule;
  logConditionRuleUpdate({
    id: conditionId,
    oldRule,
    newRule: rule,
  });
  return editedWorkflow;
};

export const updateNodeName = (workflowConfig, nodeId, name) => {
  const editedWorkflow = cloneDeep(workflowConfig);
  let success = true;
  if (editedWorkflow.conditions[nodeId]) {
    editedWorkflow.conditions[nodeId].name = name;
  } else {
    const filteredModule = editedWorkflow.modules.filter((node) => node.id === nodeId)?.[0];
    if (filteredModule) {
      filteredModule.name = name;
    } else {
      success = false;
    }
  }
  return { workflow: editedWorkflow, success };
};

// TODO: Refactor by removing nodes argument?
const createNewConditionNode = (visualNodeIdOfNewChild, nodes) => {
  // ASSUMPTION: visualNodeId ( graph ) of the endstates is of the form '<end-state>...'
  // else actual id otherwise

  let nextStep = visualNodeIdOfNewChild;
  const next_node_type = {
    if_true: '',
    if_false: '',
  };

  if (visualNodeIdOfNewChild.startsWith('approve')) {
    nextStep = 'approve';
  } else if (visualNodeIdOfNewChild.startsWith('decline')) {
    nextStep = 'decline';
  } else if (visualNodeIdOfNewChild.startsWith('manualReview')) {
    nextStep = 'manualReview';
  } else if (visualNodeIdOfNewChild.startsWith('dismissToParent')) {
    nextStep = 'dismissToParent';
  } else if (visualNodeIdOfNewChild.startsWith('user_cancelled')) {
    nextStep = 'user_cancelled';
  } else if (visualNodeIdOfNewChild.startsWith('goto')) {
    const [childNode] = nodes.filter((n) => n.id === visualNodeIdOfNewChild);
    // ASSUMPTION: actualNode is only defined for goto nodes
    nextStep = childNode.actualNode;
    next_node_type.if_true = 'goto';
  }

  const condition = {
    rule: "'NONE_NONE' == 'NONE_NONE'",
    if_true: nextStep,
    if_false: 'approve',
    next_node_type,
  };

  const uid = generateUniqueID();
  const id = `condition_${uid}`;
  return { node: condition, id };
};

// TODO: Refactor the below code to create modules, default values should be preset
export const getNewNode = (
  node,
  addNodeBetween,
  nodes,
  workflowConfig,
  countryDocMapping,
  defaultFormSections,
) => {
  const { child } = addNodeBetween;

  // generate the id
  const uid = generateUniqueID();
  const {
    type, subType, properties, variables, builderProperties = {}, version = 'v1',
  } = node;

  const id = `module_${uid}_${node.subType}`;
  // add the next step
  let nextStep = child;
  let next_node_type = {};
  if (child.startsWith('approve')) {
    nextStep = 'approve';
  } else if (child.startsWith('decline')) {
    nextStep = 'decline';
  } else if (child.startsWith('manualReview')) {
    nextStep = 'manualReview';
  } else if (child.startsWith('dismissToParent')) {
    nextStep = 'dismissToParent';
  } else if (child.startsWith('user_cancelled')) {
    nextStep = 'user_cancelled';
  } else if (child.startsWith('goto')) {
    const [childNode] = nodes.filter((n) => n.id === child);
    nextStep = childNode.actualNode;
    if (node.type !== 'dynamicForm') next_node_type = { default: 'goto' };
  }

  const createdNode = {
    type,
    subType,
    id,
    nextStep,
    next_node_type,
    version,
  };
  const variablesWithPath =
    variables && Array.isArray(variables) ? variables.filter((variable) => variable.path) : [];
  if (node.type === 'countries') {
    return {
      ...createdNode,
      properties: {
        countriesSupported: ['ind'],
      },
    };
  }
  if (node.type === 'document') {
    // create url
    const url = `{${workflowConfig.modules[0].id}.baseUrl}/${properties.endpoint}`;
    const { apiType } = properties;
    // compute documents for all selected countries
    const selectedCountries = workflowConfig.modules[0].properties.countriesSupported;
    const documentsSupported = findAllDocumentsInSelectedCountries(
      selectedCountries,
      countryDocMapping,
    );

    const newProperties = {
      url,
      apiType,
      documentsSupported,
      // TODO: This won't work if workflow is imported and id of country module is different
      countryId: '{module_countryPicker.countryId}',
    };
    Object.keys(properties).forEach((key) => {
      if (properties[key].required) {
        if (properties[key].type === 'boolean' || properties[key].type === 'string') {
          // eslint-disable-next-line prefer-destructuring
          newProperties[key] = properties[key].values[0];
        } else if (properties[key].type === 'array') {
          newProperties[key] = properties[key].values;
        } else {
          newProperties[key] = '';
        }
      }
    });
    return {
      ...createdNode,
      properties: newProperties,
      variables: variablesWithPath,
    };
  }
  if (node.type === 'face') {
    // create url
    const url = `{${workflowConfig.modules[0].id}.baseUrl}${properties.endpoint}`;
    const { apiType } = properties;
    const newProperties = { url, apiType };
    Object.keys(properties).forEach((key) => {
      if (properties[key].required) {
        if (properties[key].type === 'boolean' || properties[key].type === 'string') {
          // eslint-disable-next-line prefer-destructuring
          newProperties[key] = properties[key].values[0];
        } else if (properties[key].type === 'array') {
          newProperties[key] = properties[key].values;
        } else {
          newProperties[key] = '';
        }
      }
    });
    return {
      ...createdNode,
      properties: newProperties,
      variables: variablesWithPath,
    };
  }
  if (node.type === 'api') {
    // create url
    // what if multiple country modules are there
    // TODO: Refactor later
    const baseUrl = typeof properties?.baseUrl === 'string' ? properties?.baseUrl : '';
    const endpoint = typeof properties?.endpoint === 'string' ? properties?.endpoint : '';

    let url = `${baseUrl}${endpoint}`;
    url = url.replace('<countries>', workflowConfig.modules[0].id);
    const { apiType, allowedStatusCodes } = properties;
    const newProperties = { url, apiType };
    if (Array.isArray(allowedStatusCodes) && (allowedStatusCodes || []).length > 0) {
      newProperties.allowedStatusCodes = allowedStatusCodes;
    }
    Object.keys(properties).forEach((key) => {
      if (properties[key].required) {
        if (properties[key].type === 'boolean' || properties[key].type === 'string') {
          // eslint-disable-next-line prefer-destructuring
          newProperties[key] = properties[key].values[0];
        } else if (properties[key].type === 'array') {
          newProperties[key] = properties[key].values;
        } else {
          newProperties[key] = '';
        }
      }
    });
    if (properties.requestParameters) {
      newProperties.requestParameters = properties.requestParameters.map((param) => ({
        type: param.type,
        name: param.name,
        value: param.value.replace('<countries>', workflowConfig.modules[0].id),
      }));
    }
    return {
      ...createdNode,
      properties: newProperties,
      variables: variablesWithPath,
    };
  }
  if (node.type === 'webview') {
    const { url = '' } = properties;
    const newProperties = { url };

    Object.keys(properties).forEach((key) => {
      if (properties[key].required) {
        if (properties[key].type === 'boolean' || properties[key].type === 'string') {
          const [property] = properties[key].values;
          newProperties[key] = property;
        } else if (properties[key].type === 'array') {
          newProperties[key] = properties[key].values;
        } else {
          newProperties[key] = '';
        }
      }
    });
    return {
      ...createdNode,
      properties: newProperties,
      variables: variablesWithPath,
    };
  }
  if (node.type === 'dynamicForm') {
    const defaultSections = cloneDeep(defaultFormSections);
    const defaultButton = defaultSections[0].components.find((comp) => comp.type === 'button');
    set(defaultButton, 'onClick.nextStep', nextStep);

    (defaultSections[0].components || []).forEach((_, i) => {
      const currentCompId = defaultSections[0].components[i].id;
      defaultSections[0].components[i].id = `${currentCompId}_${generateUniqueID()}`;
    });

    const updatedDefaultBtnId = defaultSections[0].components.find((comp) => comp.type === 'button')?.id;
    if (child.startsWith('goto')) {
      createdNode.next_node_type = {
        [`${updatedDefaultBtnId}.onClick.nextStep`]: 'goto',
      };
    }

    return {
      ...createdNode,
      nextStep: '',
      properties: {
        sections: defaultSections,
      },
    };
  }
  if (node.type === 'dynamicFormV2') {
    const buttonId = createNewDomId();
    const spanId = createNewDomId();
    return {
      ...createdNode,
      nextStep: '',
      properties: {
        styles: '',
        scripts: [],
        content: `<button id="${buttonId}"><span id="${spanId}">text-goes-here</span></button>`,
        stylesheets: [],
        componentConfigs: {
          [buttonId]: {
            attributes: {},
            properties: {
              textContent: 'Default button',
            },
            events: {
              click: {
                nextStep,
              },
            },
          },
        },
      },
    };
  }
  if (node.type === 'superModule') {
    const newProperties = {};
    Object.keys(properties).forEach((key) => {
      if (properties[key].required) {
        if (properties[key].type === 'boolean' || properties[key].type === 'string') {
          // eslint-disable-next-line prefer-destructuring
          newProperties[key] = properties[key]?.values?.[0];
        } else if (properties[key]?.type === 'array') {
          newProperties[key] = properties[key].values;
        } else {
          newProperties[key] = '';
        }
      }
    });

    const newBuilderProperties = Object.entries(builderProperties).reduce(
      (currProperties, [propKey, propValue]) => {
        if (propValue.default) {
          return { ...currProperties, [propKey]: propValue.default };
        }
        if (propValue.type === 'boolean' || propValue.type === 'string') {
          return { ...currProperties, [propKey]: propValue?.values?.[0] };
        }
        if (propValue.type === 'array') {
          return { ...currProperties, [propKey]: propValue?.values };
        }
        return { ...currProperties, [propKey]: '' };
      },
      {},
    );

    return {
      ...createdNode,
      properties: newProperties,
      variables: variablesWithPath,
      builderProperties: newBuilderProperties,
    };
  }
  return {
    ...createdNode,
    variables,
  };
};

const createNewModuleNode = (
  node,
  addNodeBetween,
  nodes,
  workflowConfig,
  countryDocMapping,
  defaultFormSections,
) => {
  const newNode = getNewNode(
    node,
    addNodeBetween,
    nodes,
    workflowConfig,
    countryDocMapping,
    defaultFormSections,
  );
  return { node: newNode, id: newNode?.id };
};

const addDefaultPropertiesToModule = (moduleId, workflow, node) => {
  let editedWorkflow = cloneDeep(workflow);
  const propertiesToSet = [
    ...(node.sections?.configurations || []),
    ...(node.sections?.inputs || []),
  ];
  propertiesToSet.forEach((property) => {
    const { workflowKey, default: defaultValue, type: inputType } = property;
    let valueToSet = defaultValue;
    if (typeof defaultValue === 'undefined' && inputType === 'duration') valueToSet = 0;
    if (typeof valueToSet !== 'undefined') {
      editedWorkflow = setModulePropertyInWorkflow(
        editedWorkflow,
        moduleId,
        workflowKey,
        valueToSet,
        node,
      );
    }
  });
  return editedWorkflow;
};

export const addNewNodeInWorkflow = ({
  addNodeBetween,
  nodes,
  workflowConfig,
  localOrderOfNodes,
  type,
  node = null,
  countryDocMapping = null,
  defaultFormSections = null,
}) => {
  let workflow = cloneDeep(workflowConfig);
  // Invalid type property
  if (!['condition', 'module'].includes(type)) return { workflow, success: false };
  const { parent: parentId, child } = addNodeBetween;
  const { node: parentNode, type: parentNodeType } = getNodeAndType(parentId, workflowConfig) || {};
  const { parentPath, parentBranch } = getParentPathAndBranch(child, localOrderOfNodes);

  // Check if parent is actual module or condition id
  if (!parentNode || !parentNodeType) return { workflow, success: false };
  if (!['condition', 'module'].includes(parentNodeType)) return { workflow, success: false };
  if (parentNodeType === 'condition' && !parentBranch) return { workflow, success: false };
  if (parentNodeType === 'module' && !parentPath) return { workflow, success: false };

  const newNodeData = type === 'condition'
    ? createNewConditionNode(child, nodes)
    : createNewModuleNode(
      node,
      addNodeBetween,
      nodes,
      workflowConfig,
      countryDocMapping,
      defaultFormSections,
    );
  const { id: newNodeId, node: newNode } = newNodeData;
  if (type === 'condition') {
    workflow.conditions = {
      ...(workflow.conditions || {}),
      [newNodeId]: newNode,
    };
  } else {
    workflow.modules.push(newNode);
  }

  if (parentNodeType === 'condition') {
    const clonnedCondition = cloneDeep(parentNode);
    const existing_next_node_type = parentNode.next_node_type || {};
    clonnedCondition[parentBranch] = newNodeId;
    clonnedCondition.next_node_type = {
      ...existing_next_node_type,
      [parentBranch]: '',
    };
    workflow.conditions[parentId] = clonnedCondition;
  } else {
    // TODO: Use updateNextStepForModule fn for this.
    const parentModule = workflow.modules.find((module) => module.id === parentId);
    const existing_next_node_type = parentModule.next_node_type || {};
    if (parentModule.type === 'dynamicForm') {
      const [componentId, ...nextStepPathArray] = parentPath.split('.');
      const nextStepPath = nextStepPathArray.join('.');
      const updatedModule = updateFormPropertyViaComponentId(parentModule, componentId, nextStepPath, newNodeId);
      updatedModule.next_node_type = {
        ...existing_next_node_type,
      };
      delete updatedModule.next_node_type[parentPath];
      const parentModuleIndex = workflow.modules.findIndex((module) => module.id === parentId);
      workflow.modules[parentModuleIndex] = updatedModule;
    } else if (parentModule.type === 'dynamicFormV2') {
      const [componentId, ...nextStepPathArray] = parentPath.split('.');
      const nextStepPath = nextStepPathArray.join('.');
      const keyToEdit = `componentConfigs.${componentId}.${nextStepPath}`;
      const updatedProperties = setModuleProperty(keyToEdit, newNodeId, parentModule?.properties);
      const updatedModule = cloneDeep(parentModule);
      updatedModule.properties = updatedProperties;
      updatedModule.next_node_type = {
        ...existing_next_node_type,
      };
      delete updatedModule.next_node_type[parentPath];
      const parentModuleIndex = workflow.modules.findIndex((module) => module.id === parentId);
      workflow.modules[parentModuleIndex] = updatedModule;
    } else {
      parentModule.nextStep = newNodeId;
      parentModule.next_node_type = { ...existing_next_node_type, default: '' };
    }
  }

  if (type === 'module') workflow = addDefaultPropertiesToModule(newNodeId, workflow, node);
  let highLevelUiConfig = {};
  if (newNode?.type === 'superModule') {
    const defaultSuperModuleUIConfig = node?.library?.uiFormatting || {};
    highLevelUiConfig = getHighLevelUiConfig(newNodeId, defaultSuperModuleUIConfig);
  }
  if (type === 'condition') {
    logAddCondition({
      id: newNodeId,
      parentId: addNodeBetween?.parent,
      childId: addNodeBetween?.child,
    });
  } else {
    logAddNode({
      id: newNodeId,
      parentId: addNodeBetween?.parent,
      childId: addNodeBetween?.child,
      subType: node?.subType,
    });
  }
  return {
    workflow, highLevelUiConfig, newModule: newNode, newNodeId, success: true,
  };
};

export const updateGotoInWorkflow = (parentNodeId, parentBranch, updatedGotoModule, workflow) => {
  // Check if parent node Id is a condition
  const { node: parentNode, type: parentNodeType } = getNodeAndType(parentNodeId, workflow) || {};
  const isParentForm = parentNode?.type === 'dynamicForm';
  const isParentCondition = parentNodeType === 'condition';

  const editedWorkflow = cloneDeep(workflow);
  let oldPath = null;
  if (isParentCondition) {
    oldPath = editedWorkflow.conditions[parentNodeId][parentBranch];
    editedWorkflow.conditions[parentNodeId] = {
      ...editedWorkflow.conditions[parentNodeId],
      next_node_type: {
        ...editedWorkflow.conditions[parentNodeId].next_node_type,
        [parentBranch]: 'goto',
      },
      [parentBranch]: updatedGotoModule,
    };
  } else if (isParentForm) {
    const parentModuleIndex = workflow.modules.findIndex((module) => module.id === parentNodeId);
    // TODO: Check if we are getting expected parentBranch ?
    const [componentId, ...nextStepPathArray] = parentBranch.split('.');
    const nextStepPath = nextStepPathArray.join('.');
    const updatedModule = updateFormPropertyViaComponentId(parentNode, componentId, nextStepPath, updatedGotoModule);
    updatedModule.next_node_type = {
      ...(updatedModule.next_node_type || {}),
      [parentBranch]: 'goto',
    };
    editedWorkflow.modules[parentModuleIndex] = updatedModule;
  } else {
    const parentModuleIndex = workflow.modules.findIndex((module) => module.id === parentNodeId);
    oldPath = editedWorkflow.modules[parentModuleIndex].nextStep;
    editedWorkflow.modules[parentModuleIndex] = {
      ...editedWorkflow.modules[parentModuleIndex],
      next_node_type: { default: 'goto' },
      nextStep: updatedGotoModule,
    };
  }
  logUpdateGoto({
    id: parentNodeId,
    parentBranch,
    oldGotoStep: oldPath,
    newGotoStep: updatedGotoModule,
  });
  return editedWorkflow;
};

export const convertOldNextNodeTypeKeysToNew = (workflow) => {
  const updatedWorkflow = cloneDeep(workflow);
  // This function transforms array
  updatedWorkflow.modules.forEach((module) => {
    const { type, next_node_type: existingNextNodeType = {} } = module;
    const newNextNodeType = { ...existingNextNodeType };
    if (type === 'dynamicForm' && existingNextNodeType) {
      Object.keys(existingNextNodeType).forEach((key) => {
        const value = existingNextNodeType[key];
        const pathArray = getPathArray(key);
        if (pathArray.length) {
          const { id: componentId } = getSelectedComponent(module, pathArray, 'components');
          newNextNodeType[`${componentId}.onClick.nextStep`] = value;
          delete newNextNodeType[key];
        }
      });
    } else {
      // Will ensure that default is always present
      newNextNodeType.default = existingNextNodeType?.default || '';
    }
    module.next_node_type = newNextNodeType;
  });
  return updatedWorkflow;
};
