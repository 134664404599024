import PropTypes, { object } from 'prop-types';

function SelectedDocumentsTable(props) {
  const {
    options,
    supportedDocuments,
    togglePopup,
    isDisabled,
  } = props;

  const {
    titleText,
    selectButtonText,
  } = options;

  return (
    <div className="document-select">
      <div className="document-select__title">{titleText}</div>
      { supportedDocuments.length > 0 ? (
        <div className="title-table">
          <div className="title-table_container">
            <table>
              <thead>
                <tr>
                  <th>Country</th>
                  <th>Allowed Documents</th>
                </tr>
              </thead>
              <tbody>
                {supportedDocuments.map((item) => (
                  <tr key={item.country}>
                    <td>{item.countryName}</td>
                    <td>{item.documentsName?.join(', ')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : '' }
      <button
        type="button"
        className="document-select__btn"
        onClick={togglePopup}
        disabled={isDisabled}
      >
        {selectButtonText}
      </button>
    </div>
  );
}

export default SelectedDocumentsTable;

SelectedDocumentsTable.propTypes = {
  options: PropTypes.instanceOf(object).isRequired,
  supportedDocuments: PropTypes.array.isRequired,
  togglePopup: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

SelectedDocumentsTable.defaultProps = {
  isDisabled: false,
};
