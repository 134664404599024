import PropTypes, { object } from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import { getSelectedModule } from '../../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';
import { selectSelectedNode, selectSelectedWorkflow, selectVersionedModules } from '../../reducers/workflow';
import { evaulateRuleForModule, getDefaultValueFromComponentConfigs } from '../../utils/helper';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import InputComponent from '../../components/ViewWorkflow/v2/InputComponent/InputComponent';
import Divider from '../../components/ViewWorkflow/Divider';
import SetNodeName from '../../components/ViewWorkflow/v2/SetNodeName/SetNodeName';
import OutputDrawer from '../../components/ViewWorkflow/v2/Outputs/OutputDrawer';
import ConfigurationModal from '../../components/ViewWorkflow/v2/ConfigurationPanel/main';
import '../../components/Workflow.scss';
import TextInput from '../../components/ImportWorkflow/TextInput';
import { workflowOperationsObj } from '../../workflowOperations';
import DropDown from '../../components/FormModule/Common/DropDown';

// TODO: Add a validator to check validity of props

function PropertiesTab({
  inputs,
  configurations,
  outputs,
  modalConfig,
}) {
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const selectedNode = useSelector(selectSelectedNode);
  const versionedModules = useSelector(selectVersionedModules);

  const selectedNodeId = selectedNode?.id;
  const selectedNodeType = selectedNode?.nodeType;
  const selectedNodeVersion = selectedNode?.version || 'v1';
  const selectedModuleConfig = versionedModules[selectedNodeType]?.[selectedNodeVersion]?.config;

  const getCurrentPreviousStep = (workflow, moduleId) => {
    const selectedModule = getSelectedModule(workflow, moduleId);
    if (!selectedModule) return '';
    if (selectedModule.previousStep === undefined || selectedModule.previousStep === null) return 'not-present';
    if (selectedModule.previousStep === '') return 'hide-back';
    return selectedModule.previousStep;
  };

  const setPreviousStep = (moduleId, previousStep) => {
    if (previousStep === 'not-present') {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.UNSET_PREVIOUS_STEP,
        actionData: {
          targetNodeId: selectedNodeId,
        },
      });
    } else if (previousStep === 'hide-back') {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.SET_PREVIOUS_STEP,
        actionData: {
          targetNodeId: moduleId,
          previousStep: '',
        },
      });
    } else {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.SET_PREVIOUS_STEP,
        actionData: {
          targetNodeId: moduleId,
          previousStep,
        },
      });
    }
  };

  const getAccessibility = (workflow, components, moduleId) => {
    const isAccessible = {};
    const module = getSelectedModule(workflow, moduleId);
    if (module) {
      components.forEach((component) => {
        const { visible: visibleRule, enabled: enabledRule, workflowKey } = component;
        const key = `${moduleId}[*]${workflowKey}`;
        const isVisible = typeof visibleRule === 'string' ? evaulateRuleForModule(visibleRule, module) : true;
        const isEnabled = typeof enabledRule === 'string' ? evaulateRuleForModule(enabledRule, module) : true;
        isAccessible[key] = {
          visible: isVisible,
          enabled: isEnabled,
        };
      });
    }
    return isAccessible;
  };

  const onChanges = (updates, moduleId, moduleConfig) => {
    updates.forEach(({ workflowKey, value }) => {
      if (value === null) {
        updateWorkflowInState({}, true, {
          operation: workflowOperationsObj.UNSET_MODULE_PROPERTY,
          actionData: {
            targetNodeId: selectedNodeId,
            workflowKey,
          },
        });
      } else {
        updateWorkflowInState({}, true, {
          operation: workflowOperationsObj.SET_MODULE_PROPERTY,
          actionData: {
            targetNodeId: moduleId,
            workflowKey,
            value,
            moduleConfig,
          },
        });
      }
    });
  };

  const initialAccessibility = getAccessibility(
    selectedWorkflow,
    [...configurations, ...inputs],
    selectedNodeId,
  );
  const [accessibility, setAccessibitlity] = useState(initialAccessibility);

  const prevAccessibilityRef = useRef(initialAccessibility);

  useEffect(() => {
    const prevAccessibilityState = prevAccessibilityRef.current;
    // Unset those properties which aren't visible
    const updates = [];
    Object.keys(accessibility || {}).forEach((key) => {
      const workflowKey = key.split('[*]')[1];
      if (typeof workflowKey === 'string') {
        if (accessibility[key]?.visible && accessibility[key]?.enabled) {
          if (prevAccessibilityState[key]?.visible === false ||
            prevAccessibilityState[key]?.enabled === false) {
            // key is visible and enabled, so add it to workflow
            // Set default value if present
            const allComponents = [...(inputs || []), ...(configurations || [])];
            const defaultValue = getDefaultValueFromComponentConfigs(workflowKey, allComponents);
            if (defaultValue !== null) updates.push({ workflowKey, value: defaultValue });
          }
        } else {
          // key is not visible or not enabled, so remove from workflow
          updates.push({ workflowKey, value: null });
        }
      }
    });

    onChanges(updates, selectedNodeId, selectedModuleConfig);
    prevAccessibilityRef.current = accessibility;
  }, [JSON.stringify(accessibility)]);

  useEffect(() => {
    // Get visibility of all the components
    const isAccessible = getAccessibility(
      selectedWorkflow,
      [...configurations, ...inputs],
      selectedNodeId,
    );
    setAccessibitlity(isAccessible);
  }, [
    JSON.stringify(selectedWorkflow),
    JSON.stringify(configurations),
    JSON.stringify(inputs),
    selectedNodeId,
  ]);

  const options = [
    { key: 'not-present', name: 'Default behavior' },
    { key: 'hide-back', name: 'Hide back button' },
  ];

  return (
    <div>
      <div className="border-box">
        <h2 className="border-box__heading">INPUTS</h2>
        <div className="border-box__content">
          <SetNodeName />
          <TextInput
            label="Node Id"
            setDefaultValue
            readOnly
            placeholder={selectedNodeId}
          />
          { selectedNodeType !== 'condition' ? (
            <div className="edit-properties-div__property">
              <DropDown
                label="Previous Step"
                key={`${selectedNodeId}`}
                onChange={(value) => {
                  setPreviousStep(selectedNodeId, value);
                }}
                options={options}
                defaultValue={getCurrentPreviousStep(selectedWorkflow, selectedNodeId)}
                allowOnlyNumbers={false}
              />
            </div>
          )
            : null}
          {inputs?.length ? inputs.map((element) => {
            if (!accessibility?.[`${selectedNodeId}[*]${element.workflowKey}`]?.visible) return null;
            return (
              <InputComponent
                key={`${selectedNodeId}_${element.workflowKey}`}
                element={element}
                isDisabled={!accessibility?.[`${selectedNodeId}[*]${element.workflowKey}`]?.enabled}
              />
            );
          }) : null}
        </div>
      </div>
      <Divider />
      {configurations ? (
        <>
          <div className="configuration-heading">
            {' '}
            Configurations of Module
          </div>
          <div className="elements">
            {(configurations || []).map((element) => {
              if (!accessibility?.[`${selectedNodeId}[*]${element.workflowKey}`]?.visible) return null;
              return (
                <InputComponent
                  key={`${selectedNodeId}_${element.workflowKey}`}
                  element={element}
                  isDisabled={!accessibility?.[`${selectedNodeId}[*]${element.workflowKey}`]?.enabled}
                />
              );
            })}
          </div>
        </>
      ) : null}
      {
        outputs ? (
          <>
            <Divider />
            <OutputDrawer heading="Output from Module" data={outputs} />
          </>
        )
          : null
      }
      {
        modalConfig ? (
          <>
            <Divider />
            <ConfigurationModal
              config={modalConfig}
            />
          </>
        ) : null
      }
    </div>
  );
}

PropertiesTab.propTypes = {
  inputs: PropTypes.instanceOf(object).isRequired,
  configurations: PropTypes.instanceOf(object).isRequired,
  outputs: PropTypes.instanceOf(object).isRequired,
  modalConfig: PropTypes.any.isRequired,
};

export default PropertiesTab;
