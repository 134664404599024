import PropTypes from 'prop-types';
import conditionDeleteIcon from '../../assests/icons/conditionDeleteIcon.svg';

function ModuleInputRow({
  name,
  id,
  onDelete,
  onClick,
}) {
  return (
    <div key={id} className="workflow_output_field">
      <button
        type="button"
        onClick={() => onClick(id)}
        className="workflowInput__bottomContent_button"
      >
        {name}
      </button>
      <button type="button" onClick={() => onDelete(id)} className="workflow_output_field_deleteButton">
        <img
          src={conditionDeleteIcon}
          alt="delete"
        />
      </button>
    </div>
  );
}

ModuleInputRow.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default ModuleInputRow;
